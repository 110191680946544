

import * as React from "react";

import {CalendarEvent, CalendarEventProto, CalendarStatusOption} from "../../model/calendar";
import {FormControl, Select, MenuItem, Button, Grid, IconButton, Typography, Paper, Card} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import { timeMonday } from "d3-time";

export interface CalDay {
    name: string;
    date: string;
    hours: Date[];
    events?: CalendarEvent[];
}

interface StartEnd {
    start: Date;
    end: Date;
}

interface WeekProps {
    days: CalDay[];
    actHours: StartEnd;
    slotHeight: number;
    slotTotalHeight: number;
    weekTitle: string;
    clock: (arg0: Date) => string;
    getDayY: (arg0: Date) => any; // any number
    addEvent: (time?: Date, duration?: number) => void;
    openEvent: (event: CalendarEventProto | CalendarEvent) => void;
    nextWeek: () => void;
    prevWeek: () => void;
    today: () => void;
    events: CalendarEvent[];
    timestamp: (arg0: Date) => string;
    userSelect: JSX.Element;
    statusOptions: CalendarStatusOption[];
}

const useStyles = makeStyles((theme: Theme) => ({
    primaryBg: {
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.primary.main,
    },
	calCard: {
        position: "absolute", 
        width: "100%",
        padding: "2px 10px",
    },
    dayTitle: {
        borderLeft: "1px solid",
        padding: "5px 10px",
        textAlign: "center",
    },
    actTitle: {
        color: theme.palette.primary.main,
        fontWeight: 600,
    }
}));

function WeekNames( days?: CalDay[] ): JSX.Element | ''{
    const today: Date = new Date();
    const classes = useStyles();
    if ( days && days.length > 0 ) {
        return (
            <div
                style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "50px repeat(5, calc((100% - 50px ) / 5))",
                }}
            >
                <div />
                {
                    days.map((day: CalDay, key: number) => {
                        //className={[classes.calCard, classes.primaryBg].join(' ')}
                        const dayClasses: string[] = [ classes.dayTitle ];
                        if ( today.toLocaleDateString() === day.hours[0].toLocaleDateString() ) {
                            dayClasses.push( classes.actTitle );
                        }
                        return (
                            <div className={dayClasses.join(" ")} key={key}>{day.name} {day.date}</div>
                        )
                    })
                }
            </div>
        );
    } else {
        return '';
    }
}

function HourInColumn(hour: any, key: number, slotHeight: number, act: Boolean, ref?: any): JSX.Element{ //, ref?: String | undefined
    const style = {
        paddingLeft: "5px", 
        height: slotHeight,
        opacity: act ? 1 : 0.3,
    }
    if ( ref ) {
        return (
            <div ref={ref} style={style} key={key}>{ hour }</div>
        )
    }
    return (
        <div style={style} key={key}>{ hour }</div>
    )
}

export function WeekView(props: WeekProps){
    const {
        days,
        actHours,
        slotHeight,
        slotTotalHeight,
        weekTitle,
        clock,
        getDayY,
        addEvent,
        openEvent,
        prevWeek,
        nextWeek,
        today,
        events,
        timestamp,
        userSelect,
        statusOptions,
    } = props;
    const scrollStart = React.createRef<HTMLDivElement>();
    
    const classes = useStyles();

    const eventColors: string[] = [];
    
    statusOptions.map((item: CalendarStatusOption) => {
        eventColors[Number(item.id)] = item.color; 
    })

    const now: Date = new Date();
    
    React.useEffect(() => {
        if (scrollStart.current) {
            scrollStart.current.scrollIntoView({
                block: 'start',
            });
        }
    }, []);
    
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={3}>
                    <Button onClick={() => today() } variant="contained" size="small" color="primary">Tänään</Button>
                </Grid>
                <Grid item xs={6} style={{textAlign: "center"}}>
                    <Typography>
                        <IconButton aria-label="previous" color="primary" style={{marginRight: "5px"}} onClick={() => prevWeek()}>
                            <ArrowLeft fontSize="inherit" />
                        </IconButton>
                        {weekTitle}
                        <IconButton aria-label="next" color="primary" style={{marginLeft: "5px"}} onClick={() => nextWeek()}>
                            <ArrowRight fontSize="inherit" />
                        </IconButton>
                    </Typography>
                </Grid>
                <Grid item xs={3} style={{textAlign: "right"}}>
                    {userSelect}
                </Grid>
            </Grid>
            <Paper square={true}>  
                { WeekNames(days) }
                <div style={{
                    borderTop: "1px solid",
                    height: slotTotalHeight,
                    overflow: "scroll",
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "50px repeat(5, calc((100% - 50px ) / 5))",
                }}>
                    <div>
                        {
                            days[0].hours.map((hour: Date, key: number) => {
                                let ref: any = undefined;
                                const oclock: string = clock(hour);
                                const act: Boolean = hour < actHours.start || hour > actHours.end ? false : true;
                                if ( hour.toLocaleTimeString() === actHours.start.toLocaleTimeString() ) {
                                    ref = scrollStart;
                                }
                                return HourInColumn(oclock, key, slotHeight, act, ref );
                            })
                        }
                    </div>
                    {
                        days.map((day: CalDay, key: number) => {
                            const dayY = getDayY(day.hours[0]);
                            const dayEvents = events.filter((element) => { //, index, array
                                if (
                                    element.starttime >= Number(timestamp(day.hours[0])) 
                                    && element.starttime <= Number(timestamp(day.hours[day.hours.length-1]))
                                ) {
                                    return true;
                                }
                                return false;
                            });
                            const added: number[] = [];
                            
                            return (
                                <div style={{borderLeft: "1px solid",  position: "relative"}} key={key}>
                                    {
                                        day.hours.map((hour: Date, key: number) => {
                                            if ( hour < now ) {
                                                return ( 
                                                    <div
                                                        style={{
                                                            opacity: 0.3,
                                                            borderBottom: "1px solid",
                                                            height: slotHeight,
                                                            backgroundColor: "#dedede"
                                                        }}
                                                        key={key}>
                                                    </div>
                                                );
                                            }
                                            return (
                                                <div 
                                                    onClick={() => addEvent(hour) }
                                                    style={{
                                                        opacity: 0.3,
                                                        borderBottom: "1px solid",
                                                        height: slotHeight
                                                    }}
                                                    key={key}>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        dayEvents && dayEvents.map((event: CalendarEvent, key) => {
                                            console.log( event.id + ' :: ' + added.indexOf(event.id) );
                                            if ( added.indexOf(event.id) === -1 ) {
                                                
                                                const overlap = dayEvents.filter((element) => { //, index, array
                                                    //element.id !== event.id && 
                                                    if ( 
                                                        event.starttime >= element.starttime && 
                                                        element.endtime <= event.endtime
                                                    ) return true; 
                                                    /*else if (
                                                        element.starttime >= event.starttime && 
                                                        event.endtime <= element.endtime
                                                    ) return true;*/
                                                    return false;
                                                });
                                                // console.log(key);
                                                console.log('overlap items: '+overlap.length);
                                                /*if ( overlap.length > 1 ) {
                                                    const perc: number = 100 / overlap.length;
                                                    const appointments = overlap.map((overlapEvent: CalendarEvent, key2) => {
                                                        if ( added.indexOf(event.id) === -1 ) {
                                                            const top: number = dayY(overlapEvent.starttime);
                                                            const height: number = dayY(overlapEvent.endtime) - top;
                                                            added.push(overlapEvent.id);
                                                            return (
                                                                <Card 
                                                                    variant="outlined"
                                                                    key={2000+key2}
                                                                    className={[classes.calCard, classes.primaryBg].join(' ')}
                                                                    style={{
                                                                        top: top,
                                                                        height: height,
                                                                        // opacity: 0.5,
                                                                        width: `${perc}%`,
                                                                        left: `${perc*key2}%`,
                                                                    }}
                                                                    onClick={() => openEvent(overlapEvent)}
                                                                >
                                                                    {overlapEvent.subject}
                                                                </Card>
                                                            );
                                                        }
                                                    })
                                                    return (
                                                        <React.Fragment key={1000+key}>
                                                            {appointments}
                                                        </React.Fragment>
                                                    );
                                                } else {*/
                                                    const top: number = dayY(event.starttime);
                                                    const height: number = dayY(event.endtime) - top;
                                                    added.push(event.id);
                                                    return (
                                                        <Card 
                                                            variant="outlined"
                                                            key={1000+key}
                                                            className={[classes.calCard, classes.primaryBg].join(' ')}
                                                            style={{
                                                                top: top,
                                                                height: height,
                                                                backgroundColor: event.status_id ? eventColors[event.status_id] : eventColors[1],
                                                                //opacity: 0.5,
                                                            }}
                                                            onClick={() => openEvent(event)}
                                                        >
                                                            {event.subject}
                                                        </Card>
                                                    );
                                                //}
                                            } else {
                                                return '';
                                            }
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </Paper>
        </React.Fragment>
    );

};