import * as React from "react";

import { Button, Grid, TextField, Typography } from "@material-ui/core";

import { useSelector } from "react-redux";
import ApiConnection from "../../../actions/api/connect";
import { Client } from "../../../model/client";
import { RootState } from "../../../reducers/index";

function CompanyInfo({
    Cclient,
}: {
    Cclient: Client
}) {

    const [client, setClient] = React.useState<Client | undefined>(undefined);

    const token =  useSelector((state: RootState) => state.user.token);
    const apiConnect = new ApiConnection(token);

    function getClient() {
        apiConnect.setValues({
			id: Cclient.id,
        });
        apiConnect.connect( 'clients/get' ).then(response => {
            console.log(response);
            setClient( response );
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    const handleChange = (prop: keyof Client) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setClient({ ...client, [prop]: event.target.value });
    };

    function handleClientSave() {
        if (client) {
            apiConnect.setValues({
                id: client.id,
                vatnumber: client.vatnumber || '',
                company: client.company || '',
                address: client.address || '',
                zipcode: client.zipcode || '',
                city: client.city || '',
                phone: client.phone || '',
                email: client.email || '',
                www: client.www || '',
                extra_info: client.extra_info || '',
                user_id: client.user_id || '0'
            });

            apiConnect.connect( 'Clients/add' ).then(response => {

            })
            .catch( error => {
                console.log( 'ERROR: '+error);
            });
        }
    }

    React.useEffect(() => {
        getClient();
    }, [])

    if (client) {
        return (
            <React.Fragment>
                <Typography variant="h6">Yrityksen tiedot</Typography>
                <Grid container spacing={3} style={{marginTop: "0.5rem"}}>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            id="company"
                            label="Yrityksen nimi"
                            onChange={handleChange('company')} 
                            value={client.company || ''}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id="vatnumber"
                            label="Y-tunnus"
                            onChange={handleChange('vatnumber')} 
                            value={client.vatnumber || ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="address"
                            label="Osoite"
                            onChange={handleChange('address')} 
                            value={client.address || ''}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id="zipcode"
                            label="Postinumero"
                            onChange={handleChange('zipcode')} 
                            value={client.zipcode || ''}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            id="city"
                            label="Kaupunki"
                            onChange={handleChange('city')} 
                            value={client.city || ''}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id="phone"
                            label="Puhelinnumero"
                            onChange={handleChange('phone')} 
                            value={client.phone || ''}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            id="email"
                            label="Sähköpostiosoite"
                            onChange={handleChange('email')} 
                            value={client.email || ''}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            id="www"
                            label="Kotisivujen osoite"
                            onChange={handleChange('www')} 
                            value={client.www || ''}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            multiline
                            rows="5"
                            id="extra_info"
                            label="Lisätiedot"
                            onChange={handleChange('extra_info')} 
                            value={client.extra_info || ''}
                        />
                    </Grid>
                    <Grid item xs={12} style={{textAlign: "right", marginTop: "15px"}}>
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            size="small" 
                            disableElevation
                            onClick={ () => handleClientSave() }
                            >
                            Tallenna asiakastiedot
                        </Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    } else {
        return <React.Fragment></React.Fragment>;
    }
};

export default CompanyInfo;