import DefaultTemplate from "../components/PageTemplates/DefaultTemplate";

import { Grid, Paper, Table, TableHead, TableBody, TableRow, TableCell, Button, TextField, InputLabel, Select, MenuItem } from "@material-ui/core";

import * as React from "react";

import ApiConnection from "../actions/api/connect";

import { useSelector } from "react-redux";
import { RootState } from "../reducers/index";
import { BasicAlert, IBasicAlert } from '../components/Alerts/BasicAlert';
import { history } from "../configureStore";

import { FundingCompany, Types } from "../model/fundingcompany";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
	paper: {
        padding: 20,
	},
});

export function FundingCompaniesPage() {
	const classes = useStyles();

	const token =  useSelector((state: RootState) => state.user.token);

	const [types, setTypes] = React.useState<Types[]>([]);
	const [companies, setCompanies] = React.useState<FundingCompany[]>([]);
	const [selectedCompany, setSelectedCompany] = React.useState<FundingCompany>();
	const [alert, setAlert] = React.useState<IBasicAlert>({});



	const apiConnect = new ApiConnection(token);

    function listCompanies(){
		apiConnect.setValues({});
        apiConnect.connect( 'fundingCompanies/list' ).then(data => {
			console.log(data);
			setCompanies( data );
        })
        .catch( error => {
        });
    }

    function listTypes(){
		apiConnect.setValues({});
        apiConnect.connect( 'fundingCompanies/listTypes' ).then(data => {
			console.log(data);
			setTypes( data );
        })
        .catch( error => {
        });
    }

	const handleSelectChange = (prop: keyof FundingCompany) => (event: React.ChangeEvent<{ value: any }>) => {
		setSelectedCompany({ ...selectedCompany, [prop]: event.target.value });
	};
	

    const handleChange = (prop: keyof FundingCompany) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedCompany({ ...selectedCompany, [prop]: event.target.value });
    };

	function handleSelect(company: FundingCompany) {
		setAlert({});
		setSelectedCompany(company);
	}

	function handleSave() {
		if (selectedCompany) {
			if (selectedCompany.id) {
				apiConnect.setValues({
					id: selectedCompany.id,
					name: selectedCompany.name,
					vatnumber: selectedCompany.vatnumber,
					address: selectedCompany.address,
					zipcode: selectedCompany.zipcode,
					city: selectedCompany.city,
					phone: selectedCompany.phone,
					email: selectedCompany.email,
					funding_company_type_id: selectedCompany.funding_company_type_id
				});
			} else {
				apiConnect.setValues({
					name: selectedCompany.name,
					vatnumber: selectedCompany.vatnumber,
					address: selectedCompany.address,
					zipcode: selectedCompany.zipcode,
					city: selectedCompany.city,
					phone: selectedCompany.phone,
					email: selectedCompany.email,
					funding_company_type_id: selectedCompany.funding_company_type_id
				});
			}
		}
        apiConnect.connect( 'fundingCompanies/add' ).then(data => {
			setSelectedCompany(undefined);
			listCompanies();
		})
        .catch( error => {
        });
	}

	if (companies.length === 0) {
		listCompanies();
		listTypes();
	}

	function changeContactPage(company: FundingCompany) {
        history.push({
            pathname: '/settings/fundingcompanies/contacts',
            state: {funding_company_id: company.id},
          });
	}

	if ( selectedCompany) {

		return (
			<DefaultTemplate
				title={selectedCompany.name ? 'Muokkaa' : 'Lisää uusi rahoituskumppani'}
				content={
					<Grid container spacing={3}>
						{
							alert
								?
								<Grid item xs={12}>
									<BasicAlert
										type={alert.type}
										message={alert.message}
									/>
								</Grid>
								: ''
						}

					<Grid item xs={12}>
						<InputLabel
                                id="client_status"
                            >
                                Tyyppi
                            </InputLabel>
                            <Select
                                fullWidth
                                id="funding_company_type_id"
                                defaultValue={selectedCompany.funding_company_type_id}
                                onChange={handleSelectChange('funding_company_type_id')}
                            >
                                
                                {
                                    types.map((type: Types, key: number) => {
                                        return(
                                            <MenuItem key={key} value={type.id}>{type.name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								id="name"
								label="Nimi"
								defaultValue={selectedCompany.name}
								onChange={handleChange('name')}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								id="vatnumber"
								label="Y-tunnus"
								defaultValue={selectedCompany.vatnumber}
								onChange={handleChange('vatnumber')}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								id="address"
								label="Osoite"
								defaultValue={selectedCompany.address}
								onChange={handleChange('address')}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								id="zipcode"
								label="Postinumero"
								defaultValue={selectedCompany.zipcode}
								onChange={handleChange('zipcode')}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								id="city"
								label="Postitoimipaikka"
								defaultValue={selectedCompany.city}
								onChange={handleChange('city')}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								id="phone"
								label="Puhelinnumero"
								defaultValue={selectedCompany.phone}
								onChange={handleChange('phone')}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								id="email"
								label="Sähköpostiosoite"
								defaultValue={selectedCompany.email}
								onChange={handleChange('email')}
							/>
						</Grid>
						<Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            disableElevation
                            onClick={ () => handleSave() }
                        >
                            Tallenna
                        </Button>
						</Grid>
					</Grid>
				}
			/>
		);
	}


	return (
		<DefaultTemplate
			title="Rahoituskumppanit"
			actions={
				<Button
					variant="contained"
					color="secondary"
					size="small"
					disableElevation
					onClick={() => setSelectedCompany({}) }
				>
					Lisää uusi
				</Button>
			}
			content={
				<Paper className={classes.paper} square={true}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell padding="default">Tyyppi</TableCell>
							<TableCell padding="default">Rahoituskumppani</TableCell>
							<TableCell padding="default">Y-tunnus</TableCell>
							<TableCell padding="default">Osoite</TableCell>
							<TableCell padding="default">Puhelinnumero</TableCell>
							<TableCell padding="default">Sähköposti</TableCell>
							<TableCell padding="default"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							companies.map((company: FundingCompany, key: number) => {
								return(
									<TableRow key={key} onClick={() => handleSelect(company)}>
										<TableCell padding="default">{company.funding_company_type ? company.funding_company_type.name : ''}</TableCell>
										<TableCell padding="default">{company.name}</TableCell>
										<TableCell padding="default">{company.vatnumber}</TableCell>
										<TableCell padding="default">{company.address}, {company.zipcode} {company.city}</TableCell>
										<TableCell padding="default">{company.phone}</TableCell>
										<TableCell padding="default">{company.email}</TableCell>
										<TableCell padding="default">
										<Button
											variant="contained"
											color="secondary"
											size="small"
											disableElevation
											onClick={() => changeContactPage(company) }
										>
											Yhteyshenkilöt
										</Button>

										</TableCell>
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
			</Paper>
			}
		/>
	);
};
