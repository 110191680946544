import DefaultTemplate from "../components/PageTemplates/DefaultTemplate";

import { Grid } from "@material-ui/core";

import * as React from "react";
import { useActions } from "../actions";

import { useSelector } from "react-redux";
import { RootState } from "../reducers/index";
import { BasicAlert, IBasicAlert } from '../components/Alerts/BasicAlert';

import ApiConnection from "../actions/api/connect";
import { addReminder, Reminders, SearchReminder } from "../model/reminder";
import RemindersConditions from "../components/Reminders/RemindersConditions";
import RemindersTable from "../components/Reminders/ReminderTable";
import { ReminderDialog } from "../components/Reminders/ReminderDialog";
import { ReminderApi } from "../components/Reminders/ReminderApi";
import getUnixTime from 'date-fns/getUnixTime';

import { SearchCriteria, Criteria } from "../model/searchCriteria";
import * as SearchCriteriaActions from "../actions/searchCriteria";

export function RemindersPage() {

    const token =  useSelector((state: RootState) => state.user.token);
    const apiConnect = new ApiConnection(token);

	const defaultReminder: any = undefined;
	const [reminder, setReminder] = React.useState(defaultReminder);
	const defaultReminders: any = undefined;
	const [reminders, setReminders] = React.useState(defaultReminders);
	const [alert, setAlert] = React.useState<IBasicAlert>({});
	const defaultTime = getUnixTime(new Date()); //fromUnixTime( 0 ); //startOfTomorrow()
    const [values, setValues] = React.useState<SearchReminder>();

	const remApi = new ReminderApi(token);

	const searchCriteriaActions = useActions(SearchCriteriaActions);
	const searchCriteria = useSelector((state: RootState) => state.searchCriteriaList);

    function listReminders(search: SearchReminder){
		searchCriteriaActions.addSearchCriteria({
			name: 'reminders',
			criteria: {
				startdate: search.startdate,
				enddate: search.enddate,
				type: search.type,
				company: search.company
			},
		});
		console.log("Haetaan!");
		apiConnect.setValues({
			startdate: search.startdate,
			enddate: search.enddate,
			type: search.type,
			company: search.company
		});
		setValues(search);
        apiConnect.connect( 'reminders/list' ).then(data => {
			setReminders( data );
        })
        .catch( error => {
        });

    }

    function listRemindersFirst(){
		console.log('listRemindersFirst');
		
		const criteria = searchCriteria ? searchCriteria.filter((c) => { return (c.name === 'reminders') }) : [];
		console.log('reminders criteria');
		console.log(criteria);
		
		if ( criteria.length > 0 && criteria[0].criteria) {
			apiConnect.setValues(criteria[0].criteria);
		} else {
			apiConnect.setValues({
				startdate: defaultTime,
			});
		}
		
        apiConnect.connect( 'reminders/list' ).then(data => {
			setReminders( data );
		})
        .catch( error => {
        });
	}
	
	function selectReminder(selRem: addReminder) {
		setReminder(selRem);
	}
	
	function saveReminder(selRem: addReminder){
		setReminder(defaultReminder);
		remApi.save(selRem)
		.then(response => {
			if (values && values.startdate) {
				listReminders(values);
			}
            console.log(response);
        })
        .catch( error => {
        	console.log( 'ERROR: '+error);
        });
	}

	function deleteReminder(id: number){
		setReminder(defaultReminder);
		//
		if (id > 0) {
			remApi.delete(id)
			.then(response => {
				console.log('id: '+id);
				if (values && values.startdate) {
					listReminders(values);
				}
				})
			.catch( error => {
				console.log( 'ERROR: '+error);
			});
		}
	}

	function closeDialog(){
		setReminder(defaultReminder);
	}

	if ( !reminders ) {
		listRemindersFirst();
	}


	return (
		<DefaultTemplate
			title="Muistutukset"
			dialog={
				<ReminderDialog
					open={reminder ? true : false}
					onClose={closeDialog}
					onSave={saveReminder}
					onDelete={deleteReminder}
					actReminder={reminder}
					//noDialog={true}
				/>
			}
			content={
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<RemindersConditions 
							listC={listReminders}
						/>
					</Grid>
					<Grid item xs={12}>
						<RemindersTable 
							reminders={reminders ? reminders : []}
							onSelect={selectReminder}
						/>
					</Grid>
				</Grid>
			}
		/>
	);
};

