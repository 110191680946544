import { CssBaseline } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import * as React from "react";

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
	typography: {
		fontFamily: 'Lato, Arial',
		h4: {
			fontSize: '1.8rem',
			fontWeight: 300,
			/*textTransform: 'uppercase',*/
			/*'@media (min-width:600px)': {
				fontSize: '1.5rem',
			},
			[theme.breakpoints.up('md')]: {
				fontSize: '2.4rem',
			},*/
		},
		h5: {
			fontSize: '1.2rem',
			fontWeight: 300,
		},
		h6: {
			fontSize: '1.2rem',
			fontWeight: 300,
		}
	},
	palette: {
		primary: {
			light: "#e5e5e5",
			main: "#18447e",
			dark: "#363839",
			contrastText: "#fff",
		},
		secondary: {
			light: "#ff5e50",
			main: "#0020b2", // "#e41e26",
			dark: "#a90000",
			contrastText: "#fff",
		},
	},
});

export function withRoot(Component: any) {
	function WithRoot(props: object) {
		// MuiThemeProvider makes the theme available down the React tree
		// thanks to React context.
		return (
			<ThemeProvider theme={theme}>
				{/* Reboot kickstart an elegant, consistent, and simple baseline to build upon. */}
				<CssBaseline />
				<Component {...props} />
			</ThemeProvider>
		);
	}

	return WithRoot;
}
