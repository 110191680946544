import NotificationsIcon from "@material-ui/icons/Notifications";

import { Badge } from "@material-ui/core";
import { Todo } from "../../model";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/index";

export function TodoIcon() {

	return <NotificationsIcon />;

/*
	if (reminders.length > 0) {
		return (
			<Badge color="secondary" badgeContent={reminders.length}>
				<NotificationsIcon />
			</Badge>
		);
	} else {
		return <NotificationsIcon />;
	}
*/
}
