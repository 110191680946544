
import * as React from "react";
import { Route } from "react-router-dom";

import { LoginPage } from "../../pages";

import { MenuItems, MenuItem,} from "./MenuItems";

import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import { useSelector } from "react-redux";
import { RootState } from "../../reducers/index";
import { Service } from "../../model/service";

import TokenTimer from "../TokenTimer";

import * as H from 'history';

const useStyles = makeStyles((theme: Theme) => ({
	content: {
		backgroundColor: theme.palette.background.default,
		width: "100%",
		height: "calc(100% - 56px)",
		marginTop: 56,
		[theme.breakpoints.up("sm")]: {
			height: "calc(100% - 64px)",
			marginTop: 64,
		},
	},
}));

export function MainPages() {
	const classes = useStyles({});
	const serviceList: Service[] = useSelector((state: RootState) => state.serviceList);
	/*<TokenTimer 
				expTime={expTime || 0}
			/>*/
	return (
		<div className={classes.content}>
			{MenuItems.map((item: MenuItem, key) => {
				if ( item.id ) {
					const checker = serviceList.filter( ( service ) => service.id === item.id );
					if ( checker.length === 0 ) {
						return '';
					}
				}
				return (
					<React.Fragment key={key} >
						<Route exact={true} path={item.path} component={item.component} />
						{
							item.sub?.map((subItem: MenuItem, subKey) => {
								// console.log( JSON.stringify( subItem ) );
								///console.log( ":: " + item.path+subItem.path );
								if ( subItem.id ) {
									const subChecker = serviceList.filter( ( service ) => service.id === subItem.id );
									if ( subChecker.length === 0 ) {
										return '';
									}
								}
								return (
									<React.Fragment>
										<Route key={key+'_'+subKey} exact={true} path={item.path+subItem.path} component={subItem.component} />
									</React.Fragment>
								);
							})
						}
					</React.Fragment>
				);
			})}
		</div>
	);
};

export function LoginDefault() {
	const classes = useStyles({});
	return (
		<div className={classes.content}>
			<LoginPage />
		</div>
	);
};