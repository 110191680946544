

import * as React from "react";
// import React, { useState } from 'react';
import { timeDay, timeMonday, timeHour, timeMinute, timeHours, } from "d3-time";
import { timeFormat, timeParse } from "d3-time-format";
import { scaleLinear } from 'd3-scale';

import {CalendarEvent, CalendarEventProto, CalendarStatusOption} from "../../model/calendar";

import {EventDialog, DialogProps} from "./EventDialog";
import {WeekView, CalDay} from "./Week";

import ApiConnection from "../../actions/api/connect";

import { useSelector } from "react-redux";
import { RootState } from "../../reducers/index";

interface CalDialogProps {
    onClose: () => void;
    save?: (calEvent: CalendarEventProto) => void;
    onDelete?: (id: number) => void;
    event?: CalendarEvent | CalendarEventProto;
    statusOptions: CalendarStatusOption[];
    client?: string;
}

export interface WeekViewProps {
    openEvent: ( event: CalendarEvent | CalendarEventProto ) => void;
    start: Date| undefined; 
    setStart: (date: Date) => void;
    events: CalendarEvent[];
    userSelect: JSX.Element;
    statusOptions: CalendarStatusOption[];
}

export class Calendar {

    public startActHour: number = 8;
    public endActHour: number = 18;
    public slotMinutes: number = 30;
    public slotHeight: number = 25;

    private dayNames: string[] = [
        'MA','TI','KE','TO','PE','LA','SU',
    ];

    private calendarEvents: CalendarEvent[] = [];
    private eventsLoaded: any = false;

    public start: Date = new Date();
    public end: Date = new Date();
    public weekEnd: Date = new Date();
    public hours: Date[] = [];
    public clockEnd: Date | undefined;

    public fiDate = timeFormat("%_d.%_m.%Y");
    public fiShortDate = timeFormat("%_d.%_m.");
    public timestampToDate = timeParse("%s");
    public clock = timeFormat("%H:%M");

    public weekNumber = timeFormat("%V/%Y");
    public dayNumber = timeFormat("%u");
    public dayAndTime = timeFormat("%_d.%_m. %H:%M");
    public timestamp = timeFormat("%s");

    public dayName( date: Date ) {
        const num: number = Number( this.dayNumber(date) );
        return this.dayNames[num-1];
    }

    public stampToDate( stamp: number | undefined ){
        const dateToString: string | undefined = stamp?.toString();
        let date: Date | null;
        if ( dateToString ) {
            date = this.timestampToDate( dateToString );
            if ( date ) {
                return this.fiDate( date );
            }
        }
        return this.fiDate( new Date() );
    }

    public stampToClock( timestamp: number | undefined ){
        const dateToString: string | undefined = timestamp?.toString();
        let date: Date | null;
        if ( dateToString ) {
            date = this.timestampToDate( dateToString );
            if ( date ) {
                return this.clock( date );
            }
        }
        return this.clock( new Date() );
    }

    private forceTimestampToDate( stamp: number ){
        const date = this.timestampToDate(stamp.toString());
        if ( date ) {
            return date;
        }
        return new Date();
    }

    public getDayY(day: Date | Number){
        const dayDate: Date = day instanceof Date
            ? day 
            : this.forceTimestampToDate( Number( day ) );
        let dayStamp: number = day instanceof Date 
            ? Number( this.timestamp(day) )
            : Number( day );

        const yHeight: number = this.hours.length * this.slotHeight;
        const dayClockEnd = timeHour.offset(dayDate, 24);
        const y = scaleLinear().domain([dayStamp, Number(this.timestamp(dayClockEnd))]).range([0, yHeight]);
        //console.log(y);
        return y;
    }

    private setStart(date: Date){}

    public nextWeek(){
        this.setStart( timeDay.offset(this.start, 7) );
    }

    public prevWeek(){
        this.setStart( timeDay.offset(this.start, -7) );
    }

    public today(){
        this.setStart( timeMonday(new Date()) );
    }

    private setTimeFrame(date?: Date){
        if ( date ) {
            this.start = timeMonday(date);
        } else {
            this.start = timeMonday(new Date());
        }
        
        this.end = timeDay.offset(this.start, 5);
        this.clockEnd = timeHour.offset(this.start, 24);
        this.weekEnd = timeHour.offset(this.end, 24);
        this.hours = timeMinute.range(this.start, this.clockEnd, this.slotMinutes );

    }

    public getWeekEnd( date: Date ){
        return timeDay.offset(date, 7);
    }

    public addEvent( time?: Date, duration?: number ){
        const eventProto: CalendarEventProto = {};
        let durEnd: Date;
        let startTime: Date = new Date();
        if ( time ) {
            startTime = time;
        }
        eventProto.starttime = Number( this.timestamp( startTime ) );
        if ( !duration ) {
            durEnd = timeMinute.offset(startTime, this.slotMinutes);
        } else {
            durEnd = timeMinute.offset(startTime, duration);
        }
        eventProto.endtime = Number( this.timestamp( durEnd ) );
        this.openEvent( eventProto );
        // alert( ':: ' + dayAndTime( time ) + ' => ' + timestamp( time ) );
    }

    public openEvent( event: CalendarEventProto | CalendarEvent ){}

    public showDialog(props: CalDialogProps){
        const { onClose, event, save, onDelete, statusOptions, client } = props;
        return (
            <EventDialog
                open={event ? true : false}
                save={save}
                onClose={onClose}
                onDelete={onDelete}
                appointment={event}
                stampToDate={this.stampToDate.bind(this)}
                stampToClock={this.stampToClock.bind(this)}
                statusOptions={statusOptions}
                client={client}
            />
        );
    }

    /*public async getWeekEvents(start: Date, end: Date): Promise<any>{
 
    }*/

    public createWeekDays(start: Date, end: Date){
        const days: CalDay[] = [];
        if ( start && end ) {
            timeDay.range( start, end ).map(day => {
                const hours: Date[] = [];
                const dayEnd: Date = timeHour.offset(day, 24);
                //const dayClockEnd = timeHour.offset(day, 24);
                timeMinute.range(day, dayEnd, this.slotMinutes ).map( hour => {
                    hours.push( hour );
                });
                // this.getEvents()
                //console.log(this.calendarEvents);
                let dayEvents: CalendarEvent[] = [];
                if ( this.calendarEvents && this.calendarEvents.length > 0 ) {
                    dayEvents = this.calendarEvents.filter((element) => { //, index, array
                        if ( element.starttime >= Number(this.timestamp(day)) && element.starttime <= Number(this.timestamp(dayEnd)) ) {
                            return true;
                        }
                        return false;
                    });
                }
                
                days.push({
                    name: this.dayName(day),
                    date: this.fiShortDate(day), // this.fiDate(day),
                    hours: hours,
                    events: dayEvents,
                });
            });
        }
        return days;
    }

    /*
    public async loadEvents(start: Date, end: Date): Promise<any> {
        const startStamp = this.timestamp(start);
        const endStamp = this.timestamp(end);
        
        const token =  useSelector((state: RootState) => state.user.token);
        const apiConnect = new ApiConnection(token);
        console.log( startStamp + ' - ' + endStamp);
        apiConnect.setValues({
            starttime: startStamp,
            endtime: endStamp,
        });
        let promise = new Promise((resolve, reject) => {
            apiConnect.connect('calendars/listOwn').then( response => {
                this.calendarEvents = response;
                resolve(response);
            });
        });

        return promise;

    }*/

    public showWeek(props: WeekViewProps){
        const { openEvent, start, setStart, events, userSelect, statusOptions } = props;
        console.log( 'showWeek' );
        if ( start && start instanceof Date && start !== this.start ) {
            this.setTimeFrame( start );
        } else {

        }

        this.setStart = setStart;
        this.openEvent = openEvent;
        const weekTitle: string = "Viikko "+this.weekNumber(this.start);
        const actHours = {
            start: timeHour.offset(this.start, this.startActHour),
            end: timeHour.offset(this.start, this.endActHour),
        };
        const startTime = timeHour.offset(this.start, this.startActHour);
        const actSlotHours = timeMinute.range(
            startTime, 
            timeHour.offset(this.start, this.endActHour), 
            this.slotMinutes
        );
        const slotTotalHeight: number = this.slotHeight * actSlotHours.length;

        const days = this.createWeekDays(this.start, this.end);

        const week: JSX.Element = <WeekView
            days={days}
            actHours={actHours}
            slotHeight={this.slotHeight}
            slotTotalHeight={slotTotalHeight}
            weekTitle={weekTitle}
            clock={this.clock.bind(this)}
            getDayY={this.getDayY.bind(this)}
            addEvent={this.addEvent.bind(this)}
            openEvent={this.openEvent.bind(this)}
            nextWeek={this.nextWeek.bind(this)}
            prevWeek={this.prevWeek.bind(this)}
            today={this.today.bind(this)}
            events={events}
            timestamp={this.timestamp.bind(this)}
            userSelect={userSelect}
            statusOptions={statusOptions}
        />;
        return week;
        
    }

    public saveCalendarEvent( event: CalendarEventProto ){
        const token =  useSelector((state: RootState) => state.user.token);
        const apiConnect = new ApiConnection(token);
        apiConnect.setValues({
            starttime: event.starttime,
            endtime: event.endtime,
            // userid: ,
            subject: event.subject,
            text: event.text,
            status_id: event.status_id,
            status: event.status,
            client_id: event.client_id,
        });
        apiConnect.connect('calendars/add').then( response => {
            console.log( response );
		})
        .catch( error => {
			console.log( 'ERROR: '+error);
        });
    }

    constructor() {
        this.setTimeFrame();
	}
}