export interface Service {
    id: number;
    name: string;
    group_id: number;
    customer_id: number;
    url: string;//"search"
    order: number;
    access: number;
};

export enum ServiceActions {
    ADD_SERVICES = "ADD_SERVICES",
    ADD_SERVICE = "ADD_SERVICE",
    RESET = "RESET",
}

interface ServiceActionType<T, P> {
    type: T;
    payload: P;
}

export type ServiceAction =
    | ServiceActionType<typeof ServiceActions.ADD_SERVICES, Service[]>
    | ServiceActionType<typeof ServiceActions.ADD_SERVICE, Service>
    | ServiceActionType<typeof ServiceActions.RESET, null>
;