import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, Grid, TextField, Button, Select, MenuItem } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/index";
import { Client, ClientOffers, ClientAccepts, AddClientOffers, AddClientAccepts } from "../../model/client"; 
import { FundingCompany } from "../../model/fundingcompany"; 

import ApiConnection from "../../actions/api/connect";
import { AppConfig } from "../../AppConfig";
import { history } from "../../configureStore";

import { BasicAlert, IBasicAlert } from '../../components/Alerts/BasicAlert';

import { TableWithEditor } from '../../components/TableWithEditor';

const useStyles = makeStyles({
	paper: {
        padding: 20,
        paddingTop: 15,
	},
	table: {
		width: "100%",
	},
});

function OffersAndAccepts({
    Cclient,
}: {
    Cclient: Client
}) {
    const classes = useStyles();
    const apiDomain = AppConfig.apiUrl;
	const token =  useSelector((state: RootState) => state.user.token);

	const [ClientAccepts, setClientAccepts] = React.useState<ClientAccepts[]>([]);
	const [ClientOffers, setClientOffers] = React.useState<ClientOffers[]>([]);
	const [FundingCompanies, setFundingCompanies] = React.useState<FundingCompany[]>([]);


    const [alert, setAlert] = React.useState<IBasicAlert>({});
    const [info, setInfo] = React.useState<Info>({
        showAcceptsList: false,
        showOffersList: false,       
    });

    const apiConnect = new ApiConnection(token);

    function getFundingCompanies(){
        apiConnect.setValues({});
        apiConnect.connect( 'fundingCompanies/list' ).then(response => {
            setFundingCompanies( response );

        })
        .catch( error => {
        console.log( 'ERROR: '+error);
        });
    }



    function listOffers(){
		apiConnect.setValues({
			client_id: Cclient.id,
		});
        apiConnect.connect( 'ClientFundingOffers/list' ).then(data => {
            console.log(data);
			setClientOffers( data );
        })
        .catch( error => {
        });
    }

    function saveOffer(offer: ClientOffers) {
        if (offer.id) {
            apiConnect.setValues({
                id: offer.id,
                client_id: Cclient.id,
                funding_company_id: offer.funding_company_id || '0',
                amount: offer.amount || '0',
                repayment_period: offer.repayment_period || '0',
                percent: offer.percent || '0',
                total_amount: offer.total_amount || '0',
            });
        } else {
            apiConnect.setValues({
                client_id: Cclient.id,
                funding_company_id: offer.funding_company_id || '0',
                amount: offer.amount || '0',
                repayment_period: offer.repayment_period || '0',
                percent: offer.percent || '0',
                total_amount: offer.total_amount || '0',
            });
        }

        apiConnect.connect( 'ClientFundingOffers/add' ).then(response => {
            console.log(response);
            listOffers();
        })
        .catch( error => {
            console.log( 'ERROR!!: '+error);
        });
    }

    function deleteOffer(id: number) {
        apiConnect.setValues({
			client_id: Cclient.id,
			id: id,
        });

        apiConnect.connect( 'ClientFundingOffers/delete' ).then(response => {
            listOffers();
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    function listAccepts(){
		
		apiConnect.setValues({
			client_id: Cclient.id,
		});
        apiConnect.connect( 'ClientFundingAccepts/list' ).then(data => {
			setClientAccepts( data );
        })
        .catch( error => {
        });
    }

    function saveAccept(accept: ClientAccepts) {
        if (accept.id) {
            apiConnect.setValues({
                id: accept.id,
                client_id: Cclient.id,
                funding_company_id: accept.funding_company_id || '0',
                amount: accept.amount || '0',
                repayment_period: accept.repayment_period || '0',
                percent: accept.percent || '0',
                total_amount: accept.total_amount || '0',
                guarantees: accept.guarantees || ''
            });
        } else {
            apiConnect.setValues({
                client_id: Cclient.id,
                funding_company_id: accept.funding_company_id || '0',
                amount: accept.amount || '0',
                repayment_period: accept.repayment_period || '0',
                percent: accept.percent || '0',
                total_amount: accept.total_amount || '0',
                guarantees: accept.guarantees || ''
            });
        }
        apiConnect.connect( 'ClientFundingAccepts/add' ).then(response => {
            console.log(response);
            listAccepts();
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    function deleteAccept(id: number) {
        apiConnect.setValues({
			client_id: Cclient.id,
			id: id,
        });

        apiConnect.connect( 'ClientFundingAccepts/delete' ).then(response => {
            listAccepts();
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    if (info.showAcceptsList == false) {
        listAccepts();
        setInfo({...info, 'showAcceptsList': true});
    }
    if (info.showOffersList == false) {
        getFundingCompanies();
        listOffers();
        setInfo({...info, 'showOffersList': true});
    }
    
    const emptyClientOffer = {
        time: '',
    }

    function handleSendOfferEmail() {
        history.push({
            pathname: '/client/offeremail',
            state: {client_id: Cclient.id},
        });
    }

    const emptyClientFile = {};
    // const saveFile 
    const saveAcceptAttachment = (data: any) => {
        if (data.file && data.__row_id) {
            // client_id
            // file
            // title
            // funding_accept_id
            var formData = new FormData();
            formData.append('file', data.file);
            formData.append("client_id", String(Cclient.id));
            formData.append('funding_accept_id', data.__row_id)
            if ( data.title && data.title !== "" ) {
                formData.append('title', data.title);
            } else {
                formData.append('title', data.file.name);
            }

            fetch(apiDomain+'ClientAttachments/add', {
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Bearer '+token, 
                  }), 
                body: formData
            }).then(res => {
                if(res.ok) {
                    listAccepts();
                }
            });
        }
    }
    const deleteAcceptAttachment = (id: number) => {
        apiConnect.setValues({
			client_id: Cclient.id,
			id: id,
        });
        apiConnect.connect( 'clientAttachments/delete' ).then(response => {
            listAccepts();
        })
        .catch( error => {
        console.log( 'ERROR: '+error);
        });
    }

    const acceptAttachments = {
        title: 'Tiedostot',
        noEdit: 'false',
        columns: [
            {
                title: 'ID',
                id: 'id',
                hide: true,
            },
            {
                title: 'Lisätty',
                id: 'created_at',
                type: 'datetime'
            },
            {
                title: 'Tiedoston nimi',
                id: 'title',
                edit: false,
            },
            {
                title: 'Tiedosto',
                id: 'file',
                edit: true,
                hide: true,
                type: 'file',
                typeSettings: {
                    selectedFileName: 'Ei valittua tiedostoa',
                    fileNameId: 'title',
                },
            },
            {
                title: 'Toiminto',
                id: 'id',
                edit: false,
                type: 'link',
                url: 'Attachments/downloadFile/attachment',
                sha: 'sha',
            },
        ],
        itemData: [],
        emptyItem: emptyClientFile,
        onSave: saveAcceptAttachment,
        onDelete: deleteAcceptAttachment,
    };

    return (
        <Paper className={classes.paper} square={true}>
            <Grid item xs={12} style={{textAlign: "right"}}>
                <Button
                    style={{marginLeft: "1rem", marginBottom: "0.5rem"}}
                    variant="contained" 
                    color="secondary" 
                    size="small" 
                    disableElevation
                    onClick={ () => handleSendOfferEmail() }
                    >
                    Lähetä rahoituspyyntö sähköpostilla
                </Button>
            </Grid>
            <TableWithEditor
                title="Rahoitustarjoukset"
                noEdit="false"
                columns={[
                    {
                        title: 'ID',
                        id: 'id',
                        hide: true,
                    },
                    {
                        title: 'Aika',
                        id: 'time',
                        type: 'datetime'
                    },
                    {
                        title: 'Rahoitusyhtiö',
                        id: 'funding_company_id',
                        edit: true,
                        type: 'select',
                        typeSettings: {
                            options: FundingCompanies,
                            selectValues: {
                                id: 'id',
                                name: 'name',
                            },
                        }
                    },
                    {
                        title: 'Summa',
                        id: 'amount',
                        edit: true,
                        type: 'sum',
                    },
                    {
                        title: 'Maksuaika(kk)',
                        id: 'repayment_period',
                        edit: true,
                    },
                    {
                        title: 'Korko(%)',
                        id: 'percent',
                        edit: true,
                    },
                    {
                        title: 'Kokoissumma',
                        id: 'total_amount',
                        edit: true,
                        type: 'sum',
                    },
                    {
                        title: 'Toiminto',
                        id: 'id',
                        edit: false,
                        type: 'link',
                        url: 'ClientFundingOffers/downloadPdf',
                        sha: 'sha',
                        linkTitle: 'Lataa (pdf)',
                    },
                ]}
                itemData={ClientOffers} //
                emptyItem={emptyClientOffer}
                onSave={saveOffer}
                onDelete={deleteOffer}
            />
            <div style={{marginTop: "2rem"}} />
            <TableWithEditor
                title="Hyväksytyt rahoitukset"
                noEdit="false"
                columns={[
                    {
                        title: 'ID',
                        id: 'id',
                        hide: true,
                    },
                    {
                        title: 'Hyväksymisaika',
                        id: 'time',
                        type: 'datetime'
                    },
                    {
                        title: 'Rahoitusyhtiö',
                        id: 'funding_company_id',
                        edit: true,
                        type: 'select',
                        typeSettings: {
                            options: FundingCompanies,
                            selectValues: {
                                id: 'id',
                                name: 'name',
                            },
                        }
                    },
                    {
                        title: 'Summa',
                        id: 'amount',
                        edit: true,
                        type: 'sum',
                    },
                    {
                        title: 'Maksuaika(kk)',
                        id: 'repayment_period',
                        edit: true,
                    },
                    {
                        title: 'Korko(%)',
                        id: 'percent',
                        edit: true,
                    },
                    {
                        title: 'Kokoissumma',
                        id: 'total_amount',
                        edit: true,
                        type: 'sum',
                    },
                    {
                        title: 'Vakuudet',
                        id: 'guarantees',
                        edit: true,
                        hide: true,
                        type: 'multiline',
                    },
                    {
                        title: 'Tiedostot',
                        id: 'attachments',
                        edit: true,
                        type: 'list',
                        list: acceptAttachments,
                    }

                ]}
                itemData={ClientAccepts}
                emptyItem={emptyClientOffer}
                onSave={saveAccept}
                onDelete={deleteAccept}
            />
        </Paper>
    );
};

export default OffersAndAccepts;

interface Info{
    showAcceptsList: any;
    showOffersList: any;     
};
