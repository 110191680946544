import * as React from "react";
import { MenuItem, FormControl, InputLabel, Select } from "@material-ui/core";

import { columnItem, selectValues } from "../interfaces";

export default function InputSelect(
    props: {
        column: columnItem;
        colVal: string | number;
        onInputChange?: (prop: keyof any, file: any) => void;
    }
): JSX.Element {

    const { column, colVal, onInputChange } = props;

    const optVal = column.typeSettings && column.typeSettings.selectValues && column.typeSettings.selectValues.name
        ? column.typeSettings.selectValues.name
        : 'name';
    const optKey = column.typeSettings && column.typeSettings.selectValues && column.typeSettings.selectValues.id
        ? column.typeSettings.selectValues.id
        : 'id';

    const handleSelectChange = (prop: keyof any) => (event: React.ChangeEvent<{ value: any }>) => {

        //console.log("menee tänne2");
        if (onInputChange) {
            //console.log("menee tänne");
            onInputChange(prop, event.target.value);
        }
        //setValues({ ...values, [prop]: event.target.value });
    };
    
    if (onInputChange) {
        return <FormControl>
            <InputLabel
                id={column.id+'-label'}
            >
                {column.title}
            </InputLabel>
            <Select
                fullWidth
                id={column.id}
                labelId={column.id+'-label'}
                defaultValue={colVal}
                //value={colVal}
                onChange={handleSelectChange(column.id)}
                // onChange={handleAccessChange('user_id')}
            >                        
                <MenuItem value="0">Valitse</MenuItem>
                {
                    column.typeSettings && column.typeSettings.options && column.typeSettings.options.map((option: selectValues, key: number) => {
                        //const val: number = option && optKey && option.hasOwnProperty(optKey) option[optKey];
                        if ( option && optKey && option.hasOwnProperty(optKey) && option.hasOwnProperty(optVal) ) {
                            const val: number = Number( option.id ); // option[optKey]
                            const name: string = option.name.toString();
                            return(
                                <MenuItem key={key} value={val}>{name}</MenuItem>
                            )
                        } else {
                            return '';
                        }
                    })
                }
            </Select>
        </FormControl>;
    }
    return <React.Fragment></React.Fragment>;
    
};