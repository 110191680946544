import * as React from "react";

import { connect } from 'react-redux';

import { MainPages, LoginDefault } from "./RoutePages";

import { User } from "../../model/user";

import AppHeader from "../AppHeader/AppHeader";

type RouteProps = {
	user?: User,
	// classes?: any,
}

interface IState {
    user?: User;
}

const mapStateToProps = (state: IState, props: RouteProps) => ({
	user: state.user,
});
//const minLeft = useSelector((state: RootState) => state.user.minutes_left);
class RouteHandler extends React.Component<RouteProps>{

	constructor(props: RouteProps) {
		super(props);
	}

	render() {
		//console.log('RouteHandler '+JSON.stringify(this.props));
		//const expTime = useSelector((state: RootState) => state.user.token_expires);
		//if ( this.props.user && this.props.user.token_expires ) console.log('expTime: '+this.props.user.token_expires);
		if ( this.props.user && this.props.user.logged ) {
			//&& this.props.user.minutes_left
			// if (!this.props.user.minutes_left) this.props.user.minutes_left = 30;
			return (
				<React.Fragment>
					<AppHeader 
						logged={true}
					/>
					<MainPages />
				</React.Fragment>
			);
		}
		return (
			<React.Fragment>
				<AppHeader 
					logged={false}
				/>
				<LoginDefault />
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(RouteHandler);