import { useActions } from "../actions";
import * as UserActions from "../actions/user";
import * as ServiceActions from "../actions/service";
import { history } from "../configureStore";

export function LogoutPage() {
    console.log('Kirjaudutaan ulos');
    const userActions = useActions(UserActions);
    const serviceActions = useActions(ServiceActions);
    userActions.logout();
    serviceActions.reset();
    history.push('/');
    return ('Kirjaudutaan ulos');
}