import DefaultTemplate from "../../components/PageTemplates/DefaultTemplate";

import { Button, Grid } from "@material-ui/core";

import * as React from "react";

import ApiConnection from "../../actions/api/connect";

import { useSelector } from "react-redux";
import { RootState } from "../../reducers/index";

import UserList from "../../components/Settings/UserList";
import { UserEdit, UserAccess, ServiceAccess } from "../../components/Settings/UserEdit";

import { User } from "../../model/user"; //UserAction, UserActions,
import { Service } from "../../model/service";

import { BasicAlert, IBasicAlert } from '../../components/Alerts/BasicAlert';

export function UsersPage() {
	const token =  useSelector((state: RootState) => state.user.token);
	const [userList, setUserList] = React.useState<User[]>([]);
	const [serviceList, serServiceList] = React.useState<Service[]>([]);
	// const [userServices, setUserServices] = React.useState<ServiceAccess[]>([]);
	const [selectedUser, setSelectedUser] = React.useState<User>();
	const [alert, setAlert] = React.useState<IBasicAlert>({});

	const apiConnect = new ApiConnection(token);
	
	function resetActUser(){
		setAlert({});
		setSelectedUser(undefined);
		// setUserServices([]);
	}

	function getServices(){
		apiConnect.setValues({});
		apiConnect.connect( 'users/listServices' ).then( response => {
			serServiceList( response.data.services );
		})
        .catch( error => {
			console.log( 'ERROR: '+error);
        });
	}

	function getUserServices( user_id: number | undefined ){
		console.log('getUserServices '+user_id);
		if( user_id ){
			
			apiConnect.setValues( {
				id: user_id
			} );
			apiConnect.connect( 'users/getAdmin' ).then( response => {
				console.log( response );
				const userAccess: ServiceAccess[] = [];

				response.data.user.service_access.map((service: {
					service_id: number,
					access: number,
				}) => {
					userAccess.push({
						id: service.service_id,
						value: service.access,
					});
				});
				
				setSelectedUser( {
					id: response.data.user.id,
					username: response.data.user.username,
					name: response.data.user.name,
					service_access: userAccess,
				} );
				//setUserServices( userAccess );
			})
			.catch( error => {
				
			});
		}
	}

	function setUser(user: User ){
		setAlert({});
		if ( user.id ) {
			getUserServices( user?.id ? user.id : undefined );
		}
		else {
			setSelectedUser( user );
		}
	}

	function setUserPrivileges( user_id: number | undefined, access: ServiceAccess[] ){

		let privileges: Object = {
			user_id: user_id,
		};

		access.map((priv: ServiceAccess ) => {
			privileges = { ...privileges, ["service_access["+priv.id+"]"]: priv.value };
		});

		apiConnect.setValues( privileges );

		apiConnect.connect( 'users/addServiceAccess' ).then( response => {
			resetActUser();
			setAlert({
				type: 'success',
				message: 'Käyttäjätiedot tallennettu.',
			});
			getUsers();
		})
		.catch( error => {
			
		});

	}

	function saveUser( edit: UserAccess ){

		let warning: string | undefined;

		if ( !edit.user.id ) {
			if ( !edit.password || ( edit.password && !edit.password.password ) ) {
				warning = 'Uudelle käyttäjälle on syötettävä salasana.';
			} else if ( edit.password && edit.password.password.length < 8 ) {
				warning = 'Salasanan tulee olla vähintään kahdeksan (8) merkin mittainen.';
			} else if ( edit.password?.password !== edit.password?.password_repeat ) {
				warning = 'Salasanat eivät ole yhdenmukaiset.';
			}
		}

		if ( !edit.user.name || edit.user.name.length < 2 ) {
			warning = 'Täytä käyttäjän nimi. Käyttäjän nimen on oltava vähintään kahden (2) merkin mittainen.';
		} else if ( !edit.user.username ) { //|| edit.user.username.length < 6
			warning = 'Täytä sähköpostiosoite.';
		}

		if ( warning ) {
			setAlert({
				type: 'warning',
				message: warning,
			});
		} else {
			setAlert({
				type: 'info',
				message: 'Tallenetaan käyttäjän tietoja.',
			});

			console.log(edit);
			
			if ( !edit.user.id ) {
				console.log( 'Uusi käyttäjä?' );
				apiConnect.setValues({
					name: edit.user.name,
					username: edit.user.username,
					password: edit.password?.password,
				});
				apiConnect.connect( 'users/add' ).then( response => {
					// console.log('response: '+JSON.stringify(response));
					setUserPrivileges( response.data.data.id, edit.access );
				})
				.catch( error => {
					console.log( 'ERROR: '+error);
				});

			} else if (
				selectedUser
				&& (
					selectedUser.name !== edit.user.name
					|| selectedUser.username !== edit.user.username
				)
			) {
				apiConnect.setValues({
					id: edit.user.id,
					name: edit.user.name,
					username: edit.user.username,
				});
				apiConnect.connect( 'users/add' ).then( response => {
					//console.log('response: '+JSON.stringify(response));
					setUserPrivileges( edit.user.id, edit.access );
				})
				.catch( error => {
					console.log( 'ERROR: '+error);
				});
			}else {
				setUserPrivileges( edit.user.id, edit.access );
			}

		}

		/*else {
			setAlert({
				type: 'warning',
				message: 'Käyttäjätietojen tallentaminen epäonnistui.',
			});
            setAlert({
                type: 'info',
                message: 'Vaihdetaan salasanaa...',
			});
		}*/
	}

    function getUsers(){
		apiConnect.setValues({});
        apiConnect.connect( 'users/listUsers' ).then(response => {
            
            setUserList( response.data.users );

        })
        .catch( error => {
			console.log( 'ERROR: '+error);
            /*
            setAlert({
                type: 'warning',
                message: 'Salasanan vaihtaminen epäonnistui.',
            });
            */
        });
    }

    if ( userList.length === 0 ) {
        getUsers();
	}
	
	if ( serviceList.length === 0 ) {
        getServices();
	}

	if ( selectedUser ) {

		return (
			<DefaultTemplate
				title={selectedUser.name ? 'Muokkaa' : 'Lisää uusi käyttäjä'}
				content={
					<Grid container spacing={3}>
						{
							alert
								? 
								<Grid item xs={12}>
									<BasicAlert 
										type={alert.type}
										message={alert.message}
									/>
								</Grid>
								: ''
						}
						<Grid item xs={12}>
							<UserEdit
								user={selectedUser}
								serviceList={serviceList}
								cancel={resetActUser}
								save={saveUser}
							/>
						</Grid>
					</Grid>
				}
			/>
		);
	}
	
	return (
		<DefaultTemplate
			title="Käyttäjähallinta"
			actions={
				<Button 
					variant="contained"
					color="secondary"
					size="small"
					disableElevation
					onClick={() => setUser({}) }
				>
					Lisää uusi
				</Button>
			}
			content={
				<Grid container spacing={3}>
					{
						alert
							? 
							<Grid item xs={12}>
								<BasicAlert 
									type={alert.type}
									message={alert.message}
								/>
							</Grid>
							: ''
					}
					<Grid item xs={12}>
						<UserList 
							userList={userList}
							select={setUser}
						/>
					</Grid>
				</Grid>
			}
		/>
	);
};