import * as React from "react";

/*
import {
	HomePage,
	SearchPage,
	CallPage,
	TodoPage,
	CalendarPage,
	SettingsPage,
} from "../../pages";
*/
import * as Pages from "../../pages";

import HomeIcon from "@material-ui/icons/Home";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import CallcenterIcon from "@material-ui/icons/SettingsPhone";
import SearchIcon from "@material-ui/icons/Search";
import { TodoIcon } from "../MainMenu/TodoIcon";
import SettingsIcon from '@material-ui/icons/Settings';
import AddCircle from '@material-ui/icons/AddCircle';

const MainMenuItems: MenuItem[] = [

];

export const MenuItems: MenuItem[] = [
	{
		title: "Etusivu",
		path: "/",
		component: Pages.HomePage,
        icon: <HomeIcon />,
	},
	{
		title: "Asiakashaku",
		path: "/search",
		component: Pages.SearchPage,
        icon: <SearchIcon />,
        id: 2,
	},
	{
		title: "Uusi asiakas",
		path: "/newclient",
		component: Pages.CreateClientPage,
        icon: <AddCircle />,
		id: 3,
	},
	{
		title: "Soittokone",
		path: "/call",
		component: Pages.CallPage,
        icon: <CallcenterIcon />,
        id: 6,
		sub: [
			{
				title: "Lisää prospekti",
				path: "/addprospect",
				component: Pages.AddProspectPage,
				id: 6,
			},
		]
	},
	{
		title: "Muistutukset",
		path: "/reminders",
		component: Pages.RemindersPage,
        icon: <TodoIcon />,
        id: 5,
	},
	{
		title: "Kalenteri",
		path: "/calendar",
		component: Pages.CalendarPage,
        icon: <CalendarIcon />,
        id: 4,
	},
	{
		title: "Asetukset",
		path: "/settings",
		component: Pages.SettingsPage,
		icon: <SettingsIcon />,
		sub: [
			{
				title: "Käyttäjähallinta",
				path: "/users",
				component: Pages.UsersPage,
				id: 1,
			},
			{
				title: "Prospektien hallinta",
				path: "/sharenumbers",
				component: Pages.SharenumbersPage,
				id: 7,
			},
			{
				title: "Rahoituskumppanit",
				path: "/fundingcompanies",
				component: Pages.FundingCompaniesPage,
				id: 9,
			},
			{
				title: "Sopimusten hallinta",
				path: "/contracts",
				component: Pages.ContractsPage,
				id: 10,
			},

		],
	},
	{
		title: "Kirjaudu ulos",
		path: "/logout",
		component: Pages.LogoutPage,
		hide: true,
	},
	{
		title: "Rahoituskumppanin yhteyshenkilöt",
		path: "/settings/fundingcompanies/contacts",
		component: Pages.FundingContactsPage,
		hide: true,
	},
	{
		title: "Client",
		path: "/client",
		component: Pages.ClientPage,
		hide: true,
	},
	{
		title: "Signature",
		path: "/client/signature",
		component: Pages.SignaturePage,
		hide: true,
	},
	{
		title: "Signature",
		path: "/client/offeremail",
		component: Pages.ClientOfferEmailPage,
		hide: true,
	},
];

export interface MenuItem {
	title: string;
	path: string;
	component: any;
    icon?: JSX.Element;
	id?: number;
	sub?: MenuItem[];
	hide?: Boolean;
}
