// export * from "./ReminderDialog";
// export * from "./ReminderTable";

import { addReminder, Reminders } from "../../model/reminder";

import ApiConnection from "../../actions/api/connect";

//import { useSelector } from "react-redux";
//import { RootState } from "../../reducers/index";

export class ReminderApi extends ApiConnection {
    private urlPart: string = 'reminders/';

    public list(){
        this.setValues({});
        return this.connect(this.urlPart+'list');
    }

    public listClient(vals?: Object){
        console.log('Reminder List');
        console.log(vals);
        if (vals) {
            this.setValues(vals);
        } else {
            this.setValues({});
        }
        return this.connect(this.urlPart+'listClient');
    }

    public save(selRem: addReminder){
        if ( !selRem.time ) selRem.time = 0;
        console.log( selRem );
		this.setValues(selRem);
        return this.connect(this.urlPart+'add');
    }

    public delete(id: number){
        this.setValues({
            id: id,
        });
        return this.connect(this.urlPart+'delete');
    }
}
