import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/index";

import ApiConnection from "../../../actions/api/connect";
import { TableWithEditor } from '../../../components/TableWithEditor';

import { Client, ClientEmails } from "../../../model/client"; 

function EmailList({
    Cclient,
    OnUpdate,
}: {
    Cclient: Client,
    OnUpdate?: () => void,
}) {

    const token =  useSelector((state: RootState) => state.user.token);

    const apiConnect = new ApiConnection(token);

    const [ClientEmails, setClientEmails] = React.useState<ClientEmails[] | undefined>(undefined);

    function listEmails(){
		apiConnect.setValues({
			client_id: Cclient.id,
		});
        apiConnect.connect( 'ClientEmails/list' ).then(data => {
            console.log(data);
			setClientEmails( data );
        })
        .catch( error => {
        });
    }

    function saveEmail(email: ClientEmails){
        if (email.id) {
            apiConnect.setValues({
                id: email.id,
                client_id: Cclient.id,
                from_address: email.from_address || '',
                to_address: email.to_address || '',
                subject: email.subject || '',
                text: email.text || '',
            });
        } else {
            apiConnect.setValues({
                client_id: Cclient.id,
                from_address: email.from_address || '',
                to_address: email.to_address || '',
                subject: email.subject || '',
                text: email.text || '',
            });
        }
        apiConnect.connect( 'ClientEmails/add' ).then(response => {
            //console.log(response);
            if (OnUpdate) OnUpdate();
            listEmails();
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    function deleteEmail(id: number) {
        apiConnect.setValues({
			client_id: Cclient.id,
			id: id,
        });

        apiConnect.connect( 'ClientEmails/delete' ).then(response => {
            if (OnUpdate) OnUpdate();
            listEmails();
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    const emptyClientEmail = {
        to_address: '',
        from_address: '',
        subject: '',
        text: '',
    };

    React.useEffect(() => {
        listEmails();
    }, [])


    return (<TableWithEditor
        title="Sähköpostit"
        noEdit="true"
        columns={[
            {
                title: 'ID',
                id: 'id',
                hide: true,
            },
            {
                title: 'Aika',
                id: 'time',
                type: 'datetime'
            },
            {
                title: 'Vastaanottaja',
                id: 'to_address',
                edit: true,
            },
            {
                title: 'Lähettäjä',
                id: 'from_address',
                edit: true,
            },
            {
                title: 'Aihe',
                id: 'subject',
                edit: true,
            },
            {
                title: 'Viesti',
                id: 'text',
                hide: true,
                edit: true,
                type: 'multiline',
            },
        ]}
        itemData={ClientEmails ? ClientEmails : []}
        emptyItem={emptyClientEmail}
        onSave={saveEmail}
        onDelete={deleteEmail}
    />);

};

export default EmailList;