import { SearchCriteriaAction, SearchCriteriaActions, SearchCriteria } from "../model/searchCriteria";

export function addSearchCriteria(criteria: SearchCriteria): SearchCriteriaAction {
	return {
		type: SearchCriteriaActions.ADD_CRITERIA,
		payload: criteria,
	};
}

export function resetSearchCriteria(name: string) {
    return {
        type: SearchCriteriaActions.RESET_CRITERIA,
        payload: name,
    };
}