import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";

import Login from '../components/Login';

export function LoginPage() {
	const classes = useStyles();

	return (
		<Grid container className={classes.root} justify="center"  spacing={3}>
			<Grid item xs={6}>
				<Login />
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles({
	root: {
		padding: 40,
	},
});
