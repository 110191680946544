import DefaultTemplate from "../components/PageTemplates/DefaultTemplate";

import { Grid, Paper, Table, TableHead, TableBody, TableRow, TableCell, Button, TextField,Typography, InputLabel, Select, MenuItem } from "@material-ui/core";

import * as React from "react";

import ApiConnection from "../actions/api/connect";

import { useSelector } from "react-redux";
import { RootState } from "../reducers/index";
import { BasicAlert, IBasicAlert } from '../components/Alerts/BasicAlert';
import { Client, ClientContacts, Signature } from "../model/client";
import { history } from "../configureStore";
import { AppConfig } from "../AppConfig";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
	paper: {
        padding: 20,
	},
});

export function SignaturePage() {
	const classes = useStyles();

	const token =  useSelector((state: RootState) => state.user.token);
    const client_id = history && history.location && history.location.state && history.location.state.client_id ? history.location.state.client_id : undefined;
    const print = history && history.location && history.location.state && history.location.state.print ? history.location.state.print : undefined;
    const apiDomain = AppConfig.apiUrl;

	const [client, setClient] = React.useState<Client>({});
    const [ClientContacts, setClientContacts] = React.useState<ClientContacts[]>([]);
	const [signature, setSignature] = React.useState<Signature>({});
	const [alert, setAlert] = React.useState<IBasicAlert>({});

    const apiConnect = new ApiConnection(token);

    const handleChange = (prop: keyof Signature) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setSignature({ ...signature, [prop]: event.target.value });
    };

    const handleContactChange = (prop: keyof Signature) => (event: React.ChangeEvent<{ value: any }>) => {
        setSignature({ ...signature, [prop]: event.target.value });
    };

	function handleSave() {
        if (signature && signature.name && signature.email && signature.ssn) {

            apiConnect.setValues({
                client_id: client.id || 0,
                name: signature.name || '',
                email: signature.email || '',
                ssn: signature.ssn || '',
                price: signature.price || '',
                extra: signature.extra || '',
                product_1: signature.product_1 || '',
                productnumber_1: signature.productnumber_1 || '',
                product_2: signature.product_2 || '',
                productnumber_2: signature.productnumber_2 || '',
                product_3: signature.product_3 || '',
                productnumber_3: signature.productnumber_3 || '',
                contactperson: signature.contactperson || 0,
            });
            apiConnect.connect( 'Clients/addSignature' ).then(data => {
                history.push({
                    pathname: '/client',
                    state: {client_id: client.id},
                });
            })
            .catch( error => {
                console.log(error);
            });

        }
    }

	function handlePrint() {
        if (signature) {

            apiConnect.setValues({
                client_id: client.id || 0,
                name: signature.name || '',
                email: signature.email || '',
                ssn: signature.ssn || '',
                price: signature.price || '',
                extra: signature.extra || '',
                product_1: signature.product_1 || '',
                productnumber_1: signature.productnumber_1 || '',
                product_2: signature.product_2 || '',
                productnumber_2: signature.productnumber_2 || '',
                product_3: signature.product_3 || '',
                productnumber_3: signature.productnumber_3 || '',
                contactperson: signature.contactperson || 0,
            });
            apiConnect.connect( 'Clients/createPrintContract' ).then(data => {
                window.open(apiDomain+"Clients/printContract/"+data.filename+"/"+data.sha);
            })
            .catch( error => {
                console.log(error);
            });

        }
    }

    function handleCancel() {
        console.log("jotain");
        history.push({
            pathname: '/client',
            state: {client_id: client.id},
        });
    }

    function getClient(client_id: number) {
        apiConnect.setValues({
            id: client_id,
        });
        apiConnect.connect( 'clients/get' ).then(response => {
            setClient( response );

        })
        .catch( error => {
        console.log( 'ERROR: '+error);
        });
    }

    function listContact(client_id: number) {
        apiConnect.setValues({
			client_id: client_id,
        });
        apiConnect.connect( 'clientContacts/list' ).then(response => {
            setClientContacts( response );
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    if (!client.id) {
        getClient(client_id);
        listContact(client_id);
    }

    return (
        <DefaultTemplate
            title=''
            content={
                <Paper square={true} style={{marginTop: "20px"}}>
                    <Grid container spacing={3} className={classes.paper}>
                        <Grid item xs={12}>
                            <Typography variant="h4">Sopimuksen tulostaminen ({client.company})</Typography>
                        </Grid>
                        <Grid item xs={8} sm={5} md={4} lg={3}>
                            <InputLabel
                                id="user_id"

                            >
                                Asiakkaan kontaktihenkilö
                            </InputLabel>
                            {
                                ClientContacts.length > 0 ? (
                                <Select
                                    fullWidth
                                    id="user_id"
                                    //value={client.user_id}
                                    defaultValue={signature.contactperson}
                                    onChange={handleContactChange('contactperson')}
                                >                        
                                    <MenuItem value="0">Valitse</MenuItem>
                                    {
                                        ClientContacts.map((contact: ClientContacts, key: number) => {
                                            return(
                                                <MenuItem key={key} value={contact.id}>{contact.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>) : ''
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="price"
                                label="Palvelun hinta"
                                onChange={handleChange('price')} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="extra"
                                label="Lisäpalvelut tai erikseen sovitut ehdot"
                                onChange={handleChange('extra')} 
                            />
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "1.5rem"}}>
                            <Typography variant="h5">Tilattavat palvelut</Typography>
                        </Grid>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="default">Tuote</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell padding="default">
                                        <TextField
                                            fullWidth
                                            id="product_1"
                                            onChange={handleChange('product_1')} 
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell padding="default">
                                        <TextField
                                            fullWidth
                                            id="product_2"
                                            onChange={handleChange('product_2')} 
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell padding="default">
                                        <TextField
                                            fullWidth
                                            id="product_3"
                                            onChange={handleChange('product_3')} 
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        { print !== 1 ? 
                                <React.Fragment>
                                <Grid item xs={12}>
                                    <Typography variant="h4">Allekirjoittajan tiedot</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="name"
                                        label="Allekirjoittajan nimi"
                                        onChange={handleChange('name')} 
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        label="Allekirjoittajan sähköpostiosoite"
                                        onChange={handleChange('email')} 
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="ssn"
                                        label="Allekirjoittajan henkilötunnus"
                                        onChange={handleChange('ssn')} 
                                    />
                                </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            disableElevation
                                            onClick={ () => handleCancel() }
                                        >
                                            Peruuta
                                        </Button>
                                    </Grid>      
                                    <Grid item xs={6} style={{textAlign: "right"}}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            disableElevation
                                            onClick={ () => handleSave() }
                                        >
                                            Lähetä sopimus sähköiseen allekirjoitukseen
                                        </Button>
                                    </Grid>
                                </React.Fragment>
                        :
                            <React.Fragment>
                                <Grid item xs={6}>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        disableElevation
                                        onClick={ () => handleCancel() }
                                    >
                                        Peruuta
                                    </Button>
                                </Grid>      
                                <Grid item xs={6} style={{textAlign: "right"}}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        disableElevation
                                        onClick={ () => handlePrint() }
                                    >
                                        Tulosta sopimus
                                    </Button>
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>
                </Paper>
            }
        />
    );

};
