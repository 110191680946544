
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Button, Divider, FormControl, Grid, TextField, OutlinedInput, Paper, Toolbar, Typography, Select, MenuItem } from "@material-ui/core";

import * as React from "react";
import ApiConnection from "../../actions/api/connect";
import { User } from "../../model/user"; //UserAction, UserActions,
import { SearchReminder } from "../../model/reminder"; //UserAction, UserActions,
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/index";

import { SearchCriteria, Criteria } from "../../model/searchCriteria"
import * as SearchCriteriaActions from "../../actions/searchCriteria";
import { useActions } from "../../actions";

import DateFnsUtils from '@date-io/date-fns';
import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { BasicAlert, IBasicAlert } from '../../components/Alerts/BasicAlert';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
            textAlign: 'right',
        },

        paper: {
            padding: 20,
        },

        footer: {
            backgroundColor: '#fff', // theme.palette.grey["300"],
        }
    }),
);

function RemindersConditions({
    listC
}: {
    listC: React.Dispatch<SearchReminder>
}) {
    // const token =  useSelector((state: RootState) => state.user.token);
    // const apiConnect = new ApiConnection(token);
    const defaultTime = getUnixTime(new Date());
    
    const searchCriteria = useSelector((state: RootState) => state.searchCriteriaList);
    const criteria = searchCriteria ? searchCriteria.filter((c) => { return (c.name === 'reminders') }) : [];
    let defaultSettings = {};
    if (criteria.length > 0 && criteria[0].criteria) {
        const c: Criteria[] = criteria[0].criteria;
        defaultSettings = c;
        //if (c['startdate']) defaultSettings.startdate = c['startdate'];
    } else {
        defaultSettings = {
            startdate: defaultTime,
            enddate: defaultTime,
            type: 0,
        };
    }

    const classes = useStyles();
    const [values, setValues] = React.useState<SearchReminder>(defaultSettings);

    const searchCriteriaActions = useActions(SearchCriteriaActions);

    const handleAccessChange = (prop: keyof SearchReminder) => (event: React.ChangeEvent<{ value: any }>) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    
    const handleChange = (prop: keyof SearchReminder) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

	const handleDateChange = (date: Date | null) => {
        if ( date !== null ) {
            const timestamp = getUnixTime(date);
            setValues({ ...values, startdate: timestamp });
        }
    };
	const handleDateChange2 = (date: Date | null) => {
        if ( date !== null ) {
            const timestamp = getUnixTime(date);
            setValues({ ...values, enddate: timestamp });
        }
	};
    
    function handleSearch() {
       listC(values); 
    }

    function handleReset() {
        searchCriteriaActions.resetSearchCriteria('reminders');
        setValues({
            startdate: defaultTime,
            enddate: defaultTime,
            type: 0,
        });
    }
        
    return (
        <React.Fragment>
            <AppBar position="static" color="secondary">
                <Toolbar variant="dense">
                    <Typography variant="h6">
                        Hakuehdot
                    </Typography>
                </Toolbar>
            </AppBar>
            <Paper square={true}>
                <form noValidate autoComplete="off" className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>

                            <MuiPickersUtilsProvider 
                                utils={DateFnsUtils}
                            >
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd.MM.yyyy"
                                    // type="date-local"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Alkupvm"
                                    value={values?.startdate ? fromUnixTime( values.startdate ) : defaultTime}
                                    // value={fromUnixTime(reminder.time)}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={3}>
                            <MuiPickersUtilsProvider 
                                utils={DateFnsUtils}
                            >
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd.MM.yyyy"
                                    // type="date-local"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Loppupvm"
                                    value={values?.enddate ? fromUnixTime( values.enddate ) : defaultTime}
                                    // value={fromUnixTime(reminder.time)}
                                    onChange={handleDateChange2}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                id="company"
                                label="Yrityksen nimi"
                                onChange={handleChange('company')} 
                                value={values.company}
                            />
                        </Grid>

                        <Grid item xs={2}>
                        <Typography>Tyyppi</Typography>
                            <Select id="type" value={values.type} onChange={handleAccessChange('type')}>                        
                                <MenuItem value="0">Valitse</MenuItem>
                                <MenuItem value="1">Asiakkaat</MenuItem>
                                <MenuItem value="2">Prospektit</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>

                </form>
                <Divider />
                <Toolbar variant="dense">
                    <Grid container>
                        <Grid item xs={12} className={classes.root}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                size="small"
                                disableElevation
                                onClick={ () => handleReset() }
                            >
                                Tyhjennä
                            </Button>
                            <Button 
                                variant="contained" 
                                color="secondary" 
                                size="small" 
                                disableElevation
                                onClick={ () => handleSearch() }
                                >
                                Hae
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </Paper>
        </React.Fragment>
    );
};

export default RemindersConditions;
