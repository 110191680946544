import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useActions } from "../../actions";
import { addReminder } from "../../model/reminder";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
// import * as TodoActions from "../../actions/todo";

interface Props {
	open: boolean;
	onClose?: () => void;
	onSave?: (add: addReminder) => void;
	onDelete?: (id: number) => void;
	actReminder?: addReminder;
	noDialog?: Boolean;
}

const useStyles = makeStyles({
	textField: {
		width: "80%",
		margin: 20,
	},
});

export function ReminderDialog(props: Props) {
	const { open, onClose, onSave, onDelete, actReminder, noDialog } = props;
	const defaultReminder: addReminder = {};
	const defaultTime = getUnixTime(new Date()); //fromUnixTime( 0 ); //startOfTomorrow()
	const [reminder, setReminder] = React.useState<addReminder | undefined>(actReminder);

	/*if (actReminder?.time) {
		const timestamp = getUnixTime(new Date());
		console.log( timestamp );
        setReminder({ ...reminder, time: timestamp });
	}*/

	if ( 
		( actReminder && actReminder.id && !reminder ) ||
		( actReminder && actReminder.id && reminder && reminder.id !== actReminder.id )
	) {
		setReminder(actReminder);
		// console.log("setReminder: "+JSON.stringify(actReminder));
	}

	const handleClose = () => {
		if (onClose) onClose();
		setReminder({});
	};

	const handleChange = (prop: keyof addReminder) => (event: React.ChangeEvent<{ value: any }>) => {
        setReminder({ ...reminder, [prop]: event.target.value });
    };

	const handleDateChange = (date: Date | null) => {
        if ( date !== null ) {
            const timestamp = getUnixTime(date);
            setReminder({ ...reminder, time: timestamp });
        }
	};
	
	const dialogContent: JSX.Element = <React.Fragment>
		<TextField
			id="multiline-flexible"
			label="Aihe"
			fullWidth
			defaultValue={reminder?.subject}
			onChange={handleChange('subject')}
		/>
		<MuiPickersUtilsProvider 
			utils={DateFnsUtils}
		>
			<KeyboardDatePicker
				disableToolbar
				variant="inline"
				format="dd.MM.yyyy"
				// type="date-local"
				margin="normal"
				id="date-picker-inline"
				label="Päivämäärä"
				value={reminder?.time ? fromUnixTime( reminder.time ) : defaultTime}
				// value={fromUnixTime(reminder.time)}
				onChange={handleDateChange}
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
			/>
		</MuiPickersUtilsProvider>
		<TextField
			id="multiline-flexible"
			label="Kellonaika"
			fullWidth
			defaultValue={reminder?.klo}
			onChange={handleChange('klo')}
		/>
		<TextField
			id="multiline-flexible"
			label="Lisätietoja"
			fullWidth
			multiline
			rows={6}
			defaultValue={reminder?.text}
			onChange={handleChange('text')}
			// className={classes.textField}
		/>
	</React.Fragment>;

	const dialogActions: JSX.Element = <React.Fragment>
		<Grid container spacing={3} style={{padding: "1rem"}}>
			<Grid item xs={6}>
				{ onDelete && reminder?.id ?
					<Button 
						variant="contained"
						color="primary"
						size="small"
						onClick={() => onDelete(reminder.id ? reminder.id : 0)}
					>
						Poista
					</Button> : ''
				}
			</Grid>
			<Grid item xs={6} style={{textAlign: "right"}}>
				{ noDialog ? '' :
					<Button 
						variant="outlined"
						color="primary"
						size="small"
						onClick={handleClose}
					>
						Peruuta
					</Button>
				}
				{ onSave && reminder ?
					<Button 
						variant="contained"
						color="primary"
						size="small"
						onClick={() => onSave(reminder)}
						style={{marginLeft: "1rem"}}
					>
						Tallenna
					</Button> : ''
				}
			</Grid>
		</Grid>
	</React.Fragment>;

	if ( noDialog ) {
		if (open) {
			return (
				<Grid container>
					<Grid item xs={12}>
						{dialogContent}
					</Grid>
					<Grid item xs={12}>
						{dialogActions}
					</Grid>
				</Grid>
			);
		} else {
			return <React.Fragment></React.Fragment>;
		}
	}

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>{reminder?.client_name ? reminder.client_name : 'Muistutus'}</DialogTitle>
			<DialogContent>{dialogContent}</DialogContent>
			<DialogActions>{dialogActions}</DialogActions>
		</Dialog>
	);
}
