import DefaultTemplate from "../components/PageTemplates/DefaultTemplate";

import { Grid, Paper, Table, TableHead, TableBody, TableRow, TableCell, Button, TextField } from "@material-ui/core";

import * as React from "react";

import ApiConnection from "../actions/api/connect";

import { useSelector } from "react-redux";
import { RootState } from "../reducers/index";
import { BasicAlert, IBasicAlert } from '../components/Alerts/BasicAlert';
import { history } from "../configureStore";

import { Contracts } from "../model/contracts";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
	paper: {
        padding: 20,
	},
});

export function ContractsPage() {
	const classes = useStyles();

	const token =  useSelector((state: RootState) => state.user.token);

	const [contracts, setContracts] = React.useState<Contracts[]>([]);
	const [selectedContract, setSelectedContract] = React.useState<Contracts>();
	const [alert, setAlert] = React.useState<IBasicAlert>({});



	const apiConnect = new ApiConnection(token);

    function listContracts(){
		apiConnect.setValues({});
        apiConnect.connect( 'contracts/list' ).then(data => {
			setContracts( data );
        })
        .catch( error => {
        });
    }

    const handleChange = (prop: keyof Contracts) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedContract({ ...selectedContract, [prop]: event.target.value });
    };

	function handleSelect(contract: Contracts) {
		setAlert({});
		setSelectedContract(contract);
	}

	function handleSave() {
		if (selectedContract) {
			if (selectedContract.id) {
				apiConnect.setValues({
					id: selectedContract.id,
					subject: selectedContract.subject,
					text: selectedContract.text,
				});
			} else {
				apiConnect.setValues({
					subject: selectedContract.subject,
					text: selectedContract.text,
				});
			}
		}
        apiConnect.connect( 'contracts/add' ).then(data => {
			setSelectedContract(undefined);
			listContracts();
		})
        .catch( error => {
        });
	}

	if (contracts.length === 0) {
		listContracts();
	}

	if ( selectedContract) {

		return (
			<DefaultTemplate
				title={selectedContract.subject ? 'Muokkaa' : ''}
				content={
					<Grid container spacing={3}>
						{
							alert
								?
								<Grid item xs={12}>
									<BasicAlert
										type={alert.type}
										message={alert.message}
									/>
								</Grid>
								: ''
						}
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								id="subject"
								label="Otsikko"
								defaultValue={selectedContract.subject}
								onChange={handleChange('subject')}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
                                multiline
                                rows="20"
                                id="text"
								label="Teksti"
								defaultValue={selectedContract.text}
								onChange={handleChange('text')}
							/>
						</Grid>
						<Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            disableElevation
                            onClick={ () => handleSave() }
                        >
                            Tallenna
                        </Button>
						</Grid>
					</Grid>
				}
			/>
		);
	}


	return (
		<DefaultTemplate
			title="Sopimuspohjat"
			content={
				<Paper className={classes.paper} square={true}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell padding="default">Otsikko</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							contracts.map((contract: Contracts, key: number) => {
								return(
									<TableRow key={key} onClick={() => handleSelect(contract)}>
										<TableCell padding="default">{contract.subject}</TableCell>
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
			</Paper>
			}
		/>
	);
};
