import * as React from "react";

import ApiConnection from "../../../actions/api/connect";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/index";

import { Button, Grid, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Radio, RadioGroup, FormLabel, FormControlLabel } from "@material-ui/core";
import { Client, ClientHistories } from "../../../model/client";

function AddEvent({
    Cclient,
    afterSave,
}: {
    Cclient: Client,
    afterSave?: () => void,
}) {

    const token =  useSelector((state: RootState) => state.user.token);
    const apiConnect = new ApiConnection(token);

    const [edit, setEdit] = React.useState<Boolean>(false);
    const [option, setOption] = React.useState<string | undefined>(undefined);
    const [description, setDescription] = React.useState<string>('');

    const eventOptions = [
        'Vastattu puheluun',
        'Ei vastattu puheluun',
        'Palvelu ei kiinnosta',
        'Asiakas rahoitettu',
        'Karenssi 6kk (asiakasta ei saa kontaktoida)',
        'Maksuviive',
        'Käteisasiakas',
    ];
    
    const changeDesc = () => (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    };

    const optionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOption((event.target as HTMLInputElement).value);
    };

    function addNewEvent(){
        if ( option ) {
            let text = option;
            if ( description ) text += ': ' + description;
            apiConnect.setValues({
                client_id: Cclient.id,
                type_id: 18,
                text: text,
            });
            apiConnect.connect( 'Histories/add' ).then(response => {
                setEdit(false);
                setOption(undefined);
                setDescription('');
                if (afterSave) afterSave();
            })
            .catch( error => {
                console.log( 'ERROR: '+error);
            });
        }
    }
    /*
        <Select
                                fullWidth
                                defaultValue="0"
                                onChange={handleSelectChange()}
                                labelId="status-selector"
                                displayEmpty
                            >
                                <MenuItem value="0">Valitse tapahtuma</MenuItem>
                                {
                                    eventOptions.map((opt: string, key: number) => {
                                        return <MenuItem key={key} value={opt}>{opt}</MenuItem>
                                    })
                                }
                            </Select>
    */

    return (
        <Grid container style={{marginTop: '1rem'}}>
        { edit
            ? 
                <React.Fragment>
                    <Grid item xs={8}>
                        <Typography variant="h6">Lisää asiakastapahtuma</Typography>
                    </Grid>
                    <Grid item xs={4} style={{textAlign: 'right'}}>
                        <Button
                            variant="outlined" 
                            color="secondary" 
                            size="small" 
                            disableElevation
                            onClick={ () => setEdit(false) }
                        >
                            Peruuta
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={8}>
                            <FormControl style={{marginTop: '1rem'}} component="fieldset">
                                <FormLabel component="legend">Tapahtuma</FormLabel>
                                <RadioGroup aria-label="event-type" name="event-type" defaultValue={option} onChange={optionChange}>
                                    <Grid container>
                                    { eventOptions.map((opt: string, key: number) => (
                                        <FormControlLabel key={key} style={{width: '300px'}} value={opt} control={<Radio />} label={opt} />
                                    )) }
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows="3"
                                    id="description"
                                    label="Tapahtuman kuvaus"
                                    onChange={changeDesc()} 
                                    value={description}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'right'}}>
                        <Button
                            variant="contained" 
                            color="secondary" 
                            size="small" 
                            disableElevation
                            onClick={ () => addNewEvent() }
                        >
                            Tallenna
                        </Button>
                    </Grid>
                </React.Fragment>
            :
                <Grid item xs={12} style={{textAlign: 'right'}}>
                    <Button
                        variant="contained" 
                        color="secondary" 
                        size="small" 
                        disableElevation
                        onClick={ () => setEdit(true) }
                    >
                        Lisää uusi
                    </Button>
                </Grid>
        }
        </Grid>
    )

}

export default AddEvent;