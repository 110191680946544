import { ServiceAction, ServiceActions, Service } from "../model/service";

export function addServices(service: Service[]): ServiceAction {
	return {
		type: ServiceActions.ADD_SERVICES,
		payload: service,
	};
}

export function addService(service: Service): ServiceAction {
    console.log( service );
	return {
		type: ServiceActions.ADD_SERVICE,
		payload: service,
	};
}

export function reset() {
    return {
        type: ServiceActions.RESET,
    };
}