import DefaultTemplate from "../components/PageTemplates/DefaultTemplate";

import { Button, Grid, Paper, TextField, Select, MenuItem, Typography, Toolbar, Table, TableBody, TableCell, TableHead, TableRow, } from "@material-ui/core";

import React, { Component } from "react";

import ApiConnection from "../actions/api/connect";
import { AppConfig } from "../AppConfig";

import { User } from "../model/user"; //UserAction, UserActions,

import { useSelector } from "react-redux";
import { RootState } from "../reducers/index";

import { BasicAlert, IBasicAlert } from '../components/Alerts/BasicAlert';
import { getCount } from "../model/call"; //UserAction, UserActions,

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: 20,
        },
        toolbar: {
            '& > *': {
                margin: theme.spacing(1),
            },
            textAlign: 'right',
        },
    }),
);

export function SharenumbersPage() {
  const token =  useSelector((state: RootState) => state.user.token);
  const [alert, setAlert] = React.useState<IBasicAlert>({});
  const apiConnect = new ApiConnection(token);
  const classes = useStyles();
  const [userList, setUserList] = React.useState<UserWithProspects[]>([]);//<User[]>([]);
  const [userProspects, setUserPropects] = React.useState<UserProspects>({});
  const [prospectCount, setProspectCount] = React.useState<Number | undefined>(undefined);
  const [selFileName, setSelFileName] = React.useState<String | undefined>(undefined);
  const [fileToUpload, setFileToUpload] = React.useState<Blob | undefined>(undefined);
  const apiDomain = AppConfig.apiUrl;

  /*const [values, setValues] = React.useState<UserInfo>({
    user_id: "0",
    limit: "0",
    prospectnumbers: "0",
  });*/

  function getCount(){
		apiConnect.setValues({});
		apiConnect.connect( 'ProspectsAddUser/getCount' ).then( data => {
            setProspectCount(data);
            //setValues({ ...values, ['prospectnumbers']: data });
        })
        .catch( error => {
			console.log( 'ERROR: '+error);
        });
	}

  function getUsers(){
    apiConnect.setValues({});
        //
    apiConnect.connect( 'Prospects/listProspectByUser' ).then(response => {
      //apiConnect.connect( 'users/listUsers' ).then(response => {
        //console.log(response);
        setUserList(response);
        const newUserProspects: UserProspects = []
        response.map((user: UserWithProspects) => {
            newUserProspects[user.id] = 0;
            //console.log(newUserProspects);
        });
        setUserPropects(newUserProspects);
    })
    .catch( error => {
        console.log( 'ERROR: '+error);
          /*
          setAlert({
              type: 'warning',
              message: 'Salasanan vaihtaminen epäonnistui.',
          });
          */
    });
  }

    const handleUserProspectChange = (userId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(userId+': '+event.target.value);
        if( userId ) {
            setUserPropects({ ...userProspects, [userId]: Number(event.target.value) });
        }
        console.log( userProspects );
    };

    const addProspects = (userId: number) => {
        if ( userId > 0 && userProspects[userId] && userProspects[userId] > 0 ) {
            console.log(userId+': '+userProspects[userId]);
            apiConnect.setValues({
                user_id: userId,
                limit: userProspects[userId],
            });
            apiConnect.connect( 'ProspectsAddUser/add' ).then( response => {
                getCount();
                getUsers();
                setUserPropects({ ...userProspects, [userId]: 0 });
                setAlert({
                    type: 'info',
                    message: 'Prospektit jaettu käyttäjälle.',
                });
            })
        }
    }

    const handleFileChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const newFile = event.target.files[0];
            setFileToUpload(newFile);
            setSelFileName(newFile.name);
        }
    }

    const handleFileUpload = () => {
       if (fileToUpload) {
            var formData = new FormData();
            formData.append('file', fileToUpload);
            fetch(apiDomain+'prospects/addNewProspects', {
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Bearer '+token, 
                  }), 
                body: formData
            }).then(res => {
                if(res.ok) {
                    setFileToUpload(undefined);
                    setSelFileName(undefined);
                    getCount();
                }
            });
        }
    }

  if ( !prospectCount && prospectCount !== 0 ) {
    getUsers();
    getCount();
  }

  return (
		<DefaultTemplate
			title="Prospektien Hallinta"
			content={
                <Paper  className={classes.paper} square={true}>
                    <React.Fragment>
                        {
                            alert
                                ?
                                <Grid item xs={12}>
                                    <BasicAlert
                                        type={alert.type}
                                        message={alert.message}
                                    />
                                </Grid>
                                : ''
                        }
                    </React.Fragment>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <Typography style={{marginTop: "1rem"}} variant="h5">Jakamattomia prospekteja <strong>{prospectCount}</strong> kpl</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography style={{marginTop: "1rem"}} variant="h5">Lisää listalle uusia prospekteja tiedostosta</Typography>
                                    <Typography style={{marginTop: "0.5rem"}}>Formaattin tiedot:</Typography>
                                    <Typography style={{marginTop: "0.5rem"}}>CSV -tiedosto</Typography>
                                    <Typography style={{marginTop: "0.5rem"}}>Sarakkeet: Yhtiön nimi; Y-tunnus; osoite; postinumero; postitoimipaikka; yhteyshenkilö; yhteyshenkilön puhelinnumero </Typography>
                                    <div style={{marginTop: "1rem"}}>
                                        <input
                                            // accept="image/*"
                                            style={{display: 'none'}}
                                            id="uploadFile"
                                            type="file"
                                            onChange={handleFileChange()}
                                        />
                                        <label htmlFor="uploadFile">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                component="span"
                                                size="small" 
                                            >
                                                {selFileName ? 'Vaihda tiedosto' : 'Valitse tiedosto' }
                                            </Button>
                                        </label>
                                        <Typography
                                            variant="caption"
                                            style={{marginLeft: "1rem", marginTop: "0.5rem"}}
                                        >
                                            { selFileName ? selFileName : 'Ei valittua tiedostoa' }
                                        </Typography>
                                        <Button
                                            style={{marginLeft: "1rem"}}
                                            variant="contained"
                                            color="primary"
                                            component="span"
                                            size="small"
                                            disabled={ selFileName ? false : true}
                                            onClick={() => handleFileUpload()}
                                        >
                                            Lisää tiedosto
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                            <Typography style={{marginTop: "1.5rem"}} variant="h5">Jaa prospekteja käyttäjille</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nimi</TableCell>
                                        <TableCell>Prospekteja</TableCell>
                                        <TableCell>Jaa lisää prospekteja</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                { 
                                    userList.map((user: UserWithProspects, key: number) => {
                                        return(
                                            <TableRow key={key}>
                                                <TableCell>{user.name}</TableCell>
                                                <TableCell>{user.count}</TableCell>
                                                <TableCell>
                                                    <Grid container>
                                                        <Grid item xs={8}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                //id="limit"
                                                                label="Määrä"
                                                                onChange={handleUserProspectChange(user.id)} 
                                                                value={userProspects[user.id]}
                                                                defaultValue={0}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Button
                                                                style={{marginTop: "1rem", marginLeft: "1rem"}}
                                                                variant="contained"
                                                                color="secondary"
                                                                size="small"
                                                                // disableElevation
                                                                onClick={ () => addProspects(user.id) }
                                                            >
                                                                Jaa
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Paper>
			}
		/>
	);

};

interface UserWithProspects {
    name: string;
    count: number;
    id: number;
}

interface UserProspects {
    [id: number]: number;
    //id: number;
    //amount: number;
}

interface UserInfo{
    user_id: any;
    limit: string;
    prospectnumbers: any;
};
