export interface Criteria {
    [key: string]: string;
}

export interface SearchCriteria {
    name: string;
    criteria: Criteria[];
};

export enum SearchCriteriaActions {
    ADD_CRITERIA = "ADD_CRITERIA",
    RESET_CRITERIA = "RESET_CRITERIA",
}

interface SearchCriteriaActionType<T, P> {
    type: T;
    payload: P;
}

export type SearchCriteriaAction =
    | SearchCriteriaActionType<typeof SearchCriteriaActions.ADD_CRITERIA, SearchCriteria>
    | SearchCriteriaActionType<typeof SearchCriteriaActions.RESET_CRITERIA, string>
;