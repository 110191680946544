import * as React from "react";

import { Button, Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";

import { User } from "../../../model/user";
import { Client } from "../../../model/client";

import ApiConnection from "../../../actions/api/connect";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/index";

interface UserWithSelect extends User {
    selected?: Boolean;
}

function ClientManagers({
    Cclient,
}: {
    Cclient: Client
}) {
    const [userList, setUserList] = React.useState<UserWithSelect[] | undefined>(undefined);
    const [usersEdit, setUsersEdit] = React.useState<Boolean>(false);
    const [selectedUsers, setSelectedUsers] = React.useState<Number[] | undefined>(undefined);

    const token =  useSelector((state: RootState) => state.user.token);
    const apiConnect = new ApiConnection(token);

    const setSelectedUser = (id: number | undefined) => {
        if (id && userList) {
            const newUserList = userList.map(user => {
                if ( user.id === id ) {
                    if ( user.selected ) {
                        user.selected = false;
                    } else {
                        user.selected = true;
                    }
                }
                return user;
            })
            setUserList(newUserList);
        }
    }

    function mapAndSetSelectedUsers(listOfUsers: UserWithSelect[], ids: Number[]){
        const newUserList: UserWithSelect[] = listOfUsers.map(user => {
            if ( user.id && ids.includes(user.id) ) {
                user.selected = true;
            } else {
                user.selected = false;
            }
            return user;
        });
        setUserList(newUserList);
    }

    function resetUserList(){
        if (userList && selectedUsers) mapAndSetSelectedUsers(userList, selectedUsers);
    }

    function getClientUsers(listOfUsers: UserWithSelect[]){
        apiConnect.setValues({
            client_id: Cclient.id,
        });
        apiConnect.connect( 'clients/getClientUsers' ).then(response => {
            const ids: Number[] = response.map( (user: User) => {
                return user.id;
            });
            setSelectedUsers(ids);
            mapAndSetSelectedUsers(listOfUsers, ids);
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });        
    }

    function getUsers(){
        apiConnect.setValues({});
        apiConnect.connect( 'users/listUsers' ).then(response => {
            setUserList( response.data.users );
            getClientUsers( response.data.users );
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    function saveAccountManagers(){
        const selected: Number[] = [];
        if (userList) {
            userList.map(user => {
                if ( user.selected && user.id ) selected.push( Number(user.id) );
            });
            apiConnect.setValues({
                client_id: Cclient.id,
                user_id: selected,
            });
            apiConnect.connect( 'clients/saveClientUsers' ).then(response => {
                setSelectedUsers(selected);
                setUsersEdit(false);
            })
            .catch( error => {
                console.log( 'ERROR: '+error);
            });
        }
    }

    React.useEffect(() => {
        getUsers();
    }, [])

    return (
        <React.Fragment>
            <Typography variant={'h6'}>Asiakasvastaava(t)</Typography>
            <List dense>
                { usersEdit && userList
                    ? userList.map((user: UserWithSelect, key: number) => (
                        <ListItem style={{padding: '0', paddingLeft: '0.7rem'}} key={key}>
                            { usersEdit
                                ? <ListItemIcon style={{minWidth: '1.5rem'}}>
                                    <Checkbox
                                        edge="start"
                                        checked={user.selected && user.selected === true}
                                        onChange={() => setSelectedUser(user.id)}
                                        tabIndex={-1}
                                        disableRipple
                                        size="small"
                                        inputProps={{ 'aria-labelledby': 'user_'+user.id }}
                                    />
                                </ListItemIcon>
                                : ''
                            }
                            <ListItemText style={{padding: '0', margin: '0'}} id={'user_'+user.id} primary={user.name} />
                        </ListItem>
                    ))
                    : userList 
                        ? userList.filter(user => user.selected).map((user: User, key: number) => (
                            <ListItem style={{padding: '0', paddingLeft: '0.7rem'}} key={key}>
                                <ListItemText style={{padding: '0', margin: '0'}} primary={user.name} />
                            </ListItem>
                        ))
                        : ''
                }
            </List>
            <Grid container>
                <Grid item xs={6}>
                    <Button
                        style={{marginBottom: "0.5rem"}}
                        variant="outlined" 
                        color="secondary" 
                        size="small" 
                        disableElevation
                        onClick={ () => {
                            if (usersEdit) resetUserList();
                            setUsersEdit(!usersEdit);
                        }}
                        >
                        { usersEdit ? 'Peruuta' : 'Muokkaa' }
                    </Button>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                    { usersEdit
                        ? <Button
                            style={{marginLeft: "1rem", marginBottom: "0.5rem"}}
                            variant="contained" 
                            color="secondary" 
                            size="small" 
                            disableElevation
                            onClick={ () => saveAccountManagers() }
                            >
                            Tallenna
                        </Button>
                        : ''
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default ClientManagers;
