import { Button, Divider, Grid, Paper, Radio, Typography, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar } from "@material-ui/core";

import * as React from "react";

import { User } from "../../model/user";
import { Service } from "../../model/service";

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: 20,
        },
        toolbar: {
            '& > *': {
                margin: theme.spacing(1),
            },
            textAlign: 'right',
        },
    }),
);

export function UserEdit({
    user,
    serviceList,
    cancel,
    save,
}: {
    user: User;
    serviceList: Service[];
    cancel: React.Dispatch<undefined>;
    save: React.Dispatch<UserAccess>;
}) {
    const classes = useStyles();

    const access: AccessLevel[] = [
        {
            key: 0,
            value: 'Ei oikeuksia',
        },
        {
            key: 10,
            value: 'Luku',
        },
        {
            key: 50,
            value: 'Luku/Muokkaus',
        },
        {
            key: 100,
            value: 'Luku/Muokkaus/Poisto',
        },
    ];
    
    const serviceAccessList: ServiceAccess[] = [];

    serviceList.map((service: Service) => {

        let val: number = 0;

        const userAccessLevel = user.service_access?.filter(
            userAccess => userAccess.id === service.id
        );
        
        if ( userAccessLevel && userAccessLevel.length > 0 ){
            console.log( 'userAccessLevel: ' + userAccessLevel[0].id + '=>' +userAccessLevel[0].value );
            val = userAccessLevel[0].value;
        }

        serviceAccessList.push({
            id: service.id,
            value: val,
        });

    });

    console.log( user.service_access  );

    const [serviceAccess, setServiceAccess] = React.useState<ServiceAccess[]>(serviceAccessList);

    const [values, setValues] = React.useState<PasswordInfo>({
        password: '',
        password_repeat: '',
    });

    const [userInfo, setUserValues] = React.useState<User>({
        id: user?.id,
        name: user?.name,
        username: user?.username,
    });

    function handleAccessChange(key: number, value: number){
        const theChange: ServiceAccess[]  = Object.assign([], serviceAccess);
        theChange[key].value = value;
        setServiceAccess(theChange);
    }

    const handlePasswordChange = (prop: keyof PasswordInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleUserChange = (prop: keyof User) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserValues({ ...userInfo, [prop]: event.target.value });
    };

    function handleCancel(){
        cancel(undefined);
    }

    function handleSave(){
        if ( user.id ) {
            save({
                user: userInfo,
                access: serviceAccess,
            });
        } else {
            save({
                user: userInfo,
                access: serviceAccess,
                password: values,
            });
        }
        
    }

    return (
        <Paper square={true}>
            <Grid container spacing={3} className={classes.paper}>
                <Grid item xs={12}>
                   <Typography variant="h6">
                        Käyttäjän tiedot
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        fullWidth
                        id="name"
                        label="Nimi"
                        defaultValue={userInfo.name}
                        onChange={handleUserChange('name')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        fullWidth
                        id="username"
                        label="Sähköposti"
                        defaultValue={userInfo.username}
                        onChange={handleUserChange('username')}
                    />
                </Grid>
                {
                    !user.id ? (
                        <React.Fragment>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="password"
                                    label="Salasana"
                                    type="password"
                                    defaultValue={values.password}
                                    onChange={handlePasswordChange('password')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="password_repeat"
                                    label="Salasana"
                                    type="password"
                                    defaultValue={values.password_repeat}
                                    onChange={handlePasswordChange('password_repeat')}
                                />
                            </Grid>
                        </React.Fragment>
                    )
                    : ''
                }
            </Grid>
            <Divider />
            <Grid container spacing={3} className={classes.paper}>
                <Grid item xs={12}>
                   <Typography variant="h6">
                        Käyttöoikeudet
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="default">Palvelu</TableCell>
                                {
                                    access.map((level: AccessLevel, key: number) => {
                                        return(
                                            <TableCell key={key} padding="default">{level.value}</TableCell>
                                        );
                                    })
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                serviceList.map((service: Service, key: number) => {
                                    const userAccessLevel = serviceAccess.filter(
                                        userAccess => userAccess.id === service.id
                                    );
                                    return(
                                        <TableRow key={key}>
                                            <TableCell padding="default">{service.name}</TableCell>
                                            {
                                                access.map((level: AccessLevel, cellKey: number) => {
                                                    return(
                                                        <TableCell key={cellKey} padding="default">
                                                            <Radio
                                                                checked={userAccessLevel[0].value === level.key}
                                                                onChange={() => handleAccessChange(key, level.key)}
                                                                value={level.key}
                                                                name={service.url}
                                                            />
                                                        </TableCell>
                                                    );
                                                })
                                            }
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <Divider />
            <Toolbar variant="dense">
                <Grid container>
                    <Grid item xs={12} className={classes.toolbar} >
                        <Button 
                            variant="outlined"
                            color="secondary"
                            size="small"
                            disableElevation
                            onClick={ () => handleCancel() }
                        >
                            Peruuta
                        </Button>
                        <Button 
                            variant="contained" 
                            color="secondary"
                            size="small"
                            disableElevation
                            onClick={ () => handleSave() }
                        >
                            Tallenna
                        </Button>
                    </Grid>
                </Grid>
            </Toolbar>
        </Paper>
    ); 
}

interface AccessLevel {
    key: number;
    value: string;
}

export interface ServiceAccess {
    id: number;
    value: number;
}

interface PasswordInfo{
    password: string;
    password_repeat: string;
};

export interface UserAccess{
    user: User;
    access: ServiceAccess[];
    password?: PasswordInfo;
}