
import { Divider, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import * as React from "react";
import { history } from "../../configureStore";
import { MenuItem } from "../Route/MenuItems";

import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

import { useSelector } from "react-redux";
import { RootState } from "../../reducers/index";
import { Service } from "../../model/service";

const useStyles = makeStyles((theme: Theme) => ({
    nestedList: {
        marginLeft: theme.spacing(5),
    },
    nested: {
        padding: theme.spacing(0),
        paddingLeft: theme.spacing(4),

    },
}));

export default function HeaderMenuItem( {item}:IMenuItem ){
    const classes = useStyles();
    const serviceList: Service[] = useSelector((state: RootState) => state.serviceList);
    return(
        <React.Fragment>
            <Divider />
            <List>
                <ListItem button onClick={() => history.push(item.path)} /*selected={true}*/ >
                    {
                        item.icon
                            ? <ListItemIcon>
                                {
                                    item.icon 
                                }
                            </ListItemIcon>
                            : ''
                    }
                    <ListItemText primary={item.title}/>
                </ListItem>
                {
                    item.sub ? (
                        <List className={classes.nestedList}>
                            {
                                item.sub.map((subItem: MenuItem, subKey: number) => {
                                    if ( subItem.id ) {
                                        const checker = serviceList.filter( ( service ) => service.id === subItem.id );
                                        if ( checker.length === 0 ) {
                                            return '';
                                        }
                                    }
                                    return(
                                        <ListItem key={subKey} button className={classes.nested} onClick={() => history.push(item.path+subItem.path)}>
                                            <ListItemText primary={subItem.title} />
                                        </ListItem>
                                    );
                                })
                            }
                        </List>
                    )   
                    : ''
                }
            </List>
        </React.Fragment>
    );
}

interface IMenuItem {
    item: MenuItem;
}