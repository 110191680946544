import * as React from "react";
import { Typography, Grid, Button, TextField } from "@material-ui/core";

import { columnItem } from "../interfaces";

export default function FileSelect(
    props: {
        column: columnItem;
        colVal: string;
        selFileName: string;
        titleCol?: columnItem;
        onNameChange?: (id: string) => void;
        onFileChange?: (prop: keyof any, file: any) => void;
    }
): JSX.Element {

    const { column, colVal, selFileName, titleCol, onNameChange, onFileChange } = props;

    const handleFileChange = (prop: keyof any) => (event: React.ChangeEvent<HTMLInputElement>) => {
        //console.log("handleFileChange");
        //console.log( event.target.files );
        if (event.target.files && event.target.files[0]) {
            const newFile = event.target.files[0];
            if (onFileChange) onFileChange(prop, newFile);
            //setEditItem({ ...editItem, [prop]: newFile});
            //setSelFileName(newFile.name);

            /*
            const el: HTMLElement | null = document.getElementById('title');
            const val: string = 'defaultValue';
            if (el?.hasAttribute(val) && !el.getAttribute(val)) {
                el.setAttribute(val, newFile.name);
                console.log('change: '+newFile.name);
            } else {
                console.log('file: '+newFile.name);
            }*/
            //onFileChange
        }
    };
    
    if (onNameChange && onFileChange) {
        const fileInput: JSX.Element = <React.Fragment>
            <input
                // accept="image/*"
                // className={classes.fileInput}
                style={{display: 'none'}}
                id={column.id}
                //label={column.title}
                //defaultValue={selFileName.toString()}
                // onChange={this.handleCapture}
                type="file"
                onChange={handleFileChange(column.id)}
            />
            <label htmlFor={column.id}>
                <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    size="small" 
                >
                    Valitse tiedosto
                </Button>
            </label>
            <Typography
                variant="caption"
                style={{marginLeft: "1rem"}}
            >
                { selFileName ? selFileName : column.typeSettings?.selectedFileName }
            </Typography>
        </React.Fragment>;
        //console.log('File: '+selFileName);
        // console.log(getDefaultFileName()); // .__defaultFileName ? editItem.__defaultFileName : 'tyhjä'
        
        if (column.typeSettings?.fileNameId){
            /*let defValue: string = '';
            if ( row[column.typeSettings.fileNameId] ) {
                defValue = row[column.typeSettings.fileNameId];
            } else if ( selFileName ) {
                console.log( '-> ' + selFileName );
                defValue = selFileName;
            }*/
            /*const titleCol = columns.filter(col => {
                if( col.id && col.id === column.typeSettings?.fileNameId) return true;
                return false;
            });*/

            //console.log('::: '+titleCol[0].title);
            return <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        id={column.typeSettings.fileNameId}
                        label={titleCol?.title ? titleCol.title : 'Tiedoston nimi'}
                        onChange={() => onNameChange(String(column.typeSettings?.fileNameId))}
                        // value={editItem[column.typeSettings.fileNameId]}
                        // value={selFileName}
                        defaultValue={colVal}
                    />
                </Grid>
                <Grid item xs={6} style={{marginTop: "1rem"}}>
                    {fileInput}
                </Grid>
            </Grid>
        }
        return fileInput;
    }
    return <React.Fragment></React.Fragment>;
    
};