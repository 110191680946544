import * as React from "react";
import { history } from "../configureStore";
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from "@material-ui/core";
import { useActions } from "../actions";
import * as UserActions from "../actions/user";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/index";
import ApiConnection from "../actions/api/connect";
//import configureStore from "../configureStore";
//minutesLeft

interface ITokenTimer {
    //expTime: number;
   path?: string;
}

const TokenTimer = ({path}:ITokenTimer) => {

    const reminderMinutes = 5;
    const delay = 60000;
    const userActions = useActions(UserActions);
    const token =  useSelector((state: RootState) => state.user.token);

    const [minutes, setMinutes] = React.useState<undefined | number>(undefined);
    
    function resetTimer() {
        console.log('ResetTimer');
        const api = new ApiConnection();
        const d = new Date();
        const exp_time = d.getTime() + 30 * 600;
        api.setToken( token ); 
        api.connect(
            'users/get'
        ).then(response => {

            const userData = response.data.user;
            userActions.login({
                username: userData.username,
                name: userData.name,
                id: userData.id,
                logged: true,
                token: userData.token,
                role: userData.role,
                token_expires: exp_time,
            });
            setMinutes(30);
        });
    }

    function minutesSetter() {
        
        if (minutes && minutes > 0) {
            setMinutes(minutes-1);
        }
        
    }

    if (minutes === undefined) {
        resetTimer();
    } else if (minutes === 0) {
        history.push({
            pathname: '/logout',
        });
    }

    React.useEffect(() => {
        if ( minutes && minutes > 0 ) {
            const interval = setInterval(() => {
                minutesSetter();
            }, delay);
            return() => {
                clearInterval(interval);
            }
        }
    },[delay, minutes]);
    
    return <Dialog open={minutes && minutes < reminderMinutes ? true : false}>
        <DialogTitle>Automaattinen uloskirjaus</DialogTitle>
        <DialogContent>Sinut ollaan kirjaamassa ulos palvelusta automaattisesti hetken kuluttua. Mikäli olet haluat vielä pysyä sisäänkirjautuneena, klikkaa "Pysy kirjautuneena".</DialogContent>
        <DialogActions>
            <Button color="primary" variant="contained" onClick={() => resetTimer()}>
                Pysy kirjautuneena
            </Button>
        </DialogActions>
    </Dialog>;
}

export default TokenTimer;
