
import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Button } from "@material-ui/core";
import fromUnixTime from 'date-fns/fromUnixTime';
import dateFormat from 'date-fns/format';
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { addReminder, Reminders } from "../../model/reminder"; 
import { history } from "../../configureStore";

function RemindersTable({
	reminders,
	onSelect,
}: {
	reminders: Reminders[],
	onSelect: (selRem: addReminder) => void,
}) {

	const classes = useStyles();

	function goToData(reminder: Reminders) {
		if (reminder && reminder.prospect_id != undefined && reminder.prospect_id != 0) {
			history.push({
				pathname: '/call',
				state: {prospect_id: reminder.prospect_id},
			});
		} else {
			history.push({
				pathname: '/client',
				state: {client_id: reminder.client_id},
			});
		}
	}

	function handleSelect(reminder: Reminders) {
		if (reminder) {
			onSelect({
				id: reminder.id,
				client_id: reminder.client?.id,
				client_name: reminder.client?.company,
				time: reminder.time,
				klo: reminder.klo,
				subject: reminder.subject,
				text: reminder.text,
			});
		}
	}
	
	return (
		<Paper className={classes.paper}>
			<Table className={classes.table}>
				<TableHead>
					<TableRow>
						<TableCell padding="default">Tyyppi</TableCell>
						<TableCell padding="default">Pvm</TableCell>
						<TableCell padding="default">Klo</TableCell>
						<TableCell padding="default">Asiakas</TableCell>
						<TableCell padding="default">Aihe</TableCell>
						<TableCell padding="default">Lisätiedot</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{reminders.map((reminder: Reminders, key: number) => {
						return (
							<TableRow key={key} onClick={() => handleSelect(reminder)}>
								<TableCell>{reminder.type}</TableCell>
								<TableCell>{reminder.time ? dateFormat(fromUnixTime(reminder.time),"dd.MM.yyyy") : ''}</TableCell>
								<TableCell>{reminder.klo}</TableCell>
								<TableCell>{reminder.client.company}</TableCell>
								<TableCell>{reminder.subject}</TableCell>
								<TableCell>{reminder.text}</TableCell>
								<TableCell>
								<Button 
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        disableElevation
                                        onClick={ () => goToData(reminder) }
                                    >
                                        Tietoihin
                                    </Button>

								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</Paper>
	);
}

const useStyles = makeStyles({
	paper: {
		width: "100%",
		minWidth: 260,
		display: "inline-block",
	},
	table: {
		width: "100%",
	},
});

export default RemindersTable;
