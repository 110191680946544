import * as React from "react";
import Alert from '@material-ui/lab/Alert';

export const BasicAlert = ( { type, message }: IBasicAlert ) => {
    if ( message ) {
        return (
            <Alert severity={type}>
                {message}
            </Alert>
        );
    }
    return <span />;
}

export interface IBasicAlert {
    type?: "success" | "warning" | "info";
    message?: string;
};