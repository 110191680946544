import DefaultTemplate from "../components/PageTemplates/DefaultTemplate";

import {CalendarEvent, CalendarEventProto, CalendarStatusOption} from "../model/calendar";

import { timeMonday } from "d3-time";
import { User } from "../model/user"; 

import { history } from "../configureStore";
//import WeekView, {Calendar} from "../components/Calendar/WeekView"
import {Calendar} from "../components/Calendar";

import {FormControl, Select, MenuItem} from "@material-ui/core";

import * as React from "react";

import { useSelector } from "react-redux";
import { RootState } from "../reducers/index";

import ApiConnection from "../actions/api/connect";

function calEventLoader(token: string | undefined, start: number, end: number, userId?: number){
	console.log('load events: ' + start + ' - ' + end);
	// let url = 'calendars/listOwn';
	let promise;
	if (start && end ) {
		if (userId) {
			console.log('WithUserId: '+userId);
			//url = 'calendars/list';
			const apiConnect = new ApiConnection(token);
			apiConnect.setValues({
				starttime: start,
				endtime: end,
				user_id: userId,
			});
			promise = new Promise((resolve, reject) => {
				apiConnect.connect('calendars/list').then( response => {
					resolve(response);
				});
			});
		} else {
			const apiConnect = new ApiConnection(token);
			apiConnect.setValues({
				starttime: start,
				endtime: end,
			});
			promise = new Promise((resolve, reject) => {
				apiConnect.connect('calendars/listOwn').then( response => {
					resolve(response);
				});
			});
		}
	}

	return promise;
}

function calEventSave(token: string | undefined, event: CalendarEventProto){
	const apiConnect = new ApiConnection(token);
	// event.
	apiConnect.setValues(event);
	let promise = new Promise((resolve, reject) => {
		apiConnect.connect('calendars/add').then( response => {
			resolve(response);
		});
	});

	return promise;
}

function calEventDelete(token: string | undefined, id: number){
	const apiConnect = new ApiConnection(token);
	apiConnect.setValues({
		id: id,
	});
	let promise = new Promise((resolve, reject) => {
		apiConnect.connect('calendars/delete').then( response => {
			resolve(response);
		});
	});

	return promise;
}

interface Props {
	prospectId?: number;
	clientId?: number;
	afterSaveAction?: () => void;
}

export function CalendarPage(props: Props) {
	const { prospectId, clientId, afterSaveAction } = props;
	const token: string | undefined =  useSelector((state: RootState) => state.user.token);
	const userId = useSelector((state: RootState) => state.user.id);
	const cal = new Calendar();
	const defaultStartDay: any = undefined;
	const defaultEvent: CalendarEvent | CalendarEventProto | undefined = history && history.location && history.location.state && history.location.state.event ? history.location.state.event : undefined;
	const defaultEvents: any = undefined;

	const [startDay, setStart] = React.useState(defaultStartDay);
	const [event, setEvent] = React.useState<CalendarEvent | CalendarEventProto | undefined>(defaultEvent);
	const [events, setEvents] = React.useState(defaultEvents);
	const [client, setClient] = React.useState<string | undefined>(undefined);
	const [userList, setUserList] = React.useState<User[]>([]);
	const [selUser, setUser] = React.useState<number | undefined>(undefined);
	const [statusOptions, setStatusOptions] = React.useState<CalendarStatusOption[]>([]);

	const handleClose = () => {
		if (defaultEvent) {
			history.push({
				pathname: '/calendar',
				state: {},
			});
		}
		setEvent(undefined);
		setClient(undefined);
	};

	const openEvent = ( event: CalendarEventProto | CalendarEvent ): void => {
		if ( typeof( event === "CalendarEvent" ) ){
			if (event.client_id) {
				// console.log('Client_id: '+event.client_id);
				const apiConnect = new ApiConnection(token);
				apiConnect.setValues({
					id: event.client_id,
				});
				apiConnect.connect( 'clients/get' ).then(response => {
					if (response.company) {
						setClient(response.company);
					}
					setEvent(event);
				})
				.catch( error => {
					console.log( 'ERROR: '+error);
				});
			} else {
				setEvent(event);
			}
		} else {

		}
	}

	async function getEvents(date?: Date, uId?: number): Promise<any>{
		//if (!startDay) setStart();
		let start = Number( cal.timestamp(startDay) );
		let end = Number( cal.timestamp( cal.getWeekEnd( startDay ) ) );
		if (date && date !== undefined) {
			// console.log( date.toLocaleDateString() );
			start = Number( cal.timestamp(date) );
			end = Number( cal.timestamp( cal.getWeekEnd( date ) ) );
		} else {
			// console.log( startDay.toLocaleDateString() );
		}
		console.log( 'selUser ' + selUser );

		if ( uId && uId > 0 ) {
			return calEventLoader(token, start, end, uId);
		} else if ( selUser && selUser > 0 ) {
			return calEventLoader(token, start, end, selUser);
		} else {
			return calEventLoader(token, start, end);
		}
	}

	const loadEvents = (date?: Date, uId?: number): void => {
		let call = getEvents(date,uId);
		call.then( result => {
			console.log(result);
			if (event && events) handleClose();
            setEvents(result);
        });
	}

	async function callSaveEvent(calEvent: CalendarEventProto): Promise<any>{
		//let uId = userId;
		if ( !calEvent.id && prospectId ) {
			calEvent.prospect_id = prospectId;
		} else if ( !calEvent.id && clientId ) {
			calEvent.client_id = clientId;
		}

		if ( !calEvent.user_id ) {
			if ( selUser ) {
				calEvent.user_id = selUser;
			} else {
				calEvent.user_id = userId;
			}
		}
		return calEventSave(token, calEvent);
	}
	const saveEvent = (calEvent: CalendarEventProto): void => {
		let call = callSaveEvent(calEvent);
		call.then( result => {
			console.log(result);
			if (afterSaveAction) {
				console.log('Save event');
				afterSaveAction();
			} else {
				loadEvents();
			}
        });
	}

	async function callDeleteEvent(id: number): Promise<any>{
		return calEventDelete(token, id);
	}
	const deleteEvent = (id: number): void => {
		let call = callDeleteEvent(id);
		call.then( result => {
            console.log(result);
            loadEvents();
        });
	}

	const changeWeek = (date: Date): void => {
		console.log( date.toLocaleDateString() );
		setStart(date);
		loadEvents(date);
	}

	if ( events === undefined ) {
		loadEvents( timeMonday(new Date()) );
	}

	function getStatusOptions(){
		const apiConnect = new ApiConnection(token);
		apiConnect.setValues({});
        apiConnect.connect( 'calendarStatus/list' ).then(response => {
			
			console.log('calendarStatus');
			console.log( response );
			setStatusOptions( response );
			//setUserList( response.data.users );

        })
        .catch( error => {
			console.log( 'ERROR: '+error);
        });
	}
	
	if ( statusOptions.length === 0 ) {
		getStatusOptions();
	}

	function getUsers(){
		const apiConnect = new ApiConnection(token);
		apiConnect.setValues({});
        apiConnect.connect( 'users/listUsers' ).then(response => {
			
			console.log( 'USERS: ' + response.data.users );
			setUserList( response.data.users );

        })
        .catch( error => {
			console.log( 'ERROR: '+error);
        });
    }

    if ( userList.length === 0 ) {
        getUsers();
	}

	const changeUser = () => (event: React.ChangeEvent<{ value: any }>) => {
		const newId: number = Number(event.target.value);
		setUser(newId);
		loadEvents(startDay, newId);
	}

	const userSelect: JSX.Element = (
		<FormControl>
			<Select
				value={selUser || userId}
				onChange={changeUser()}
				displayEmpty
			>
				{
					userList.map((user: User, key: number) => {
						return <MenuItem key={key} value={user.id}>{user.name}</MenuItem>
					})
				}
			</Select>
		</FormControl>
	);
	
	const weekEventsLoaded: boolean = false; 

	return (
		<DefaultTemplate
			title={prospectId || clientId ? undefined : "Kalenteri"}
			dialog={cal.showDialog({
				onClose: handleClose,
				event: event,
				save: saveEvent,
				onDelete: deleteEvent,
				statusOptions: statusOptions,
				client: client,
			})}
			content={cal.showWeek({
				openEvent: openEvent,
				start: startDay,
				setStart: changeWeek,
				events: events ? events : [],
				userSelect: userSelect,
				statusOptions: statusOptions,
			})}
		/>
	);
}