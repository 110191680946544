import DefaultTemplate from "../components/PageTemplates/DefaultTemplate";

import * as React from "react";

import { Divider, FormControl, InputLabel, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, Grid, TextField, Button, Select, MenuItem, TextareaAutosize } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/index";
import { FundingCompanyContact, FundingCompany } from "../model/fundingcompany"; 
import { history } from "../configureStore";
import { AppConfig } from "../AppConfig";

import ApiConnection from "../actions/api/connect";

import { BasicAlert, IBasicAlert } from '../components/Alerts/BasicAlert';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { TableWithEditor } from '../components/TableWithEditor';

const useStyles = makeStyles({
	paper: {
        padding: 20,
        paddingTop: 15,
	},
	table: {
		width: "100%",
	},
});

export function FundingContactsPage() {


    const classes = useStyles();

    const funding_company_id = history && history.location && history.location.state && history.location.state.funding_company_id ? history.location.state.funding_company_id : undefined;

    const apiDomain = AppConfig.apiUrl;
    const token =  useSelector((state: RootState) => state.user.token);
    const apiConnect = new ApiConnection(token);

    const [Contacts, setContacts] = React.useState<FundingCompanyContact[]>([]);
    const [FundingCompany, setFundingCompany] = React.useState<FundingCompany>({});

    const [info, setInfo] = React.useState<Info>({
        showContactList: false,
    });
    
    function getFundingCompany() {
        apiConnect.setValues({
			id: funding_company_id,
        });
        apiConnect.connect( 'fundingCompanies/get' ).then(response => {
            console.log(response);
            setFundingCompany( response );
        })
        .catch( error => {
        console.log( 'ERROR: '+error);
        });
    }

    function listContact() {
        apiConnect.setValues({
			funding_company_id: funding_company_id,
        });
        apiConnect.connect( 'FundingCompanyContacts/list' ).then(response => {
            setContacts( response );
        })
        .catch( error => {
        console.log( 'ERROR: '+error);
        });
    }

    function saveContact( contact: FundingCompanyContact ) {
        if (contact.id) {
            apiConnect.setValues({
                id: contact.id,
                funding_company_id: funding_company_id,
                name: contact.name || '',
                title: contact.title || '',
                phone: contact.phone || '',
                email: contact.email || '',
            });
        } else {
            apiConnect.setValues({
                funding_company_id: funding_company_id,
                name: contact.name || '',
                title: contact.title || '',
                phone: contact.phone || '',
                email: contact.email || '',
            });
        }

        apiConnect.connect( 'FundingCompanyContacts/add' ).then(response => {
            console.log(response);
            listContact();
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    function deleteContact( id: number) {
        apiConnect.setValues({
			funding_company_id: funding_company_id,
			id: id,
        });

        apiConnect.connect( 'FundingCompanyContacts/delete' ).then(response => {
            listContact();   
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    function setback() {
        history.push({
            pathname: '/settings/fundingcompanies'
          });
    }

    if (info.showContactList == false) {
        setInfo({...info, 'showContactList': true});
        listContact();
    }
    if (!FundingCompany.id) {
        getFundingCompany();
    }
    const emptyContact = {
        name: '',
        title: '',
        phone: '',
        email: '',
    };

/*
    
*/
    if (info.showContactList == true && FundingCompany.id) {
        return (
			<DefaultTemplate
				title={FundingCompany.name +' yhteyshenkilöt'}
				content={
                    <Paper className={classes.paper} square={true}>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            disableElevation
                            onClick={ () => setback() }
                        >
                            Takaisin listaan
                        </Button>
                    </Grid>
                    <TableWithEditor
                    title=""
                    noEdit="false"
                    columns={[
                        {
                            title: 'ID',
                            id: 'id',
                            hide: true,
                        },
                        {
                            title: 'Nimi',
                            id: 'name',
                            edit: true,
                        },
                        {
                            title: 'Titteli',
                            id: 'title',
                            edit: true,
                        },
                        {
                            title: 'Puhelin',
                            id: 'phone',
                            edit: true,
                        },
                        {
                            title: 'Sähköposti',
                            id: 'email',
                            edit: true,
                        },
                    ]}
                    itemData={Contacts}
                    emptyItem={emptyContact}
                    onSave={saveContact}
                    onDelete={deleteContact}
                />
            </Paper>
			}
            />
        );
    } else {
        return (
            <Paper className={classes.paper} square={true}>
                <Typography variant="h6">
                    Haetaan tietoja
                </Typography>
            </Paper>
        );
    }
};


interface Info{
    showContactList: any;
};
