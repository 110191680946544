
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Button, Divider, FormControl, Grid, Input, InputLabel, OutlinedInput, Paper, Toolbar, Typography } from "@material-ui/core";

import { BasicAlert, IBasicAlert } from '../../components/Alerts/BasicAlert';

import ApiConnection from "../../actions/api/connect";

import { useSelector } from "react-redux";
import { RootState } from "../../reducers/index";

import * as React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
            textAlign: 'right',
        },

        paper: {
            padding: 20,
        },

        footer: {
            backgroundColor: '#fff', // theme.palette.grey["300"],
        }
    }),
);

function ChangePassword() {

    const classes = useStyles();

    const [alert, setAlert] = React.useState<IBasicAlert>({});

    const [values, setValues] = React.useState<PasswordInfo>({
        password: '',
        password_repeat: '',
    });

    const token =  useSelector((state: RootState) => state.user.token);

    const handleChange = (prop: keyof PasswordInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handlePasswordChange = () => () => {
        console.log( 'User: '+ token);

        if ( values.password.length < 8 ) {
            setAlert({
                type: 'warning',
                message: 'Salasanan tulee olla vähintään kahdeksan (8) merkin mittainen.',
            });
        } else if ( values.password !== values.password_repeat ) {
            setAlert({
                type: 'warning',
                message: 'Salasanat eivät ole yhdenmukaiset.',
            });
        } else {
            setAlert({
                type: 'info',
                message: 'Vaihdetaan salasanaa...',
            });

            const pwConnect = new ApiConnection(token);
            pwConnect.setValues( values );
            pwConnect.connect(
                'users/changePassword'
            ).then(response => {
                console.log('response: '+JSON.stringify(response));
                
                setAlert({
                    type: 'success',
                    message: 'Salasana vaihdettu',
                });
    
            })
            .catch( error => {
    
                setAlert({
                    type: 'warning',
                    message: 'Salasanan vaihtaminen epäonnistui.',
                });
    
            });

        }

    };

    return (
        <React.Fragment>
            <AppBar position="static" color="secondary">
                <Toolbar variant="dense">
                    <Typography variant="h6">
                        Vaihda salasana
                    </Typography>
                </Toolbar>
            </AppBar>
            <Paper square={true}>
                <form noValidate autoComplete="off" className={classes.paper}>
                    <Grid container spacing={3}>
                        {
                            alert
                            ? <Grid item xs={12}>
                                <BasicAlert 
                                    type={alert.type}
                                    message={alert.message}
                                />
                            </Grid>
                            : ''
                        }
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="component-outlined">Salasana</InputLabel>
                                <Input 
                                    id="password" 
                                    value={values.password} 
                                    type="password"
                                    onChange={handleChange('password')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="component-outlined">Salasana uudelleen</InputLabel>
                                <Input 
                                    id="password_repeat" 
                                    value={values.password_repeat} 
                                    type="password"
                                    onChange={handleChange('password_repeat')}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
                <Divider />
                <Toolbar variant="dense">
                    <Grid container>
                        <Grid item xs={12} className={classes.root}>
                            <Button variant="contained"  color="secondary" size="small" disableElevation onClick={handlePasswordChange()}>
                                Vaihda
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </Paper>
        </React.Fragment>
    );
};

export default ChangePassword;

interface PasswordInfo{
    password: string;
    password_repeat: string;
};
