import DefaultTemplate from "../components/PageTemplates/DefaultTemplate";

import ProspectPanel from "../components/Prospect/ProspectPanel";

import { CalendarPage } from "../pages/CalendarPage";

import * as React from "react";

import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, Button, Card, CardHeader, CardContent, Grid, Divider, Paper, Typography } from "@material-ui/core";

import ApiConnection from "../actions/api/connect";

import { useSelector } from "react-redux";
import { RootState } from "../reducers/index";
import { BasicAlert, IBasicAlert } from '../components/Alerts/BasicAlert';

import { Reminders, addReminder } from "../model/reminder";
import { ReminderDialog } from "../components/Reminders/ReminderDialog";

import 'date-fns';
import getUnixTime from 'date-fns/getUnixTime';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import { history } from "../configureStore";

import { Prospect, ProspectData, Histories } from "../model/call";

const useStyles = makeStyles({
	paper: {
        padding: 20,
	},
});

export function CallPage() {

	const token =  useSelector((state: RootState) => state.user.token);
	const [pros, setPros] = React.useState<Prospect>({});
	const [prosData, setProsData] = React.useState<ProspectData>({});
	const [alert, setAlert] = React.useState<IBasicAlert>({});
	const [calOpen, setCalOpen] = React.useState<Boolean>(false);
	const [hist, setHist] = React.useState<Histories[]>([]);
	const [started, setStarted] = React.useState(true);
	const [materialType, setmaterialType] = React.useState<Number>(0);

	const [prospectId, setprospectId] = React.useState<Number>(0);
	
	//let prospectId: undefined | number = undefined;

	const defaultReminder: any = undefined;
	const [reminder, setReminder] = React.useState(defaultReminder);

    const prospect_id = history && history.location && history.location.state && history.location.state.prospect_id ? history.location.state.prospect_id : undefined;

	const apiConnect = new ApiConnection(token);

	React.useEffect(() => {
		const interval = setInterval(() => {
			checkProspect();
		}, 3000);
		return() => {
			clearInterval(interval);
		}
	},[started,prospectId]);

	function getProspect(first: any, type: any){
		if (type == undefined) {
			type = materialType;
		}
		console.log("getProspect");
		console.log("first "+first);
		console.log("prospectId "+prospectId);
		if (first == 1 && prospect_id && prospect_id != undefined) {
			console.log("yksi");
			apiConnect.setValues({id: prospect_id, type: type});
			history.location.state.prospect_id = undefined;
		} else if (first > 2) {
			console.log("kaksi");
			apiConnect.setValues({id: first, type: type});
		} else {
			console.log("kolme");
			apiConnect.setValues({type: type});
		}
        apiConnect.connect( 'prospects/get' ).then(data => {
			if ( data && data.id ) {
				console.log("tänne");
				setprospectId(data.prospect_id);
				setPros( data );
				setProsData( data.prospect );
				console.log("prospectId kaksi "+prospectId);

				apiConnect.setValues({
					prospect_id: data.prospect_id,
				});
				apiConnect.connect( 'Histories/list' ).then(data => {
					console.log(data);
					setHist( data );
				})
				.catch( error => {
				});
		
			} else if( pros.id ) {
				setPros({});
				setProsData({});
				setprospectId(0);
			}
			history.location.state.prospect_id = undefined;
        })
        .catch( error => {
        });
    }

	function checkProspect() {
		apiConnect.setValues({});
        apiConnect.connect( 'prospects/checkProspectId' ).then(data => {
			console.log("checkProspect");
			console.log("prospectId "+prospectId);
			console.log("data "+data);

			if ( data ) {
				if ( prospectId !== data ) {
					getProspect(2,materialType);
				}
			}
        })
        .catch( error => {
        });
	}

    function setSelect(pros: Prospect){
		getProspect(pros.prospect_id, materialType);
	}

	function addReminder() {
        const selRem: addReminder = {
            time: getUnixTime(startOfTomorrow()),
        };
        setReminder(selRem);
	}
	
	function closeReminder() {
		setReminder(defaultReminder);
	}

	function openCalendar() {
		setCalOpen(true);
	}

	function closeCalendar() {
		setCalOpen(false);
	}

	function afterCaledarSave() {
		setCalOpen(false);
		getProspect(2, materialType);
	}
	function setmaterialType0() {
		setmaterialType(0);
        getProspect(2,0);
	}
	function setmaterialType1() {
		setmaterialType(1);
        getProspect(2,1);
	}
    if ( !pros.id ) {
		console.log("VÄLITETTY ID "+prospect_id);
		if (prospect_id != undefined) {
			getProspect(prospect_id, materialType);
		} else {
			getProspect(1, materialType);
		}
	}

    function saveReminder(selRem: addReminder){
        if ( !selRem.time ) selRem.time = 0;
		selRem.prospect_id = prosData.id;
		apiConnect.setValues(selRem);
		apiConnect.connect('Reminders/add').then(data => {
			console.log("Kutsutaan get prospect");
			getProspect(2, materialType);
			console.log("Suljetaan");
			closeReminder();
		})
		.catch( error => {
		});

    }

	let dialog: JSX.Element = <React.Fragment></React.Fragment>;

	if ( reminder ) {
		dialog = (
			<ReminderDialog
				open={reminder ? true : false}
				onSave={saveReminder}
				onClose={closeReminder}
				actReminder={reminder ? reminder : undefined}
			/>
		);
	}

	let content: JSX.Element = (
		<Grid item xs={12}>
			<BasicAlert 
				type="info"
				message="Ei soitettavia prospekteja"
			/>
		</Grid>
	);

	if (pros && pros.id) {
		if (calOpen) {
			content = (
				<React.Fragment>
					<AppBar position="static" color="secondary">
						<Toolbar variant="dense">
							<Grid container>
								<Grid item xs={9}>
									<Typography variant="h6">
										Ajanvaraus: {prosData.company} ({prosData.vatnumber})
									</Typography>
								</Grid>
								<Grid item xs={3} style={{textAlign: "right"}}> 
									<Button
										variant="outlined"
										size="small"
										color="inherit"
										onClick={() => closeCalendar()}
									>
										Peruuta
									</Button>
								</Grid>
							</Grid>
						</Toolbar>
					</AppBar>
					<Paper square={true}>
						<CalendarPage
							prospectId={pros.prospect_id}
							afterSaveAction={afterCaledarSave}
						/>
					</Paper>
				</React.Fragment>
			);
			//<React.Fragment>CalOpen</React.Fragment>;
		} else {
			content = (
				<ProspectPanel 
					pros={pros}
					prosData={prosData}
					setProsData={setProsData}
					getProspect={getProspect}
					select={setSelect}
					openReminder={addReminder}
					openCalendar={openCalendar}
					hist={hist}
					materialType={materialType}
					setmaterialType0={setmaterialType0}
					setmaterialType1={setmaterialType1}
				/>
			);
		}
	}

	const classes = useStyles();
	return (
		<DefaultTemplate
			title="Soittokone"
			dialog={ dialog }
			content={ content }
		/>
	);
}

interface Info{
    prospect_id: number;
};

