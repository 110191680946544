import * as React from "react";

import { ClientHistories } from "../../../model/client";

import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Grid } from "@material-ui/core";

import fromUnixTime from 'date-fns/fromUnixTime';
import dateFormat from 'date-fns/format';
//, TextareaAutosize, TextField, Button

function EventHistory({
    history,
    shortlist,
}: {
    history: ClientHistories[],
    shortlist?: Boolean, 
}) {

    return (
        <Grid container spacing={shortlist ? 0 : 3} style={shortlist ? {} : {marginTop: "2rem"}}>
            <Grid item xs={12}>
                <Typography variant="h6">
                    { shortlist ? 'Viimeisimmät tapahtumat' : 'Tapahtumahistoria' }
                </Typography>
                <Table size={ shortlist ? 'small' : 'medium'}>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="default">Aika</TableCell>
                            <TableCell padding="default">Tapahtuma</TableCell>
                            <TableCell padding="default">Lisääjä</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            history.map((history: ClientHistories, key: number) => {
                                return(
                                    <TableRow key={key}>
                                        <TableCell padding="default">{history.time ? dateFormat(fromUnixTime(history.time),"dd.MM.yyyy HH:mm") : ''}</TableCell>
                                        <TableCell padding="default">{history.text}</TableCell>
                                        <TableCell padding="default">{history.user && history.user.name ? history.user.name : ''}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
};

export default EventHistory; 
