import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/index";
import { Client } from "../../model/client"; 

import { history } from "../../configureStore";

import ApiConnection from "../../actions/api/connect";

import fromUnixTime from 'date-fns/fromUnixTime';
import dateFormat from 'date-fns/format';

import { BasicAlert, IBasicAlert } from '../../components/Alerts/BasicAlert';

interface ClientWithEvent extends Client {
    history_time: number ;
    history_text: String;
}

const useStyles = makeStyles({
	paper: {
        padding: 20,
        paddingTop: 15,
	},
	table: {
		width: "100%",
	},
});

function HomeLeed({
    Ctype,
}: {
    Ctype: Number,
}) {
    const classes = useStyles();

    const token =  useSelector((state: RootState) => state.user.token);
    const apiConnect = new ApiConnection(token);

	const [clients, setClient] = React.useState<ClientWithEvent[]>([]);
	const [alert, setAlert] = React.useState<IBasicAlert>({});
    const [info, setInfo] = React.useState<Info>({
        showClients: false,
      });

    function selectClient(id: number) {
        if (id > 0) {
            history.push({
                pathname: '/client',
                state: {client_id: id},
            });
        }
    }
    
    function listClient(){

        apiConnect.setValues({
            dealflow_status_id: Ctype,
            frontpage: 1,
        });
        apiConnect.connect( 'clients/list' ).then(data => {
            setClient( data );
        })
        .catch( error => {
        });
    }

    if (info.showClients == false) {
        listClient();
        setInfo({...info, 'showClients': true});
    }    

	return (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="default">Asiakas</TableCell>
                            <TableCell padding="default" colSpan={2}>Viimeisin tapahtuma</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        clients.map((client: ClientWithEvent, key: number) => {
                            return(
                                <TableRow key={key} onClick={() => selectClient(client.id ? client.id : 0)}>
                                    <TableCell padding="default">{client.company}</TableCell>
                                    <TableCell padding="default">{dateFormat(fromUnixTime(client.history_time),"dd.MM.yyyy")}</TableCell>
                                    <TableCell padding="default">{client.history_text}</TableCell>
                                </TableRow>
                            )
                        })
                    }
                    </TableBody>
                </Table>
	);

}

export default HomeLeed;

interface Info{
    showClients: any;
};
