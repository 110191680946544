import * as React from "react";

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import { Drawer, AppBar, Toolbar, List, ListItem, ListItemText, ListSubheader, Button, Grid, Divider, Paper, Typography, TextField, Table, TableBody, TableCell, TableHead, TableRow, Select, MenuItem } from "@material-ui/core";

import CssBaseline from '@material-ui/core/CssBaseline';
import { Prospect, ProspectData, ProspectList, Histories } from "../../model/call";
import { addReminder } from "../../model/reminder";
import ApiConnection from "../../actions/api/connect";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/index";
import { history } from "../../configureStore";

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
  

import 'date-fns';
import dateFormat from 'date-fns/format';
import DateFnsUtils from '@date-io/date-fns';
import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';
import startOfTomorrow from 'date-fns/startOfTomorrow';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
        padding: 20,
    },
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
  }),
);

function ProspectPanel({
    pros,
    prosData,
    setProsData,
    getProspect,
    select,
    openReminder,
    openCalendar,
    hist,
    materialType,
    setmaterialType0,
    setmaterialType1
}: {
    pros: Prospect;
    prosData: ProspectData;
    setProsData: React.Dispatch<ProspectData>;
    getProspect: (first: any, type: any) => void;
    select: React.Dispatch<Prospect>;
    openReminder?: () => void;
    openCalendar?: () => void;
    hist: Histories[];
    materialType: Number;
    setmaterialType0: () => void;
    setmaterialType1: () => void;
}) {

    const token =  useSelector((state: RootState) => state.user.token);
	const [prosListData, setProsListData] = React.useState<ProspectList[]>([]);
	const [addReminderCall, setaddReminderCall] = React.useState<addReminder>({});
    const defaultTime = getUnixTime(new Date());



	function getReminder() {
		apiConnect.setValues({prospect_id: pros.prospect_id});
        apiConnect.connect( 'Reminders/listProspect' ).then(data => {
	
			if ( data.id ) {
                setaddReminderCall(data);
			}
        })
        .catch( error => {
        });
	}

    
	const apiConnect = new ApiConnection(token);

    const [values, setValues] = React.useState<Search>({
        searchText: '',
        showList: 0,
        gethistory: 0,
      });

    const handleTypeChange = (prop: any) => (event: React.ChangeEvent<{ value: any }>) => {
        if (event.target.value == 0) {
            setmaterialType0();
        } else {
            setmaterialType1();
        }
        console.log("Muutos "+materialType);
    }; 
      

    function handleSave(status_id: number) {
		apiConnect.setValues({
            id: pros.prospect_id,
            status_id: status_id,
        });
        apiConnect.connect( 'prospects/saveStatus' ).then(data => {
			getProspect(2, materialType);
        })
        .catch( error => {
        });
    }

    function handleNoteSave() {
		apiConnect.setValues({
            id: pros.prospect_id,
            note: prosData.note,
        });
        apiConnect.connect( 'prospects/saveNote' ).then(data => {
            setValues({ ...values, ["gethistory"]: 0 });
        })
        .catch( error => {
        });
    }

    const handleNoteChange = (prop: keyof ProspectData) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setProsData({ ...prosData, [prop]: event.target.value });
    };


    function handleRememberSave() {
        setValues({searchText: '', showList: 2, gethistory: 0});
        //setValues({ ...values, ["showList"]: 2 });
    }

    function handleClientSave() {
		apiConnect.setValues({
            id: pros.prospect_id,
        });
        apiConnect.connect( 'prospects/convertClient' ).then(data => {
            history.push({
                pathname: '/client',
                state: {client_id: data},
              });
        })
        .catch( error => {
        });
    }

    const handleChange = (prop: keyof Search) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    
    function handleSearch() {
		apiConnect.setValues({
            type: materialType,
            searchtext: values.searchText,
        });
        apiConnect.connect( 'Prospects/search' ).then(response => {
			setProsListData( response );
            setValues({ ...values, ["showList"]: 1 });
        })
        .catch( error => {
        });
    }

    const classes = useStyles();

    function handleSelect(pros2: Prospect){
        select( pros2 );
        setValues({searchText: '', showList: 0, gethistory: 0});
    }

    function handleSaveReminder() {
        if (addReminderCall) {
            apiConnect.setValues({
                prospect_id: prosData.id,
                time: addReminderCall.time || '0',
                text: addReminderCall.text || '',
                subject: addReminderCall.subject || '',
                klo: addReminderCall.klo || '',
            });
        }
        apiConnect.connect( 'Reminders/add' ).then(response => {
            setaddReminderCall({});
			history.push({
				pathname: '/reminders',
			});
        })
        .catch( error => {
        console.log( 'ERROR: '+error);
        });
    }
   
    function handleCall() {
        if (prosData) {
            apiConnect.setValues({
                phone: prosData.phone,
            });

            apiConnect.connect( 'Clients/callphone' ).then(response => {
            })
            .catch( error => {
            console.log( 'ERROR: '+error);
            });
        }
    }

    const handleReminderChange = (prop: keyof addReminder) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setaddReminderCall({ ...addReminderCall, [prop]: event.target.value });
    };

    const handleSelectReminderChange = (prop: keyof addReminder) => (event: React.ChangeEvent<{ value: any }>) => {
        setaddReminderCall({ ...addReminderCall, [prop]: event.target.value });
    };

	const handleDateChange = (date: Date | null) => {
        if ( date !== null ) {
            const timestamp = getUnixTime(date);
            setaddReminderCall({ ...addReminderCall, time: timestamp });
        }
	};


    React.useEffect(() => {
        getReminder();
    }, [])

    if (values.showList == 1) {
        return (
            <Paper square={true}>
            <Table>
				<TableHead>
					<TableRow>
						<TableCell padding="default">Yritys</TableCell>
						<TableCell padding="default">Yhteyshenkilö</TableCell>
						<TableCell padding="default">Puhelinnumero</TableCell>
						<TableCell padding="default"></TableCell>
					</TableRow>
				</TableHead>
                <TableBody>
                    {
                        prosListData.map((pros: ProspectList, key: number) => {
                                return(
                                <TableRow key={key} onClick={() => handleSelect(pros)}>
                                    <TableCell padding="default">{pros.prospect.company}</TableCell>
                                    <TableCell padding="default">{pros.prospect.name}</TableCell>
                                    <TableCell padding="default">{pros.prospect.phone}</TableCell>
                                    <TableCell padding="default"></TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </Paper>
        );
    } else if (values.showList == 2) {
        return (
            <Paper square={true}>
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <Typography variant="h6">
                    {prosData.company}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="subject"
                        label="Päivämäärä"
                        onChange={handleReminderChange('time')} 
                        value={addReminderCall.time}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="text"
                        label="Viesti"
                        multiline
                        rows="4"
                        onChange={handleSelectReminderChange('text')} 
                        value={addReminderCall.text}
                    />
                </Grid>
                <Grid item xs={12} style={{textAlign: "right"}}>
                    <Button
                        style={{marginTop: "10px"}}
                        variant="contained" 
                        color="secondary" 
                        size="small" 
                        disableElevation
                        onClick={ () => handleSaveReminder() }
                        >
                        Tallenna muistutus
                    </Button>
                </Grid>

            </Grid>
        </Paper>
    );
} else if (prosData.company) {
        return (
            <div>
                <AppBar position="static" color="secondary">
                    <Toolbar variant="dense">
                        <Typography variant="h6">
                            {prosData.company} ({prosData.vatnumber})
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Paper className={classes.paper} square={true}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={8} lg={9}>
                            <Typography variant="h5">
                                {prosData.name}
                            </Typography>
                            <Typography variant="h4">
                                {prosData.phone}
                            </Typography>
                            <Typography paragraph variant="h5">    
                                {prosData.address}, {prosData.zipcode} {prosData.city}
                            </Typography>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                disableElevation
                                onClick={ () => handleCall() }
                            >
                                SOITA
                            </Button>

                            <Grid container spacing={1}>
                                <Grid item xs={8}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows="10"
                                        id="note"
                                        label="Muistiinpano"
                                        onChange={handleNoteChange('note')} 
                                        value={prosData.note || ''}
                                    />
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        disableElevation
                                        onClick={ () => handleNoteSave() }
                                    >
                                        Tallenna
                                    </Button>
                                </Grid>
                                <Grid item xs={4}>
                                { addReminderCall.id ?
                                    <div>
                                    <Typography>Muistutus</Typography>

                                    <TextField
                                        id="multiline-flexible"
                                        label="Aihe"
                                        fullWidth
                                        value={addReminderCall.subject || ''}
                                        onChange={handleReminderChange('subject')}
                                    />
                                    <MuiPickersUtilsProvider 
                                        utils={DateFnsUtils}
                                    >
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="dd.MM.yyyy"
                                            // type="date-local"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label="Päivämäärä"
                                            value={addReminderCall?.time ? fromUnixTime( addReminderCall.time ) : defaultTime}
                                            // value={fromUnixTime(reminder.time)}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <TextField
                                        id="multiline-flexible"
                                        label="Kellonaika"
                                        fullWidth
                                        value={addReminderCall.klo || ''}
                                        onChange={handleReminderChange('klo')}
                                    />
                                    <TextField
                                        id="multiline-flexible"
                                        label="Lisätietoja"
                                        fullWidth
                                        multiline
                                        rows={6}
                                        defaultValue={addReminderCall.text || ''}
                                        onChange={handleReminderChange('text')}
                                    />
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        disableElevation
                                        onClick={ () => handleSaveReminder() }
                                    >
                                        Tallenna uusi muistutus ja palaa muistutuksiin
                                    </Button>
                                    </div> : ''
                                    }
                                    <Typography>Tapahtumahistoria</Typography>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell padding="default">Pvm</TableCell>
                                                <TableCell padding="default">Tapahtuma</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                hist.map((history: Histories, key: number) => {
                                                        return(
                                                        <TableRow key={key}>
                                                            <TableCell padding="default">{history.time ? dateFormat(fromUnixTime(history.time),"dd.MM.yyyy HH:mm") : ''}</TableCell>
                                                            <TableCell padding="default">{history.text}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </Grid>
                        </Grid>

                        </Grid>
                        <Grid item xs={12} md={4} lg={3} style={{borderLeft: "1px solid"}}>

                        <List
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                subheader={
                                    <ListSubheader component="div" id="nested-list-subheader">
                                        Valitse käsiteltävä materiaali
                                    </ListSubheader>
                                }
                            >
                                <Divider />
                                <ListItem>

                                    <Select
                                    fullWidth
                                    id="materialType"
                                    defaultValue={materialType}
                                    onChange={handleTypeChange('materialType')}
                                    >
                                    <MenuItem value={0}>Kylmät prospektit</MenuItem>
                                    <MenuItem value={1}>Esiselvitettävät</MenuItem>
                                        
                                    </Select>


                                </ListItem>

                        </List>


                        <List
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                subheader={
                                    <ListSubheader component="div" id="nested-list-subheader">
                                        Hae prospekteista
                                    </ListSubheader>
                                }
                            >
                                <Divider />
                                <ListItem>
                                <TextField
                                    required
                                    fullWidth
                                    id="searchText"
                                    label="Haettava teksti"
                                    onChange={handleChange('searchText')} 
                                    value={values.searchText}
                                />
                                    
                                </ListItem>
                                <ListItem>
                                    <Button 
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        disableElevation
                                        onClick={ () => handleSearch() }
                                    >
                                        Hae!
                                    </Button>
                                </ListItem>

                        </List>
                            <List
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                subheader={
                                    <ListSubheader component="div" id="nested-list-subheader">
                                        Tila
                                    </ListSubheader>
                                }
                            >
                                <Divider />
                                <ListItem>
                                    <Button 
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        disableElevation
                                        onClick={ () => handleSave(2) }
                                    >
                                        Ei vastannut
                                    </Button>
                                </ListItem>
                                <ListItem>
                                    <Button 
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        disableElevation
                                        onClick={ () => handleSave(3) }
                                    >
                                        Ei kiinnostunut
                                    </Button>
                                </ListItem>
                                <ListItem>
                                    <Button 
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        disableElevation
                                        onClick={ () => handleSave(4) }
                                    >
                                        Soittokielto
                                    </Button>
                                </ListItem>
                                <ListItem>
                                    <Button 
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        disableElevation
                                        onClick={ () => handleSave(7) }
                                    >
                                        Roskakori
                                    </Button>
                                </ListItem>

                            </List>
                            <List
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                subheader={
                                    <ListSubheader component="div" id="nested-list-subheader">
                                        Toiminnot
                                    </ListSubheader>
                                }
                            >
                                <Divider />
                                <ListItem>
                                    <Button 
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        disableElevation
                                        onClick={ () => handleSave(8) }
                                    >
                                        Siirrä esiselvitykseen
                                    </Button>
                                </ListItem>

                                { openReminder ? 
                                    <ListItem>
                                        <Button 
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            disableElevation
                                            onClick={ () => openReminder() }
                                            //onClick={ () => handleRememberSave() }
                                        >
                                            Lisää muistutus
                                        </Button>
                                    </ListItem>
                                    : ''
                                }
                                { openCalendar ? 
                                    <ListItem>
                                        <Button 
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            disableElevation
                                            onClick={ () => openCalendar() }
                                        >
                                            Varaa aika
                                        </Button>
                                    </ListItem>
                                    : ''
                                }
                                <ListItem>
                                    <Button 
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        disableElevation
                                        onClick={ () => handleClientSave() }
                                    >
                                        Siirrä asiakkaaksi
                                    </Button>
                                </ListItem>


                            </List>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    } else {
        return (
            <div>
            </div>
        );
    }
}

export default ProspectPanel;

interface Search{
    searchText: any;
    showList: any;
    gethistory: any;
};
