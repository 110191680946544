import { ServiceAction, ServiceActions, Service } from "../model/service";
import createReducer from "./createReducer";

const INITIAL_STATE: Service[] = [];

export const serviceList = createReducer<Service[]>(
    INITIAL_STATE,
    {
		[ServiceActions.ADD_SERVICES](state: Service[], action: ServiceAction) {
			return [...state, action.payload];
        },
        [ServiceActions.ADD_SERVICE](state: Service[], action: ServiceAction) {
			return [...state, action.payload];
        },
        [ServiceActions.RESET](state: Service[]) {
			state = INITIAL_STATE;
			return state;
        },
    }
);