import { History } from "history";
import { combineReducers } from "redux";
import { Todo } from "../model";
import { User } from "../model/user";
import { Service } from "../model/service";
import { SearchCriteria } from "../model/searchCriteria";
import * as todoReducer from "./todo";
import * as userReducer from "./user";
import * as serviceReducer from "./service";
import * as searchCriteriaReducer from "./searchCriteria";

export interface RootState {
	user: User;
	serviceList: Service[];
	todoList: Todo[];
	searchCriteriaList: SearchCriteria[];
}

/*
const appReducer = combineReducers({
	...todoReducer,
	...userReducer,
	...serviceReducer,
});
*/
type Action = {
	type: 'USER_LOGGED_OUT',
};

const rootReducer = (history: History, action?: Action) => {
	// when a logout action is dispatched it will reset redux state
	/*if ( action && action.type === 'USER_LOGGED_OUT') {
		console.log( action.type );

	}*/
  
	return combineReducers({
		...todoReducer,
		...userReducer,
		...serviceReducer,
		...searchCriteriaReducer,
	});
};

export default rootReducer;
/*=> (
	
	combineReducers({
		...todoReducer,
		...userReducer,
	})
);*/
