import DefaultTemplate from "../components/PageTemplates/DefaultTemplate";

import SearchConditions from "../components/Search/SearchConditions";
import SearchResultList from "../components/Search/SearchResultList";

import { Grid } from "@material-ui/core";

import * as React from "react";

import ApiConnection from "../actions/api/connect";

import { useSelector } from "react-redux";
import { RootState } from "../reducers/index";
import { BasicAlert, IBasicAlert } from '../components/Alerts/BasicAlert';

import { Client, ClientSearch } from "../model/client";

export function SearchPage() {

	const token =  useSelector((state: RootState) => state.user.token);
	const [client, setClient] = React.useState<Client[]>([]);
	const [search, setSearch] = React.useState<ClientSearch>();
	const [alert, setAlert] = React.useState<IBasicAlert>({});



	const apiConnect = new ApiConnection(token);

    function listClient(search: ClientSearch){
		
		apiConnect.setValues({
			company_name: search.company_name,
			name: search.name,
			vatnumber: search.vatnumber,
			phone: search.phone,
			user_id: search.user_id,
			dealflow_status_id: search.dealflow_status_id
		});
        apiConnect.connect( 'clients/list' ).then(data => {
			setClient( data );
        })
        .catch( error => {
        });
    }


	return (
		<DefaultTemplate
			title="Asiakashaku"
			content={
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<SearchConditions 
						listC={listClient}
						/>
					</Grid>
					<Grid item xs={12}>
						<SearchResultList 
						clients={client}
						/>
					</Grid>
				</Grid>
			}
		/>
	);
};
