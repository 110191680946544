import DefaultTemplate from "../components/PageTemplates/DefaultTemplate";

import { Grid, Paper, Table, TableHead, TableBody, TableRow, TableCell, Button, TextField,Typography,Checkbox, Select, MenuItem } from "@material-ui/core";

import * as React from "react";

import ApiConnection from "../actions/api/connect";

import { useSelector } from "react-redux";
import { RootState } from "../reducers/index";
import { BasicAlert, IBasicAlert } from '../components/Alerts/BasicAlert';
import { Client, OfferEmail, ClientFiles } from "../model/client";
import { FundingCompany, FundingCompanyContact } from "../model/fundingcompany";
import { history } from "../configureStore";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
	paper: {
        padding: 20,
	},
});

export function ClientOfferEmailPage() {
	const classes = useStyles();

	const token =  useSelector((state: RootState) => state.user.token);
    const client_id = history && history.location && history.location.state && history.location.state.client_id ? history.location.state.client_id : undefined;

	const [client, setClient] = React.useState<Client>({});
	const [offerEmail, setOfferEmail] = React.useState<OfferEmail>({});
	const [ClientFiles, setClientFiles] = React.useState<ClientFiles[]>([]);
	const [companies, setCompanies] = React.useState<FundingCompany[]>([]);
    const [Contacts, setContacts] = React.useState<FundingCompanyContact[]>([]);
	const [alert, setAlert] = React.useState<IBasicAlert>({});
    const [info, setInfo] = React.useState<Info>({
        funding_company_id: 0,
    });

    const apiConnect = new ApiConnection(token);

    const selectedFiles: number[] = [];
    const selectedContacts: number[] = [];

    const handleChange = (prop: keyof OfferEmail) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setOfferEmail({ ...offerEmail, [prop]: event.target.value });
    };

    const handleCompanyChange = (prop: keyof Info) => (event: React.ChangeEvent<{ value: any }>) => {
        setInfo({ ...info, [prop]: event.target.value });
        getFundingContacts(event.target.value);
    };

    function getFundingContacts(id: number) {
        apiConnect.setValues({
			funding_company_id: id,
        });
        apiConnect.connect( 'FundingCompanyContacts/list' ).then(response => {
            setContacts( response );
        })
        .catch( error => {
        console.log( 'ERROR: '+error);
        });
    }

	function handleSave() {
        if (offerEmail && offerEmail.fromname && offerEmail.fromemail && offerEmail.subject && offerEmail.text) {

            apiConnect.setValues({
                client_id: client.id || 0,
                fromname: offerEmail.fromname || '',
                fromemail: offerEmail.fromemail || '',
                subject: offerEmail.subject || '',
                text: offerEmail.text || '',
                files: selectedFiles,
                contacts: selectedContacts,
            });
            apiConnect.connect( 'clientFundingOffers/sendFundingOffer' ).then(data => {
                history.push({
                    pathname: '/client',
                    state: {client_id: client.id},
                });
            })
            .catch( error => {
                console.log(error);
            });

        }
    }

    function getClient(client_id: number) {
        apiConnect.setValues({
            id: client_id,
        });
        apiConnect.connect( 'clients/get' ).then(response => {
            setClient( response );

        })
        .catch( error => {
        console.log( 'ERROR: '+error);
        });
    }

    function listFiles(client_id: number){
		apiConnect.setValues({
			client_id: client_id,
		});
        apiConnect.connect( 'clientAttachments/list' ).then(data => {
			setClientFiles( data );
        })
        .catch( error => {
        });
    }

    function listCompanies(){
		apiConnect.setValues({});
        apiConnect.connect( 'fundingCompanies/list' ).then(data => {
			console.log(data);
			setCompanies( data );
        })
        .catch( error => {
        });
    }

    const handleContactCheckbox = (id: number | undefined) => (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.checked && !selectedContacts.some(x => x === id)) {
            if(id) selectedContacts.push(id);
        } else if(selectedContacts.some(x => x === id) && id) {
            const index = selectedContacts.indexOf(id);
            selectedContacts.splice(index, 1);
        }
    }

    const handleFileCheckbox = (id: number | undefined) => (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.checked && !selectedFiles.some(x => x === id)) {
            if(id) selectedFiles.push(id);
        } else if(selectedFiles.some(x => x === id) && id) {
            const index = selectedFiles.indexOf(id);
            selectedFiles.splice(index, 1);
        }
    }

    if (!client.id) {
        getClient(client_id);
        listFiles(client_id);
        listCompanies();
    }

    return (
        <DefaultTemplate
            title=''
            content={
                <Paper square={true} style={{marginTop: "20px"}}>
                    <Grid container spacing={3} className={classes.paper}>
                    <Grid item xs={12}>
                        <Typography variant="h4">Lähetä tarjouspyyntö ({client.company})</Typography>
                    </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="fromname"
                                label="Lähettäjän nimi"
                                onChange={handleChange('fromname')} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="fromemail"
                                label="Lähettäjän sähköpostiosoite"
                                onChange={handleChange('fromemail')} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="subject"
                                label="Otsikko"
                                onChange={handleChange('subject')} 
                            />
                        </Grid>                 
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows="10"
                                id="text"
                                label="Viesti"
                                onChange={handleChange('text')} 
                            />
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '2rem'}}>
                            <Typography variant="h4">Valitse vastaanottaja</Typography>
                            <Grid item xs={6} style={{marginTop: '1rem'}}>
                            {
                                companies.length > 0 ? (
                                <Select
                                    fullWidth
                                    id="funding_company_id"
                                    defaultValue={info.funding_company_id}
                                    onChange={handleCompanyChange('funding_company_id')}
                                >                        
                                    <MenuItem value="0">Valitse</MenuItem>
                                    {
                                        companies.map((company: FundingCompany, key: number) => {
                                            return(
                                                <MenuItem key={key} value={company.id}>{company.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>) : ''
                            }
                            </Grid>
                            <Grid item xs={12} style={{marginTop: '2rem'}}>
                            {
                                Contacts.length > 0 ? (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="default"></TableCell>
                                            <TableCell padding="default">Yhteyshenkilö</TableCell>
                                            <TableCell padding="default">Sähköpostiosoite</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            Contacts.map((contact: FundingCompanyContact, key: number) => {
                                                return(
                                                    <TableRow key={key}>
                                                        <TableCell padding="default">
                                                            <Checkbox
                                                                value={contact.id}
                                                                inputProps={{ 'aria-label': 'disabled checked checkbox' }}
                                                                onChange={handleContactCheckbox(contact.id)}
                                                            />
                                                        </TableCell>
                                                        <TableCell padding="default">{contact.name}</TableCell>
                                                        <TableCell padding="default">{contact.email}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                                ) : ''
                            }

                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4">Valitse lähetettävät liitteet</Typography>

                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="default"></TableCell>
                                        <TableCell padding="default">Liitteen nimi</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        ClientFiles.map((file: ClientFiles, key: number) => {
                                            return(
                                                <TableRow key={key}>
                                                    <TableCell padding="default">
                                                        <Checkbox
                                                            value={file.id}
                                                            inputProps={{ 'aria-label': 'disabled checked checkbox' }}
                                                            onChange={handleFileCheckbox(file.id)}
                                                            /*onChange={handleFileChange('fileid')}*/
                                                        />
                                                    </TableCell>
                                                    <TableCell padding="default">{file.title}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={12} style={{textAlign: "right", marginTop: '2rem'}}>
                            <Button
                                variant="contained"
                                color="secondary"
                                //size="small"
                                disableElevation
                                onClick={ () => handleSave() }
                            >
                                Lähetä rahoituspyyntö
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            }
        />
    );

};
interface Info{
    funding_company_id: any;
};
