import * as React from "react";
import DefaultTemplate from "../components/PageTemplates/DefaultTemplate";

import { BasicAlert, IBasicAlert } from '../components/Alerts/BasicAlert';

import { Client } from "../model/client";
import { history } from "../configureStore";

import Informations from "../components/Client/Informations";
import NotesAndFiles from "../components/Client/NotesAndFiles";
import OffersAndAccepts from "../components/Client/OffersAndAccepts";
import EmailsAndHistories from "../components/Client/EmailsAndHistories";
import ExtraInfo from "../components/Client/ExtraInfo";

import { CalendarPage } from "../pages/CalendarPage";

import ApiConnection from "../actions/api/connect";

import { useSelector } from "react-redux";
import { RootState } from "../reducers/index";

import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar , Button, Typography, AppBar, Tabs, Tab, Grid  } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
	paper: {
        padding: 20,
	},
});

export function ClientPage() {
	const classes = useStyles();

    const token =  useSelector((state: RootState) => state.user.token);
    const apiConnect = new ApiConnection(token);

    const [value, setValue] = React.useState('perus');
	const [client, setClient] = React.useState<Client>({});

    const client_id = history && history.location && history.location.state && history.location.state.client_id ? history.location.state.client_id : undefined;
    function handleChange(newValue: any) {
        if ( newValue ) {
            setValue(newValue);
        }
    }

    function getClient(client_id: number) {
        apiConnect.setValues({
            id: client_id,
        });
        apiConnect.connect( 'clients/get' ).then(response => {
            setClient( response );

        })
        .catch( error => {
        console.log( 'ERROR: '+error);
        });
    }

    if (!client.id) {
        getClient(client_id);
    }

    const backHome = (): void => {
        handleChange("perus");
    }

    return (
		<DefaultTemplate
			title="Asiakasnäkymä"
			content={
                client && client.id
                ?
                <div>
                    <AppBar position="static">
                        {
                            value !== 'kalenteri'
                            ? <Tabs 
                                value={value}
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab value="perus" onClick={() => handleChange("perus")} label="Perustiedot" />
                                <Tab value="extra" onClick={() => handleChange("extra")} label="Liiketoiminta- ja rahoituskuvaukset" />
                                <Tab value="muisti" onClick={() => handleChange("muisti")} label="Muistiinpanot ja tiedostot" />
                                <Tab value="kaupat" onClick={() => handleChange("kaupat")} label="Tarjoukset ja kaupat" />
                                <Tab value="sahko" onClick={() => handleChange("sahko")} label="Sähköpostit ja tapahtumahistoria" />
                            </Tabs>
                            : <Toolbar variant="dense">
                                <Grid container>
                                    <Grid item xs={9}>
                                        <Typography variant="h6">
                                            Ajanvaraus: {client.company}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} style={{textAlign: "right"}}> 
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            color="inherit"
                                            onClick={() => handleChange("perus")}
                                        >
                                            Peruuta
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        }
                    </AppBar>
                    {value == 'perus' && <Informations Cclient={client} selectTab={handleChange}></Informations>}
                    {value == 'kalenteri' && <CalendarPage clientId={client.id} afterSaveAction={backHome} />}
                    {value == 'extra' && <ExtraInfo Cclient={client}></ExtraInfo>}
                    {value == 'muisti' && <NotesAndFiles Cclient={client}></NotesAndFiles>}
                    {value == 'kaupat' && <OffersAndAccepts Cclient={client}></OffersAndAccepts>}
                    {value == 'sahko' && <EmailsAndHistories Cclient={client}></EmailsAndHistories>}
                </div>
                :
                <Grid item xs={12}>
                <BasicAlert 
                    type="info"
                    message="Haetaan asiakastietoja"
                />
                </Grid>

            }
		/>


    );

}

export default ClientPage;
