import { Paper } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/index";
import { Client, ClientHistories } from "../../model/client"; 

import ApiConnection from "../../actions/api/connect";

import EmailList from './Parts/EmailList';
import EventHistory from './Parts/EventHistory';

const useStyles = makeStyles({
	paper: {
        padding: 20,
        paddingTop: 15,
	},
	table: {
		width: "100%",
	},
});

function EmailsAndHistories({
    Cclient,
}: {
    Cclient: Client
}) {
    const classes = useStyles();
    const token =  useSelector((state: RootState) => state.user.token);

    const [ClientHistories, setClientHistories] = React.useState<ClientHistories[] | undefined>(undefined);

    const apiConnect = new ApiConnection(token);
    
    function getHistoryList() {
        apiConnect.setValues({
			client_id: Cclient.id,
		});
        apiConnect.connect( 'Histories/list' ).then(data => {
            setClientHistories( data );
            console.log('reset history');
        })
        .catch( error => {
        });
    }

    const update = () => {
        getHistoryList();
    }

    React.useEffect(() => {
        getHistoryList();
    }, [])

    return (
        <Paper className={classes.paper} square={true}>
            <EmailList Cclient={Cclient} OnUpdate={update}/>
            <EventHistory history={ClientHistories ? ClientHistories : []} />
        </Paper>
    );
};

export default EmailsAndHistories;
