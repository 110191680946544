// prettier-ignore
import { Theme } from "@material-ui/core/styles";

import { makeStyles } from "@material-ui/styles";
import * as React from "react";

import { Router } from "react-router-dom";
import { history } from "./configureStore";

import RouteHandler from "./components/Route/RouteHandler";

import { withRoot } from "./withRoot";

function App() {
	const classes = useStyles();
	return (
		<Router history={history}>
			<div className={classes.root}>
				<div className={classes.appFrame}>
					<RouteHandler />
				</div>
			</div>
		</Router>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: "100%",
		height: "100%",
		zIndex: 1,
		overflow: "hidden",
	},
	appFrame: {
		position: "relative",
		display: "flex",
		width: "100%",
		height: "100%",
	},
}));

export default withRoot(App);
