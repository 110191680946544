import { Button, Divider, FormControl, Grid, Input, InputLabel, OutlinedInput, Paper, Typography, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import { BasicAlert, IBasicAlert } from '../../components/Alerts/BasicAlert';

import * as React from "react";

import { User } from "../../model/user"; //UserAction, UserActions, 

function UserList({
    userList,
    select,
}: {
    userList: User[];
    select: React.Dispatch<User>; // = () => void
}) {

    function handleSelect(user: User){
        select(user);
    }

    return (
        <Paper square={true}>
            <Table>
				<TableHead>
					<TableRow>
						<TableCell padding="default">Nimi</TableCell>
						<TableCell padding="default">Sähköposti</TableCell>
						<TableCell padding="default"></TableCell>
					</TableRow>
				</TableHead>
                <TableBody>
                    {
                        userList.map((user: User, key: number) => {
                            return(
                                <TableRow key={key} onClick={() => handleSelect(user)}>
                                    <TableCell padding="default">{user.name}</TableCell>
                                    <TableCell padding="default">{user.username}</TableCell>
                                    <TableCell padding="default"></TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </Paper>
    );
}

export default UserList;