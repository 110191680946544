import * as React from "react";
import { InputLabel, FormControl, Select, MenuItem, Grid, Button, Dialog, DialogContent, DialogActions, DialogTitle, TextField } from "@material-ui/core";

import {CalendarEvent, CalendarEventProto, CalendarStatusOption} from "../../model/calendar";

import { timeHour } from "d3-time"; //timeMonday
import { timeFormat, timeParse } from "d3-time-format";

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';

export interface DialogProps {
	open: boolean;
    onClose: () => void;
    save?: (calEvent: CalendarEventProto) => void;
    onDelete?: (id: number) => void;
    stampToDate: (timestamp: number | undefined) => string;
    stampToClock: (timestamp: number | undefined) => string;
    appointment?: CalendarEvent | CalendarEventProto;
    statusOptions: CalendarStatusOption[];
    client?: string;
}

export function EventDialog(props: DialogProps){
    const { open, onClose, onDelete, save, appointment, stampToDate, stampToClock, statusOptions, client } = props;
    // appointment?.starttime ? timeHour(fromUnixTime(appointment.starttime)) :
    const defaultStart: Date = timeHour.ceil( new Date() );
    const defaultEnd: Date = fromUnixTime( getUnixTime(defaultStart) + 1800 );
    // console.log('::'+JSON.stringify({...event}));

    const defaultCalEvent: CalendarEventProto = {};
    /*
    let actAppointment =  appointment;
    if (!appointment) {
        actAppointment =  defaultCalEvent;
    }
    */
    // event ? {...event} : {}

    // console.log('appointment');
    // console.log(appointment);

    /*if (appointment) {
        if (appointment.starttime) console.log('start: '+fromUnixTime( appointment.starttime ) );
        if (appointment.endtime) console.log('end: '+fromUnixTime( appointment.endtime ) );
    }*/

    // const startTime: Date = appointment?.starttime ? fromUnixTime( appointment.starttime ) : defaultStart;
    // const startTime: Date | undefined = undefined;

    const [calEvent, setCalEvent] = React.useState(defaultCalEvent);
    const [timeStart, setTimeStart] = React.useState<Date>(); //defaultStart appointment?.starttime ? fromUnixTime(appointment.starttime) : defaultStart
    const [duration, setDuration] = React.useState(1800);//

    if ( appointment?.starttime && !timeStart ) {
        setTimeStart( fromUnixTime(appointment.starttime) );
        if ( appointment.starttime && appointment.endtime && appointment.endtime - appointment.starttime >= 1800) {
            setDuration( appointment.endtime - appointment.starttime );
        } else {
            setDuration( 1800 );
        }
    }
    console.log('!calEvent.starttime: '+!calEvent.starttime);
    console.log(calEvent);
    if ( appointment?.starttime && !calEvent.starttime ) {
        setCalEvent( appointment );
    }


    const handleClose = () => {
        onClose();
        setCalEvent(defaultCalEvent);
        setTimeStart(undefined);
    };

    const handleDelete = () => {
        const id = { ...appointment}.id;
        if( id && onDelete) onDelete(id);
    }

    const handleChange = (prop: keyof CalendarEventProto) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setCalEvent({ ...calEvent, [prop]: event.target.value });
        /*if ( !calEvent?.starttime ) {
            setCalEvent({ ...appointment, [prop]: event.target.value });
        } else {
            setCalEvent({ ...calEvent, [prop]: event.target.value });
        }*/
        
    };

    //const changeUser = () => 
    const handleSelectChange = (prop: keyof CalendarEventProto) => (event: React.ChangeEvent<{ value: any }>) => {
        //console.log(prop + ': ' + event.target.value);
        setCalEvent({ ...calEvent, [prop]: event.target.value });
    };

    /*const startDateCheck = () => {
        console.log('startDateCheck');
        if ( !calEvent?.starttime && appointment) {
            setCalEvent(appointment);
            console.log(appointment);
        }
    }

    const handleDateChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log('dateChange');
    }*/

    const handleDateChange = (date: Date | null) => {
        if (date) {
            // const eventString: string = JSON.stringify(calEvent);
            // const event = JSON.parse(eventString);
            const starttime = getUnixTime(date);
            const endtime: number = starttime + duration;
            
            setTimeStart(date);
            
            // console.log( fromUnixTime(starttime).toLocaleString()  + ' - ' + fromUnixTime(endtime).toLocaleString() );

            const event: CalendarEventProto = { ...calEvent };
            event.starttime = starttime;
            event.endtime = endtime;
            setCalEvent(event);
        }
    };
    
    const handleTimeChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        //console.log(prop);
        //console.log(event.target.value);
        const date = timeStart;
        if (date) {
            const timeString = timeFormat('%Y-%m-%d')(date) + ' ' + event.target.value;
            const time = timeParse('%Y-%m-%d %H:%M')(timeString);

            if ( time ) {
                if (prop === 'start') {
                    const starttime = getUnixTime(time);
                    setTimeStart(time);
                    const endtime: number = starttime + duration;
                    const event: CalendarEventProto = { ...calEvent };
                    event.starttime = starttime;
                    event.endtime = endtime;
                    setCalEvent(event);                    
                } else if (prop === 'end') {
                    const endtime = getUnixTime(time);
                    const starttime = getUnixTime(date);
                    const dur = endtime - starttime;
                    if ( dur >= 1800 ) {
                        setDuration(dur);
                        setCalEvent({ ...calEvent, ['endtime']: endtime });
                    } 
                    // console.log('dur: '+dur);
                }
                
            }
        }
    }

    const selectedEndTime = () => {
        if (timeStart && duration) {
            const end = getUnixTime(timeStart) + duration;
            // const timeEnd = fromUnixTime(end);
            return stampToClock( end );
        }
        return undefined;
    }

    const handleSave = () => {
        if ( calEvent?.starttime ) {
            console.log(JSON.stringify(calEvent));
            if (save) save(calEvent);
            console.log(calEvent);
        }
        handleClose();
    }
    /*
    { appointment && appointment.starttime 
                            ? stampToDate(appointment.starttime) + ' ' 
                            : '' 
                        }
                        { appointment && appointment.endtime 
                            ? stampToDate(appointment.endtime) + ' '// + stampToClock(appointment.endtime)
                            : '' 
                        }
    */

    return (
		<Dialog open={open} onClose={handleClose}>
            {
                client ? <DialogTitle>{client}</DialogTitle> : ''
            }
			<DialogContent>
                <TextField
                    required
                    fullWidth
                    id="subject"
                    label="Tapaamisen aihe"
                    defaultValue={appointment?.subject}
                    onChange={handleChange('subject')}
                />
            </DialogContent>
            <DialogContent>
                <Grid container>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider 
                            utils={DateFnsUtils}
                            //style={{marginTop: "0px"}}
                        >
                            <KeyboardDatePicker
                                //margin="none"
                                disableToolbar
                                variant="inline"
                                format="dd.MM.yyyy"
                                // type="date-local"
                                id="date-picker-inline"
                                label="Päivämäärä"
                                // value={fromUnixTime(calEvent.starttime)}
                                value={timeStart}
                                // onFocus={startDateCheck}
                                //  ? fromUnixTime(calEvent.starttime) : appointment?.starttime ? fromUnixTime(appointment.starttime) : defaultStart
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3} style={{paddingLeft: '1rem'}}>
                        <TextField
                            id="starttime"
                            type="time"
                            label="Alkaa"
                            defaultValue={appointment?.starttime ? stampToClock(appointment.starttime) : defaultStart}
                            onChange={handleTimeChange('start')}
                            // className={classes.textField}
                            inputProps={{
                                step: 1800, // 30 min
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            id="endtime"
                            type="time"
                            label="Päättyy"
                            value={selectedEndTime()}
                            // defaultValue={appointment && appointment.endtime ? stampToClock(appointment.endtime) : defaultEnd}
                            onChange={handleTimeChange('end')}
                            // className={classes.textField}
                            inputProps={{
                                step: 1800, // 30 min
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogContent>
                <FormControl>
                    <InputLabel id="status-selector">Status</InputLabel>
                    <Select
                        defaultValue={calEvent.status_id || 1}
                        onChange={handleSelectChange('status_id')}
                        labelId="status-selector"
                        displayEmpty
                    >
                        {
                            statusOptions.map((option: CalendarStatusOption, key: number) => {
                                return <MenuItem key={key} value={option.id}>{option.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogContent>
                <TextField
                    fullWidth
                    multiline
                    rows="4"
                    id="text"
                    label="Tarkemmat tiedot"
                    defaultValue={appointment?.text}
                    onChange={handleChange('text')}
                />
            </DialogContent>
			<DialogActions>
                {
                    { ...appointment}.id ? (
                        <Button
                            color="primary" 
                            onClick={handleDelete}
                        >
                            Poista
                        </Button>
                    ) : ''
                }
                <Button
                    color="primary" 
                    onClick={handleSave}
                >
                    OK
                </Button>
            </DialogActions>
		</Dialog>
	);
}