import { UserAction, UserActions, User } from "../model/user";
import createReducer from "./createReducer";

const INITIAL_STATE = {};

export const user = createReducer<User>(
	INITIAL_STATE, 
	{
		[UserActions.LOGIN](state: User, action: UserAction) {
			if ( action.payload && typeof(action.payload) !== 'number') {
				state = action.payload;
			}
			return state;
		},
		[UserActions.LOGOUT](state: User) { 
			state = INITIAL_STATE;
			return state;

		},
		[UserActions.SETMINUTES](state: User, action: UserAction) {
			if ( action.payload && typeof(action.payload) === 'number') {
				//console.log('EXPTime: '+action.payload);
				state.token_expires = action.payload;
			}
			return state;
		},
	}
);
