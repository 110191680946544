import { SearchCriteriaAction, SearchCriteriaActions, SearchCriteria } from "../model/searchCriteria";
import createReducer from "./createReducer";

const INITIAL_STATE: SearchCriteria[] = [];

export const searchCriteriaList = createReducer<SearchCriteria[]>(
    INITIAL_STATE,
    {
		[SearchCriteriaActions.ADD_CRITERIA](state: SearchCriteria[], action: SearchCriteriaAction) {
            const cVals: string | SearchCriteria = action.payload;
            if ( typeof(cVals) !== 'string' && cVals.name ) {
                const criterias = state.filter((c) => { return (c.name === cVals.name) });
                if (criterias.length > 0) {
                    return state.map(c =>
                        c.name === cVals.name ? cVals : c
                    );
                }
            }
			return [...state, action.payload];
        },
        [SearchCriteriaActions.RESET_CRITERIA](state: SearchCriteria[], action: SearchCriteriaAction) {
            //console.log(action);
            const cVals: string | SearchCriteria = action.payload;
            //console.log('cVals');
            //console.log(cVals);
            if ( cVals ) {
                const criterias = state.filter((c) => { return (c.name !== cVals) });
                return criterias;
            }
			state = INITIAL_STATE;
			return state;
        },
    }
);