import { Button, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography } from "@material-ui/core";

import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from "@material-ui/styles";

import 'date-fns';
import * as React from "react";
import { useSelector } from "react-redux";
import ApiConnection from "../../actions/api/connect";
import { history } from "../../configureStore";
import { Client, ClientHistories } from "../../model/client";
import { RootState } from "../../reducers/index";

import EventHistory from './Parts/EventHistory';
import AddEvent from './Parts/AddEvent';
import CompanyInfo from './Parts/CompanyInfo';
import ClientManagers from './Parts/ClientManagers';
import ClientMeetings from './Parts/ClientMeetings';
import ClientReminder from './Parts/ClientReminder';
import ClientContactPersons from './Parts/ClientContactPersons';

const useStyles = makeStyles({
	paper: {
        padding: 20,
        paddingTop: 15,
	},
	table: {
		width: "100%",
    },
    ul: {
        // backgroundColor: 'inherit',
        marginTop: '0rem',
        padding: '0.2rem',
    },
    li: {
        // backgroundColor: 'inherit',
        marginTop: '0rem',
        padding: '0.2rem',
    },
    icon: {
        width: 16,
        height: 16,
    },
});

function Informations({
    Cclient,
    selectTab,
}: {
    Cclient: Client,
    selectTab: (str: string) => void, 
}) {
    const classes = useStyles();

    const token =  useSelector((state: RootState) => state.user.token);
    const apiConnect = new ApiConnection(token);
    const [statusList, setStatusList] = React.useState<IStatus[]>([]);

    const [client, setClient] = React.useState<Client>({});
    const [ClientHistories, setClientHistories] = React.useState<ClientHistories[]>([]);

    function listHistories(){
        console.log('listHistories()');
		apiConnect.setValues({
            client_id: Cclient.id,
            limit: 5,
		});
        apiConnect.connect( 'Histories/list' ).then(data => {
            console.log(data);
			setClientHistories( data );
        })
        .catch( error => {
        });
    }

    function getClient() {
        apiConnect.setValues({
			id: Cclient.id,
        });
        apiConnect.connect( 'clients/get' ).then(response => {
            console.log(response);
            setClient( response );
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    const handleStatusChange = (prop: keyof Client) => (event: React.ChangeEvent<{ value: any }>) => {
        apiConnect.setValues({
            id: Cclient.id,
            dealflow_status_id: event.target.value,
        });
        apiConnect.connect( 'clients/saveDealFlow' ).then(response => {
            setClient({ ...client, [prop]: event.target.value });
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
        /*/clients/saveDealFlow
        Lähetetään parametrit
        id = client ID
        dealflow_status_id = dealflow:n status id.*/
    }; 

    function handleContractPrint() {
        //window.open(apiDomain+"Clients/printContract/"+Cclient.id+"/"+client.sha);
        history.push({
            pathname: '/client/signature',
            state: {client_id: client.id, print: 1},
        });
    }

    function handleContractSignature() {
        history.push({
            pathname: '/client/signature',
            state: {client_id: client.id, print: 0},
        });
    }

    function getStatusList() {
        apiConnect.setValues({});
        apiConnect.connect( 'clients/listDealFlow' ).then(response => {
            console.log(response);
            setStatusList(response);
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    React.useEffect(() => {
        getClient();
        getStatusList();
        listHistories();
    }, [])

    if (client.id) {
        return (
            <Paper className={classes.paper} square={true}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <Typography variant="h4">{Cclient.company} (#{Cclient.id})</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                            <InputLabel
                                id="client_status"
                            >
                                Asiakkaan tila
                            </InputLabel>
                            <Select
                                fullWidth
                                id="client_status"
                                defaultValue={client.dealflow_status_id}
                                onChange={handleStatusChange('dealflow_status_id')}
                            > 
                                {
                                    statusList.map((status: IStatus, key: number) => {
                                        return(
                                            <MenuItem key={key} value={status.id}>{status.name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <LinearProgress variant="determinate" color="secondary" value={
                            statusList.filter(status => status.id === client.dealflow_status_id).length === 1
                            ? statusList.filter(status => status.id === client.dealflow_status_id)[0].percent
                            :0
                        } />
                    </Grid>
                    <Grid item xs={12}>
                        <EventHistory history={ClientHistories ? ClientHistories : []} shortlist={true} />
                        <AddEvent Cclient={Cclient} afterSave={listHistories}/>
                    </Grid>
                    <Grid item xs={12}><Divider /></Grid>
                    <Grid item xs={12} style={{textAlign: "right"}}>
                        <Button
                            style={{marginLeft: "1rem", marginBottom: "0.5rem"}}
                            variant="contained" 
                            color="secondary" 
                            size="small" 
                            disableElevation
                            onClick={ () => handleContractPrint() }
                        >
                            Tulosta sopimus
                        </Button>
                        <Button
                            style={{marginLeft: "1rem", marginBottom: "0.5rem"}}
                            variant="outlined" 
                            color="secondary" 
                            size="small" 
                            disableElevation
                            onClick={ () => handleContractSignature() }
                        >
                            Lähetä sopimus sähköiseen allekirjoitukseen
                        </Button>
                    </Grid>
                    <Grid item xs={12}><Divider /></Grid>
                    <Grid item xs={12} md={4}>
                        <ClientMeetings Cclient={Cclient} selectTab={selectTab} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ClientReminder Cclient={Cclient} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ClientManagers Cclient={Cclient} />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <CompanyInfo Cclient={Cclient} />
                    </Grid>
                </Grid>
                <Divider style={{marginTop: "20px", marginBottom: "20px"}} />
                <ClientContactPersons Cclient={Cclient} />
            </Paper>
        );
    } else {
        return (
            <Paper className={classes.paper} square={true}>
                <Typography variant="h6">
                    Haetaan tietoja
                </Typography>
            </Paper>
        );
    }
};

export default Informations;

interface IStatus{
    id: number;
    name: string;
    percent: number;
}
