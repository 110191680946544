
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Button, Divider, FormControl, Grid, TextField, OutlinedInput, Paper, Toolbar, Typography, Select, MenuItem, InputLabel } from "@material-ui/core";

import * as React from "react";
import ApiConnection from "../../actions/api/connect";
import { User } from "../../model/user"; //UserAction, UserActions,
import { ClientSearch } from "../../model/client"; //UserAction, UserActions,
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/index";

import { BasicAlert, IBasicAlert } from '../../components/Alerts/BasicAlert';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
            textAlign: 'right',
        },

        paper: {
            padding: 20,
        },

        footer: {
            backgroundColor: '#fff', // theme.palette.grey["300"],
        }
    }),
);

function SearchConditions({
    listC,
}: {
    listC: React.Dispatch<ClientSearch>
}) {
    const token =  useSelector((state: RootState) => state.user.token);
    const apiConnect = new ApiConnection(token);
    const [userList, setUserList] = React.useState<User[]>([]);
    const [statusList, setStatusList] = React.useState<IStatus[]>([]);
  
    const classes = useStyles();
    const [values, setValues] = React.useState<ClientSearch>({
        company_name: '',
        name: '',
        phone: '',
        vatnumber: '',
        user_id: 0,
        dealflow_status_id: 0,
    });

     React.useEffect(() => {
         listC(values);
      }, [values]);
    

    const handleAccessChange = (prop: keyof ClientSearch) => (event: React.ChangeEvent<{ value: any }>) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    
    const handleChange = (prop: keyof ClientSearch) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    function getUsers(){
        apiConnect.setValues({});
        apiConnect.connect( 'users/listUsers' ).then(response => {
            setUserList( response.data.users );

        })
        .catch( error => {
        console.log( 'ERROR: '+error);
        });
    }

    function handleSearch() {
       listC(values); 
    }

    function getStatusList() {
        apiConnect.setValues({});
        apiConnect.connect( 'clients/listDealFlow' ).then(response => {
            console.log(response);
            setStatusList(response);
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    if ( userList.length === 0 ) {
        getUsers();
    }

    if (statusList.length === 0) {
        getStatusList();
    }

    return (
        <React.Fragment>
            <AppBar position="static" color="secondary">
                <Toolbar variant="dense">
                    <Typography variant="h6">
                        Hakuehdot
                    </Typography>
                </Toolbar>
            </AppBar>
            <Paper square={true}>
                <form noValidate autoComplete="off" className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                id="company_name"
                                label="Yrityksen nimi"
                                onChange={handleChange('company_name')} 
                                value={values.company_name}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                id="vatnumber"
                                label="Y-tunnus"
                                onChange={handleChange('vatnumber')} 
                                value={values.vatnumber}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                id="name"
                                label="Kontaktihenkilö"
                                onChange={handleChange('name')} 
                                value={values.name}
                            />

                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                id="phone"
                                label="Puhelinnumero"
                                onChange={handleChange('phone')} 
                                value={values.phone}
                            />
                        </Grid>
                        <Grid item xs={4}>
    						<InputLabel
                                id="user_id"
                            >
                                Asiakasvastaava
                            </InputLabel>
                            <Select id="user_id" value={values.user_id} onChange={handleAccessChange('user_id')}>                        
                                <MenuItem value="0">Kaikki</MenuItem>
                                {
                                    userList.map((user: User, key: number) => {
                                        return(
                                            <MenuItem key={key} value={user.id}>{user.name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>

                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel
                                id="user_id"
                            >
                                Dealflow tila
                            </InputLabel>
                            <Select id="dealflow_status_id" value={values.dealflow_status_id} onChange={handleAccessChange('dealflow_status_id')}>                        
                                <MenuItem value="0">Kaikki</MenuItem>
                                {
                                    statusList.map((status: IStatus, key: number) => {
                                        return(
                                            <MenuItem key={key} value={status.id}>{status.name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>

                        </Grid>
                    </Grid>

                </form>
                <Divider />
                <Toolbar variant="dense">
                    <Grid container>
                        <Grid item xs={12} className={classes.root}>
                            <Button 
                                variant="contained" 
                                color="secondary" 
                                size="small" 
                                disableElevation
                                onClick={ () => handleSearch() }
                                >
                                Hae
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </Paper>
        </React.Fragment>
    );
};

export default SearchConditions;

interface IStatus{
    id: number;
    name: string;
    percent: number;
}
