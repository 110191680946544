import * as React from "react";

import { Button, Typography } from "@material-ui/core";

import { addReminder } from "../../../model/reminder";
import { Client } from "../../../model/client";

import getUnixTime from 'date-fns/getUnixTime';
import startOfTomorrow from 'date-fns/startOfTomorrow';

import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/index";

import { ReminderApi } from "../../Reminders/ReminderApi";
import { ReminderDialog } from "../../Reminders/ReminderDialog";

function ClientReminder({
    Cclient,
}: {
    Cclient: Client
}) {

    const [reminder, setReminder] = React.useState<addReminder | undefined>(undefined);

    const token =  useSelector((state: RootState) => state.user.token);
    const remApi = new ReminderApi(token);

    function getReminder() {
        remApi.listClient({
            client_id: Cclient.id,
        })
        .then(response => {
            console.log('getReminder');
            if (response?.id) {
                setReminder(response);
            } 
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    function addReminder() {
        const selRem: addReminder = {
            time: getUnixTime(startOfTomorrow()),
        };
        setReminder(selRem);
    }

    function saveReminder(selRem: addReminder){
        if ( !selRem.client_id ) {
            selRem.client_id = Cclient.id;
        }
		remApi.save(selRem)
		.then(response => {
            console.log(response);
        })
        .catch( error => {
        	console.log( 'ERROR: '+error);
        });
    }

    React.useEffect(() => {
        getReminder();
    }, [])
    
    return (
        <React.Fragment>
            <Typography variant="h6">Muistutus</Typography>
            <div style={{marginTop: "1.25rem", textAlign: "right"}}>
                { reminder && reminder.time ?
                    <ReminderDialog
                        open={reminder.time ? true : false}
                        //reminder ? true : false
                        //onClose={closeDialog}
                        onSave={saveReminder}
                        //onDelete={deleteReminder}
                        actReminder={reminder}
                        noDialog={true}
                    />
                    : <Button
                        variant="contained" 
                        color="secondary" 
                        size="small" 
                        disableElevation
                        onClick={ () => addReminder() }
                    >
                        Lisää muistutus
                    </Button>
                }
            </div>
        </React.Fragment>
    )
}

export default ClientReminder;