import * as React from "react";

import { Button, FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";

import { Client } from "../../../model/client";
import { CalendarEvent } from "../../../model/calendar";

import 'date-fns';
import { endOfYesterday, isAfter } from 'date-fns';
import dateFormat from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';

import ApiConnection from "../../../actions/api/connect";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/index";

function ClientMeetings({
    Cclient,
    selectTab,
}: {
    Cclient: Client,
    selectTab: (str: string) => void,
}) {
    const [meetings, setMeetings] = React.useState<CalendarEvent[] | undefined>(undefined);
    const [filterMeetings, setFilterMeetings] = React.useState<Boolean>(true);

    const token =  useSelector((state: RootState) => state.user.token);
    const apiConnect = new ApiConnection(token);

    function getMeetings() {
        apiConnect.setValues({
			client_id: Cclient.id,
        });
        apiConnect.connect( 'calendars/list' ).then(response => {
            setMeetings(response);
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    React.useEffect(() => {
        getMeetings();
    }, [])

    return (
        <Grid container>
            <Grid item xs={12} md={6}><Typography variant="h6">Tapaamiset</Typography></Grid>
            <Grid item xs={12} md={6} style={{marginTop: '0.2rem'}}>
                <FormControlLabel
                    control={
                    <Switch
                        checked={!filterMeetings}
                        onChange={() => setFilterMeetings(!filterMeetings)}
                        size="small"
                        name="checkedB"
                        color="primary"
                    />
                    }
                    label="Näytä menneet"
                />
            </Grid>
            <Grid item xs={12} style={{marginTop: '1rem', marginBottom: '1rem'}}>
                {
                    meetings && meetings.length > 0
                        ? meetings.map((meeting: CalendarEvent, key: number) => (
                            !filterMeetings || (filterMeetings && isAfter(fromUnixTime(meeting.starttime), endOfYesterday() ))
                                ?
                                    <Grid container key={key} style={{marginBottom: '0.5rem'}}>
                                        <Grid item style={{paddingRight: '0.5rem'}} xs={12} md={4}>
                                            <Grid container>
                                                <Grid item xs={12} sm={'auto'} md={12} style={{paddingRight: '0.5rem'}}>
                                                    <Typography variant="body2">{dateFormat(fromUnixTime(meeting.starttime),"dd.MM.yyyy")}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={'auto'} md={12}>
                                                    <Typography variant="caption">{dateFormat(fromUnixTime(meeting.starttime),"HH:mm")}–{dateFormat(fromUnixTime(meeting.endtime),"HH:mm")}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="body2">{meeting.subject}</Typography>
                                            <Typography variant="caption">{meeting.text}</Typography>
                                        </Grid>
                                    </Grid>
                                : ''
                        ))
                        : 'Ei sovittuja tapaamisia'
                }
            </Grid>
            <Grid item xs={12} style={{textAlign: 'right'}}>
                <Button 
                    variant="contained" 
                    color="secondary" 
                    size="small" 
                    disableElevation
                    onClick={ () => selectTab('kalenteri') }
                    >
                    Lisää uusi
                </Button>
            </Grid>
        </Grid>
    );
}

export default ClientMeetings;