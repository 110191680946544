import DefaultTemplate from "../components/PageTemplates/DefaultTemplate";

import SearchConditions from "../components/Search/SearchConditions";
import SearchResultList from "../components/Search/SearchResultList";

import { Grid, Paper, Table, TableHead, TableBody, TableRow, TableCell, Button, TextField } from "@material-ui/core";

import * as React from "react";

import ApiConnection from "../actions/api/connect";

import { useSelector } from "react-redux";
import { RootState } from "../reducers/index";
import { BasicAlert, IBasicAlert } from '../components/Alerts/BasicAlert';
import { history } from "../configureStore";

import { ProspectData } from "../model/call";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
	paper: {
        padding: 20,
	},
});

export function AddProspectPage() {
	const classes = useStyles();

	const token =  useSelector((state: RootState) => state.user.token);

	const [prospect, setProspect] = React.useState<ProspectData>({});
	const [alert, setAlert] = React.useState<IBasicAlert>({});

    const apiConnect = new ApiConnection(token);

    const handleChange = (prop: keyof ProspectData) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setProspect({ ...prospect, [prop]: event.target.value });
    };

	function handleSave(choose: any) {
        apiConnect.setValues({
            company: prospect.company || '',
            vatnumber: prospect.vatnumber || '',
            address: prospect.address || '',
            zipcode: prospect.zipcode || '',
            city: prospect.city || '',
            name: prospect.name || '',
            phone: prospect.phone || '',
        });
        apiConnect.connect( 'Prospects/add' ).then(data => {
            if (choose == 1) {
                history.push({
                    pathname: '/call',
                });
            } else {
                history.push({
                    pathname: '/call',
                    state: {prospect_id: data.id},
                });
            }
        })
        .catch( error => {
            console.log(error);
        });
	}

    return (
        <DefaultTemplate
            title='Lisää uusi prospekti'
            content={
                <Paper square={true} style={{marginTop: "20px"}}>
                    <Grid container spacing={3} className={classes.paper}>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                id="company"
                                label="Yrityksen nimi"
                                onChange={handleChange('company')} 
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                id="vatnumber"
                                label="Y-tunnus"
                                onChange={handleChange('vatnumber')} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="address"
                                label="Osoite"
                                onChange={handleChange('address')} 
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                id="zipcode"
                                label="Postinumero"
                                onChange={handleChange('zipcode')} 
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                id="city"
                                label="Kaupunki"
                                onChange={handleChange('city')} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="name"
                                label="Yhteyshenkilön nimi"
                                onChange={handleChange('name')} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="phone"
                                label="Puhelinnumero"
                                onChange={handleChange('phone')} 
                            />
                        </Grid>
                        <Grid item xs={6} style={{textAlign: "left"}}>
                            <Button
                                variant="contained"
                                color="secondary"
                                //size="small"
                                disableElevation
                                onClick={ () => handleSave(1) }
                            >
                                Tallenna
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            }
        />
    );

};
