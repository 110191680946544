import { Button, Grid, Paper, Typography, Table, TableHead, TableBody, TableCell, TableRow, Tab, Tabs, AppBar, Toolbar } from "@material-ui/core";
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";
import ApiConnection from "../actions/api/connect";
import { User } from "../model/user";
import { Reminders } from "../model/reminder";
import { CalendarEvent } from "../model/calendar";

import { history } from "../configureStore";

import HomeLeed from "../components/Home/HomeLeed";

import TokenTimer from "../components/TokenTimer";

import dateFormat from 'date-fns/format';
import { endOfYesterday } from 'date-fns';
import { isAfter } from 'date-fns';
//import isAfter from 'date-fns/is_after';

import 'date-fns';
//import isAfter from '@date-io/date-fns';
//import endOfYesterday from '@date-io/date-fns';

import DateFnsUtils from '@date-io/date-fns';
import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';
import startOfTomorrow from 'date-fns/startOfTomorrow';

export function HomePage() {
	const classes = useStyles();
	const [boxColor, setBoxColor] = React.useState("red");
	// const todoList = useSelector((state: RootState) => state.todoList);
	const token =  useSelector((state: RootState) => state.user.token);
    const apiConnect = new ApiConnection(token);
	const [user, setUser] = React.useState<User>({});
	const [reminders, setReminders] = React.useState<Reminders[]>([]);
	const [calendars, setCalendars] = React.useState<CalendarEvent[]>([]);

	const [value, setValue] = React.useState<Number>(1);

	const dealFlowTabs = [
		{
			title: "Liidi",
			id: 1,
		},
		{
			title: "Kontaktoitu",
			id: 2,
		},
		{
			title: "Tapaaminen sovittu",
			id: 3,
		},
		{
			title: "Asiakassopimus",
			id: 4,
		},
		{
			title: "Työalla",
			id: 5,
		},
		{
			title: "Rahoitettu",
			id: 6,
		},
		{
			title: "Laskutettu",
			id: 7,
		},
		{
			title: "Lasku maksettu",
			id: 8,
		},
	];

    function handleChange(newValue: number) {
		if ( newValue ) {
            setValue(newValue);
		}
    }


	function getUser() {
		apiConnect.setValues({
		});
		apiConnect.connect( 'users/get' ).then(response => {
			setUser( response.data.user );

		})
		.catch( error => {
		console.log( 'ERROR: '+error);
		});
	}

	function selectCalEvent(event: CalendarEvent){
		console.log(event);
		history.push({
			pathname: '/calendar',
			state: {event: {
				id: event.id,
				starttime: event.starttime,
				endtime: event.endtime,
				user_id: event.user_id,
				subject: event.subject,
				text: event.text,
				status_id: event.status_id,
				client_id: event.client_id,
			}},
		});
	}

	function getCalendars() {
		apiConnect.setValues({});
		apiConnect.connect( 'frontPage/getCalendarsToday' ).then(data => {
			setCalendars( data );

		})
		.catch( error => {
		console.log( 'ERROR: '+error);
		});
	}

	function selectCompany(id: number) {
		history.push({
			pathname: '/client',
			state: {client_id: id},
		});
	}

	function getReminders() {
		apiConnect.setValues({});
		apiConnect.connect( 'frontPage/getRemindersToday' ).then(data => {
			console.log(data);
			setReminders( data );

		})
		.catch( error => {
		console.log( 'ERROR: '+error);
		});
	}


	if (!user.id) {
		getUser();
		getCalendars();
		getReminders();
	}


	return (
		<React.Fragment>
			<Grid container className={classes.root}>
				<Grid item xs={12}>
					<Grid container spacing={3} className={classes.paper}>
						<Grid item xs={6}>
							<AppBar position="static" color="secondary">
								<Toolbar variant="dense">
									<Typography variant="h6">Muistutukset tänään</Typography>
								</Toolbar>
							</AppBar>
							<Paper className={classes.paper} square={true}>
								{ reminders && reminders.length > 0
									? <Table>
										<TableHead>
											<TableRow>
												<TableCell padding="default">Asiakas</TableCell>
												<TableCell padding="default">Otsikko</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{
												reminders.map((reminder: Reminders, key: number) => {
													return(
														<TableRow key={key} onClick={() => selectCompany(reminder.client.id)}>
															<TableCell padding="default">{reminder.client.company}</TableCell>
															<TableCell padding="default">{reminder.subject || ''}</TableCell>
														</TableRow>
													)
												})
											}
										</TableBody>
									</Table>
									: 'Ei muistutuksia'
								}
							</Paper>
						</Grid>

						<Grid item xs={6}>
							<AppBar position="static" color="secondary">
								<Toolbar variant="dense">
									<Typography variant="h6">Tapaamiset tänään</Typography>
								</Toolbar>
							</AppBar>
							<Paper className={classes.paper} square={true}>
								{ calendars && calendars.length > 0
									? <Table>
										<TableHead>
											<TableRow>
												<TableCell padding="default">Aika</TableCell>
												<TableCell padding="default">Otsikko</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{
												calendars.map((calendar: CalendarEvent, key: number) => {
													return(
														<TableRow key={key} onClick={() => selectCalEvent(calendar)}>
															<TableCell padding="default">{dateFormat(fromUnixTime(calendar.starttime),"HH:mm")}–{dateFormat(fromUnixTime(calendar.endtime),"HH:mm")}</TableCell>
															<TableCell padding="default">{calendar.subject || ''}</TableCell>
														</TableRow>
													)
												})
											}
										</TableBody>
									</Table>
									: 'Ei tapaamisia'
								}
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<AppBar position="static" color="secondary">
								<Toolbar variant="dense">
									<Typography variant="h6">Dealflow -tilannekatsaus</Typography>
								</Toolbar>
							</AppBar>
							<Paper className={classes.paper} square={true}>
								<Grid container style={{marginTop: '1rem'}}>
									<Grid item xs={4}>
										<Tabs 
											value={value}
											variant="scrollable"
											scrollButtons="auto"
											orientation="vertical"
											className={classes.tabs}
										>
											{
												dealFlowTabs.map((tab, key: number) => (
													<Tab key={key} value={tab.id} onClick={() => handleChange(tab.id)} label={tab.title} />
												))
											}
										</Tabs>
									</Grid>
									<Grid item xs={8} style={{paddingLeft: '1rem'}}>
										{
											dealFlowTabs.map((tab, key: number) => {
												if( value === tab.id ) return <HomeLeed key={key} Ctype={value}></HomeLeed>;
											})
										}
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<TokenTimer />
		</React.Fragment>
	);

}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		/*height: "100%",*/
		padding: 40,
		maxHeight: '100%',
		overflow: 'scroll',
	},

	paper: {
		padding: 20,
	},

	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
	},

	/*
	centerContainer: {
		flex: 1,
		height: "90%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
	},

	button: {
		marginTop: 20,
	},*/
}));
