import DefaultTemplate from "../../components/PageTemplates/DefaultTemplate";

import ChangePassword from "../../components/Settings/ChangePassword";

import { Grid } from "@material-ui/core";

import * as React from "react";

export function SettingsPage() {
    return (
        <DefaultTemplate
			title="Asetukset"
			content={
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<ChangePassword />
					</Grid>
				</Grid>
			}
		/>
    );
};
