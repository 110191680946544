//import * as React from "react";
import { AppConfig } from "../../AppConfig";
//import { connect } from 'react-redux';
//import { useActions } from "..";
//import * as UserActions from "../user";
import { history } from "../../configureStore";

const apiDomain = AppConfig.apiUrl;

/*
const resetTimer = () => {
    //const minutes = useSelector((state: RootState) => state.user.minutes_left);
    //userActions.setMinutes(30);
}*/

let setter: undefined | number = undefined;

function apiConnection(url: string, request: RequestInit) {
    //const user =  useSelector((state: RootState) => state.user);
    //console.log(user);
    const conn = fetch(url, request);
    //conn.finally(() => <Tester />);
    /*conn.finally(() => {
        const a = <Tester />;
        const b = a.type;
        console.log(b);
        //console.log(t.key);
        //ReactDOM.render(<Tester />);
    });*/
    //conn.finally(() => <Tester />);
    return conn.then(
        response => {
            if ( response.status === 401 ){
                history.push('/logout');
            }
            //let a = Tester();
            console.log("Status "+response.status);
            return response.json();
        }
    ); //<Tester />);
}

export default class ApiConnection{ 
    
    private request: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type':'application/x-www-form-urlencoded',
        }
    };

    public setToken( token: string | undefined ) {
        this.request.headers = {
            'Content-Type':'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+token,
        };
    }

    public setValues( values: Object ) {
        this.request.body = Object.entries(values)
            .map((pair) => `${pair[0]}=${pair[1]}`)
            .join('&');
    }

    public connect( urlAction: string ){
        return apiConnection(apiDomain+urlAction, this.request);
        /*const conn = fetch(apiDomain+urlAction, this.request);
        const { store } = configureStore();
        conn.finally(() => {
            if ( urlAction !== 'users/login' &&  urlAction !== 'users/get' && urlAction !== 'users/getUserServices') {
                //const d = new Date();
                store.dispatch(setMinutes(30));
                //console.log('store');
                //console.log(store.getState());
                //store.subscribe(() => console.log(store.getState()))
                //console.log('resetTimer');
                //const a = ResetMinutes();
                //const a = <ResetMinutes />;
                //return a;
                //console.log( '::: ' + a );
            }
        });

        return conn.then(
            response => {
                if ( response.status === 401 ){
                    history.push('/logout');
                }
                //minutes = 30;
                //resetTimer(userActions);
                console.log("Status "+response.status);
				// if ( response.status !== 200 ) {
					// throw new Error(response.status.toString());
				// }
				return response.json();
			}
        );*/
    }

    constructor( token?: string | undefined ) {
        if ( token ) {
            this.setToken( token );
        }
        else{

        }
	}
}
