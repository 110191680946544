import * as React from "react";
import fromUnixTime from 'date-fns/fromUnixTime';
import dateFormat from 'date-fns/format';
import { Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography, Grid, Button, TextField, ThemeProvider } from "@material-ui/core";
import { AppConfig } from "../../AppConfig";

import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import {columnItem, TableProps} from "./interfaces";

import InputSelect from "./DataTypes/InputSelect";
import FileSelect from "./DataTypes/FileSelect";

const useStyles = makeStyles((theme: Theme) => ({
	subList: {
		backgroundColor: theme.palette.background.default,
		marginTop: '1rem',
	},
}));

export function TableWithEditor(props: TableProps){
    const apiDomain = AppConfig.apiUrl;
    const classes = useStyles({});
    const { title, noEdit, columns, itemData, emptyItem, onSave, onDelete, onClick, onSelect } = props; //, onFile

    const columnsToShow: columnItem[] = columns.filter( (column) => {
        if ( column.hide && column.hide === true ) return false;
        return true;
    });

    const defaultItem: Object = emptyItem ? emptyItem :{};

    const defaultOpenItem: number = -1;
    const [openItem, setOpenItem] = React.useState(defaultOpenItem);
    const [editItem, setEditItem] = React.useState<Object>(defaultItem);
    const [selFileName, setSelFileName] = React.useState<string>('');


    const removeNewRow = (key: number): void => {
        if ( itemData[key] && !itemData[key].id ) {
            itemData.splice(key, ( key + 1 ));
            // setOpenItem(defaultOpenItem);
        }
    }

    const selectRow = (key: number): void => {
        let itemN: number = 0;
        //console.log('selectRow');
        const selItem = itemData[key];
        //if (columns.file?.typeSettings?.fileNameId){
        /*const cols = columns.filter(column => column.typeSettings?.fileNameId);
        if ( cols && cols.length > 0 ) { //onSelect
            console.log( '_true: ');
            const colKey = cols[0].typeSettings?.fileNameId;
            if ( colKey && colKey in selItem ) {
                setSelFileName(selItem[colKey]);
            } else {
                setSelFileName('');
            }
            //console.log(selItem[colKey]);
            //console.log( selItem.hasOwn ); //colKey
            //itemData[key][fileId.typeSettings.fileNameId]
        }*/
        setSelFileName('');
        setEditItem(selItem);

        if ( key !== openItem ) {
            if ( openItem ) {
                itemN = itemData.length;
                removeNewRow(openItem);
            }
            if ( itemN > 0 && itemN < itemData.length && key > openItem) {
                setOpenItem(key-1);
            } else {
                setOpenItem(key);
            }
        } else {
            removeNewRow(key);
            setOpenItem(defaultOpenItem);
        }
    }

    const addNew = (): void => {
        //if ( itemData[0].hasOwnProperty('id') ) {
            itemData.unshift(defaultItem);
        //}
        setOpenItem(defaultOpenItem);
        selectRow(0);
    }

    const handleDataChange = (prop: keyof any) => (event: React.ChangeEvent<{ value: any }>) => {
        //console.log(event.target.value);
        setEditItem({ ...editItem, [prop]: event.target.value });
    };

    const handleSelectChange = (prop: keyof any, value: any) => {
        if (value) {
            //console.log(value);
            setEditItem({ ...editItem, [prop]: value});
        }
    }


    const itemSave = (data: Object): void => {
        if (onSave) onSave(data);
        setOpenItem(defaultOpenItem);
    }

    const itemDelete = (id: number): void => {
        if (onDelete) onDelete(id);
        setOpenItem(defaultOpenItem);
    }

    const itemClick = (id: any): void => {
        if (onClick) onClick(id);
        setOpenItem(defaultOpenItem);
    }


    const setFile = (prop: keyof any, file: any) => {
        if (file) {
            setEditItem({ ...editItem, [prop]: file});
            setSelFileName(file.name);
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant="h6">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} style={{textAlign: "right"}}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            disableElevation
                            onClick={ () => addNew() }
                        >
                            Lisää uusi
                        </Button>
                    </Grid>
                </Grid>
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                columnsToShow.map((column: columnItem, key: number) => {
                                    return (
                                        <TableCell key={key} padding="default">{column.title}</TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            itemData.map((row: any, key: number) => {
                                if ( openItem === key ) {
                                    //  cols={columnsToShow.length}
                                    let cancelText = "Peruuta";
                                    let rowSpacing: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | undefined = 3;
                                    let columnsOpen: columnItem[] = [];
                                    let deleteButton: JSX.Element | undefined = undefined;
                                    let saveButton: JSX.Element | undefined = undefined;
                                    if (noEdit == "true" && editItem.hasOwnProperty('id')) {
                                        rowSpacing = 1;
                                        cancelText = "Sulje";
                                        columnsOpen = columns.filter( (column) => {
                                            if ( column.edit && column.edit === true ) return true;
                                            if ( !column.hide ) return true;
                                            return false;
                                        });
                                    } else {
                                        columnsOpen = columns.filter( (column) => {
                                            if ( column.edit && column.edit === true ) return true;
                                            return false;
                                        });
                                        if ( onSave ) {
                                            saveButton = <Button
                                                style={{marginLeft: '1rem'}}
                                                variant="contained"
                                                color="secondary"
                                                size="small"
                                                disableElevation
                                                onClick={ () => itemSave(editItem) }
                                            >
                                                Tallenna
                                            </Button>
                                        }
                                    }

                                    if ( onDelete && row.id && row.id > 0 ) {
                                        deleteButton = <Button
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            disableElevation
                                            onClick={ () => itemDelete(row.id) }
                                        >
                                            Poista
                                        </Button>
                                    }

                                    /*onDelete && noEdit != "true" && row.id && row.id > 0 ? (

                                    ) : ''*/
                                    //console.log("openColumns: "+columnsOpen.length);
                                    // if (noEdit == "true" && editItem.hasOwnProperty('id'))
                                    /*
                                    }
                                                            return (
                                                                <Grid item xs={12} key={subKey}>
                                                                    {field}
                                                                </Grid>
                                                            );
                                                        })*/

                                    return(
                                        <TableRow key={key}>
                                            <TableCell padding="default" colSpan={columnsToShow.length}>
                                                <Grid container spacing={rowSpacing}>
                                                    {
                                                        columnsOpen.map((column: columnItem, subKey: number) => {
                                                            //console.log(column.title);
                                                            let colVal = row[column.id];
                                                            let field: JSX.Element = <TextField
                                                                fullWidth
                                                                id={column.id}
                                                                label={column.title}
                                                                onChange={handleDataChange(column.id)}
                                                                defaultValue={colVal}
                                                                key={subKey}
                                                            />;
                                                            switch(column.type) {
                                                                case 'select':
                                                                    if ( !colVal ) colVal = 0;

                                                                    field = <InputSelect
                                                                        column={column}
                                                                        colVal={colVal}
                                                                        onInputChange={handleSelectChange}
                                                                        key={subKey}
                                                                    />

                                                                    break;
                                                                case 'multiline':
                                                                    field = <TextField
                                                                        multiline
                                                                        rows="8"
                                                                        fullWidth
                                                                        id={column.id}
                                                                        label={column.title}
                                                                        onChange={handleDataChange(column.id)}
                                                                        defaultValue={colVal}
                                                                        key={subKey}
                                                                        //value={editItem[column.id] }
                                                                    />;
                                                                    break;
                                                                case 'date':
                                                                    colVal = colVal ? dateFormat(fromUnixTime(colVal),"dd.MM.yyyy") : '';
                                                                    break;
                                                                case 'datetime':
                                                                    colVal = colVal ? dateFormat(fromUnixTime(colVal),"dd.MM.yyyy HH:mm") : '';
                                                                    break;
                                                                case 'button':
                                                                    return <Grid
                                                                        item xs={12}
                                                                        style={{textAlign: "right"}}
                                                                        key={subKey}
                                                                    >
                                                                        <Button
                                                                            color="secondary"
                                                                            variant="contained"
                                                                        >
                                                                            {column.buttonTitle ? column.buttonTitle : 'Lataa'}
                                                                        </Button>
                                                                    </Grid>
                                                                    break;

                                                                case 'link':
                                                                    if (column.url && column.sha && row[column.sha] && colVal) {
                                                                        //console.log( apiDomain+column.url+'/'+colVal+'/'+row[column.sha] );
                                                                        return <Grid
                                                                            item xs={12}
                                                                            style={{textAlign: "right"}}
                                                                            key={subKey}
                                                                        >
                                                                            <Button
                                                                                href={apiDomain+column.url+'/'+colVal+'/'+row[column.sha]}
                                                                                target="_new"
                                                                                color="secondary"
                                                                                variant="contained"
                                                                            >
                                                                                {column.linkTitle ? column.linkTitle : 'Lataa'}
                                                                            </Button>
                                                                        </Grid>
                                                                        break;
                                                                    } else {
                                                                        colVal = '';
                                                                    }
                                                                case 'file':

                                                                    const titleCol = columns.filter(col => {
                                                                        if( col.id && col.id === column.typeSettings?.fileNameId) return true;
                                                                        return false;
                                                                    });

                                                                    colVal = column.typeSettings?.fileNameId ? row[column.typeSettings?.fileNameId] : undefined;
                                                                    if ( !colVal ) {
                                                                        colVal = selFileName;
                                                                    }

                                                                    field = <FileSelect
                                                                        column={column}
                                                                        colVal={colVal}
                                                                        selFileName={selFileName}
                                                                        titleCol={titleCol[0]}
                                                                        onNameChange={handleDataChange}
                                                                        onFileChange={setFile}
                                                                        key={subKey}
                                                                    />;

                                                                    if (noEdit == "true" && editItem.hasOwnProperty('id')) {
                                                                        return '';
                                                                    }
                                                                    break;

                                                                case 'list':
                                                                    //console.log('COLUMN LIST');
                                                                    if (column.list && row.id) {
                                                                        field = <Grid item xs={12} key={subKey} className={classes.subList}>
                                                                            <TableWithEditor
                                                                                title={column.title}
                                                                                noEdit={column.list.noEdit}
                                                                                columns={column.list.columns}
                                                                                itemData={colVal ? colVal : column.list.itemData}
                                                                                emptyItem={column.list.emptyItem}
                                                                                onSave={(data: any) => {
                                                                                    if (column && column.list && column.list.onSave ) {
                                                                                        data.__row_id = row.id;
                                                                                        column.list.onSave(data);
                                                                                    }
                                                                                }}
                                                                                onDelete={column.list.onDelete}
                                                                            />
                                                                        </Grid>;
                                                                    } else {
                                                                        return '';
                                                                    }
                                                                    break;
                                                            }

                                                            if (noEdit == "true" && editItem.hasOwnProperty('id')) {
                                                                return <Grid item xs={12} key={subKey}>
                                                                    <Typography variant="caption" style={{marginTop: 0}}>
                                                                        {column.title}
                                                                    </Typography>
                                                                    <Typography style={{marginTop: 0}}>
                                                                        {colVal}
                                                                    </Typography>
                                                                </Grid>;
                                                            }
                                                            return field;

                                                        })
                                                    }
                                                </Grid>
                                                <Grid container spacing={rowSpacing} style={{marginTop: "2rem"}}>
                                                    <Grid item xs={12}>
                                                        <Divider />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        { deleteButton ? deleteButton : ''}
                                                    </Grid>
                                                    <Grid item xs={8} style={{textAlign: "right"}}>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            size="small"
                                                            disableElevation
                                                            onClick={ () => selectRow(key) }
                                                        >
                                                            {cancelText}
                                                        </Button>
                                                        { saveButton ? saveButton : ''}
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    );
                                } else {
                                    return(
                                        <TableRow key={key} >
                                            {
                                                columnsToShow.map((column: columnItem, subKey: number) => {
                                                    let colVal = row[column.id];
                                                    let columnAlign: any = "left";
                                                    if ( column.type ) {
                                                        switch(column.type) {
                                                            case 'date':
                                                                colVal = colVal ? dateFormat(fromUnixTime(colVal),"dd.MM.yyyy") : '';
                                                                break;
                                                            case 'datetime':
                                                                colVal = colVal ? dateFormat(fromUnixTime(colVal),"dd.MM.yyyy HH:mm") : '';
                                                                break;
                                                            case 'sum':
                                                                columnAlign = "right";
                                                                break;
                                                            case 'button':
                                                                if (colVal && row[column.id] && onClick) {
                                                                    colVal =  <Button
                                                                        color="secondary"
                                                                        variant="contained"
                                                                        onClick={ () => onClick(row[column.id]) }
                                                                        >
                                                                        {column.buttonTitle ? column.buttonTitle : 'Lataa'}
                                                                    </Button>
                                                                } else {
                                                                    colVal = '';
                                                                }
                                                                break;
                                                            case 'link':
                                                                if (column.url && column.sha && row[column.sha] && colVal) {

                                                                    colVal = <Button
                                                                        href={apiDomain+column.url+'/'+colVal+'/'+row[column.sha]}
                                                                        target="_new"
                                                                        color="secondary"
                                                                    >
                                                                        {column.linkTitle ? column.linkTitle : 'Lataa'}
                                                                    </Button>
                                                                } else {
                                                                    colVal = '';
                                                                }
                                                                break;
                                                            case 'select':
                                                                if (
                                                                    column && column.typeSettings && column.typeSettings.options
                                                                ) {
                                                                    const options = column.typeSettings.options.filter( (option) => {
                                                                        if ( option.id && option.id === colVal ) return true;
                                                                        return false;
                                                                    });
                                                                    if ( options.length === 1 ) {
                                                                        colVal = options[0].name;
                                                                    }

                                                                }
                                                                //colVal = column && column.typeSettings && column.typeSettings.options && column.typeSettings.options[colVal] ? column.typeSettings.options[colVal].name : colVal;
                                                            /* default:
                                                            return '';*/
                                                                break;
                                                            case 'list':
                                                                colVal = colVal ? colVal.length : 0;   
                                                                break;
                                                        }
                                                    }
                                                if (column.type == 'link' || column.type == 'button') {
                                                    return (
                                                        <TableCell
                                                            align={columnAlign}
                                                            key={subKey}
                                                            padding="default"
                                                        >
                                                            {colVal}
                                                        </TableCell>
                                                    )
                                                } else {
                                                    return (
                                                        <TableCell
                                                            align={columnAlign}
                                                            key={subKey}
                                                            padding="default"
                                                            onClick={ () => selectRow(key) }
                                                        >
                                                            {colVal}
                                                        </TableCell>
                                                    )
                                                }
                                            })
                                        }
                                        </TableRow>
                                    );
                                }
                            })
                        }
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );

}
