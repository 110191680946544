import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, Grid, Button, TextField, TextareaAutosize } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/index";
import { Client, ClientNotes, ClientFiles, AddClientNote, AddClientFile } from "../../model/client";
import { AppConfig } from "../../AppConfig";

import ApiConnection from "../../actions/api/connect";

import fromUnixTime from 'date-fns/fromUnixTime';

import { BasicAlert, IBasicAlert } from '../../components/Alerts/BasicAlert';

import { TableWithEditor } from '../../components/TableWithEditor';

const useStyles = makeStyles({
	paper: {
        padding: 20,
        paddingTop: 15,
	},
	table: {
		width: "100%",
	},
});
function NotesAndFiles({
    Cclient,
}: {
    Cclient: Client
}) {
    const classes = useStyles();

    const apiDomain = AppConfig.apiUrl;
    const token =  useSelector((state: RootState) => state.user.token);
	const [ClientNotes, setClientNotes] = React.useState<ClientNotes[]>([]);
	const [addNote, setAddNote] = React.useState<AddClientNote>({});
	const [ClientFiles, setClientFiles] = React.useState<ClientFiles[]>([]);
	const [addFile, setAddFile] = React.useState<AddClientFile>({});
	const [alert, setAlert] = React.useState<IBasicAlert>({});
    const [info, setInfo] = React.useState<Info>({
        showFilesList: false,
        showNotesList: false,
        addFileShow: false,
        removeFileShow: false,
        fileTitle: 'Muuta tiedostoa',
    });

    const [filename, setFilename] = React.useState<Files>();

      const apiConnect = new ApiConnection(token);

    function listNotes(){

		apiConnect.setValues({
			client_id: Cclient.id,
		});
        apiConnect.connect( 'ClientNotes/list' ).then(data => {
            console.log(data);
			setClientNotes( data );
        })
        .catch( error => {
        });
    }

    function saveNote(note: ClientNotes){
        if (note.id) {
            apiConnect.setValues({
                id: note.id,
                client_id: Cclient.id,
                subject: note.subject || '',
                note: note.note || '',
            });
        } else {
            apiConnect.setValues({
                client_id: Cclient.id,
                subject: note.subject || '',
                note: note.note || '',
            });
        }
        apiConnect.connect( 'ClientNotes/add' ).then(response => {
            console.log(response);
            listNotes();
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    function deleteNote(id: number) {
        apiConnect.setValues({
			client_id: Cclient.id,
			id: id,
        });

        apiConnect.connect( 'ClientNotes/delete' ).then(response => {
            listNotes();
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    function saveFile(newFile: any){
        if (newFile.file) {
            console.log(newFile.file.name);
            var formData = new FormData();
            formData.append('file', newFile.file);
            formData.append("client_id", String(Cclient.id));
            if ( newFile.title && newFile.title !== "" ) {
                formData.append('title', newFile.title);
            } else {
                formData.append('title', newFile.file.name);
            }

            fetch(apiDomain+'ClientAttachments/add', {
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Bearer '+token,
                  }),
                body: formData
            }).then(res => {
                if(res.ok) {
                    listFiles();
                }
            });
        }
    }

    function deleteFile(id: number) {
        apiConnect.setValues({
			client_id: Cclient.id,
			id: id,
        });
        apiConnect.connect( 'clientAttachments/delete' ).then(response => {
            listFiles();
        })
        .catch( error => {
        console.log( 'ERROR: '+error);
        });
    }


    function listFiles(){
		apiConnect.setValues({
			client_id: Cclient.id,
		});
        apiConnect.connect( 'clientAttachments/list' ).then(data => {
			setClientFiles( data );
        })
        .catch( error => {
        });
    }

    function handleSelectFile(addfile: ClientFiles) {
        setAddNote(addfile);
        setInfo({
            showFilesList: false,
            showNotesList: false,
            addFileShow: true,
            removeFileShow: true,
            fileTitle: 'Muuta tiedostoa',
    });
    }

    function handleAddFile() {
        console.log("handleAddFile");
        if (filename && filename.file) {
            console.log(filename.file);

            var formData = new FormData();
            formData.append('file', filename.file);
            if (addFile && addFile.title) {
                formData.append('title', addFile.title);
            }
            if (Cclient && Cclient.id) {
                formData.append("client_id", String(Cclient.id));
            }
            fetch(apiDomain+'ClientAttachments/add', {
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Bearer '+token,
                  }),
                body: formData
            }).then(res => {
                if(res.ok) {
                    setAddFile({});
                    listFiles();
                    setInfo({
                        showFilesList: false,
                        showNotesList: false,
                        addFileShow: false,
                        removeFileShow: false,
                        fileTitle: 'Lisää tiedosto',
                        });
                }
            });
        }
    }



    function handleRemoveFile() {
        apiConnect.setValues({
			client_id: Cclient.id,
			id: addNote.id,
        });

        apiConnect.connect( 'clientAttachments/delete' ).then(response => {
            setAddFile({});
            listFiles();
            setInfo({
                showFilesList: false,
                showNotesList: false,
                addFileShow: false,
                removeFileShow: false,
                fileTitle: 'Lisää tiedosto',
                });
        })
        .catch( error => {
        console.log( 'ERROR: '+error);
        });
    }

    if (info.showNotesList == false) {
        listNotes();
        setInfo({...info, 'showNotesList': true});
    }
    if (info.showFilesList == false) {
        listFiles();
        let user_file = '';
        setInfo({...info, 'showFilesList': true});
    }

    
    const emptyClientNote: Object = {
        client_id: Cclient.id,
        subject: '',
        note: '',
    };

    const emptyClientFile: Object = {
        client_id: Cclient.id,
    };

    return (
        <Paper className={classes.paper} square={true}>
            <TableWithEditor
                title="Muistiinpanot"
                noEdit="false"
                columns={[
                    {
                        title: 'ID',
                        id: 'id',
                        hide: true,
                    },
                    {
                        title: 'Aika',
                        id: 'created_at',
                        type: 'datetime'
                    },
                    {
                        title: 'Otsikko',
                        id: 'subject',
                        edit: true,
                    },
                    {
                        title: 'Muistiinpano',
                        id: 'note',
                        edit: true,
                        hide: true,
                        type: 'multiline',
                    },
                ]}
                itemData={ClientNotes}
                emptyItem={emptyClientNote}
                onSave={saveNote}
                onDelete={deleteNote}
            />
            <div style={{marginTop: "2rem"}} />
            <TableWithEditor
                title="Tiedostot"
                noEdit="false"
                columns={[
                    {
                        title: 'ID',
                        id: 'id',
                        hide: true,
                    },
                    {
                        title: 'Lisätty',
                        id: 'created_at',
                        type: 'datetime'
                    },
                    {
                        title: 'Tiedoston nimi',
                        id: 'title',
                        edit: false,
                    },
                    {
                        title: 'Tiedosto',
                        id: 'file',
                        edit: true,
                        hide: true,
                        type: 'file',
                        typeSettings: {
                            selectedFileName: 'Ei valittua tiedostoa',
                            fileNameId: 'title',
                        },
                    },
                    {
                        title: 'Toiminto',
                        id: 'id',
                        edit: false,
                        type: 'link',
                        url: 'Attachments/downloadFile/attachment',
                        sha: 'sha',
                    },
                ]}
                itemData={ClientFiles}
                emptyItem={emptyClientFile}
                onSave={saveFile}
                onDelete={deleteFile}
            />
        </Paper>
    );

};

export default NotesAndFiles;

interface Info{
    showFilesList: any;
    showNotesList: any;
    addFileShow: any;
    removeFileShow: any;
    fileTitle: string;
};

interface Files {
    file: Blob;
}
