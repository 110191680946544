import { UserAction, UserActions, User, UserLogin } from "../model/user"; //

export function login(userinfo: User): UserAction {
    // here you could do API eg
    //console.log('set login: '+JSON.stringify(userinfo));
    return {
        type: UserActions.LOGIN,
        payload: userinfo,
    };
}

export function setMinutes(minutes: number): UserAction {
    const d = new Date();
    const min = d.getTime() + minutes * 600; //60000
    //console.log('ExpireTime: '+min);
    return {
        type: UserActions.SETMINUTES,
        payload: min, //minutes,
    };
}

/*
export function addServices(services: UserService[] ): UserAction  {
    
    return (dispatch: Function, getState: Function) => {
		dispatch({ 
            type: UserActions.ADD_SERVICES, 
            payload: services });
    };
    return {
        type: UserActions.ADD_SERVICES,
        payload: services,
    };
}*/

export function logout(){
    return {
        type: UserActions.LOGOUT,
    };
}