import { Grid, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import TokenTimer from "../TokenTimer";

const DefaultTemplate = ( { title, dialog, actions, content }: IPage ) => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	return (
		<React.Fragment>
			<Grid container className={classes.root}>
				{dialog}
				{ actions ?
					<React.Fragment>
						<Grid item xs={7}>
							<Typography className={classes.title} variant="h4" gutterBottom>
								{title}
							</Typography>
						</Grid>
						<Grid item xs={5} style={{textAlign: "right"}}>
							{actions}
						</Grid>
					</React.Fragment>
					: title ?
						<Grid item xs={12}>
							<Typography className={classes.title} variant="h4" gutterBottom>
								{title}
							</Typography>
						</Grid>
						: ''
				}
				<Grid item xs={12} style={{}}>
					{content}
				</Grid>
			</Grid>
			<TokenTimer />
		</React.Fragment>
	);
}

export interface IPage{
    title?: string;
	dialog?: JSX.Element;
	actions?: JSX.Element;
    content?: JSX.Element;    
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: 40,
		/*[theme.breakpoints.down("md")]: {
			paddingTop: 50,
			paddingLeft: 15,
			paddingRight: 15,
		},*/
		maxHeight: '100%',
		overflow: 'scroll',
	},

	title: {
		textTransform: 'uppercase',
	},
	/*
	buttonContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-end",
	},

	button: {
		marginBottom: 15,
    },*/
}));

export default DefaultTemplate;