import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/index";
import { Client } from "../../model/client"; 
import { history } from "../../configureStore";

const useStyles = makeStyles({
	paper: {
	},
	table: {
		width: "100%",
	},
});

function SearchResultList({
    clients,
}: {
    clients: Client[]
}) {
    const classes = useStyles();


    function handleSelect(client: Client) {
        history.push({
            pathname: '/client',
            state: {client_id: client.id},
          });
    }
    if ( clients.length > 0 ) {
        return (
            <Paper className={classes.paper} square={true}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="default">Yritys</TableCell>
                            <TableCell padding="default">Y-tunnus</TableCell>
                            <TableCell padding="default">Puhelin</TableCell>
                            <TableCell padding="default">Sähköposti</TableCell>
                            <TableCell padding="default">Osoite</TableCell>
                            <TableCell padding="default">Postinumero</TableCell>
                            <TableCell padding="default">Kaupunki</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            clients.map((client: Client, key: number) => {
                                return(
                                    <TableRow key={key} onClick={() => handleSelect(client)}>
                                        <TableCell padding="default">{client.company}</TableCell>
                                        <TableCell padding="default">{client.vatnumber || ''}</TableCell>
                                        <TableCell padding="default">{client.phone || ''}</TableCell>
                                        <TableCell padding="default">{client.email || ''}</TableCell>
                                        <TableCell padding="default">{client.address || ''}</TableCell>
                                        <TableCell padding="default">{client.zipcode || ''}</TableCell>
                                        <TableCell padding="default">{client.city || ''}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </Paper>
        );
    }
    return <span />;
};

export default SearchResultList;
