import DefaultTemplate from "../components/PageTemplates/DefaultTemplate";

import SearchConditions from "../components/Search/SearchConditions";
import SearchResultList from "../components/Search/SearchResultList";

import { Grid, Paper, Table, TableHead, TableBody, TableRow, TableCell, Button, TextField } from "@material-ui/core";

import * as React from "react";

import ApiConnection from "../actions/api/connect";

import { useSelector } from "react-redux";
import { RootState } from "../reducers/index";
import { BasicAlert, IBasicAlert } from '../components/Alerts/BasicAlert';
import { history } from "../configureStore";

import { Client } from "../model/client";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
	paper: {
        padding: 20,
	},
});

export function CreateClientPage() {
	const classes = useStyles();

	const token =  useSelector((state: RootState) => state.user.token);

	const [client, setClient] = React.useState<Client>({});
	const [alert, setAlert] = React.useState<IBasicAlert>({});

    const apiConnect = new ApiConnection(token);

    const handleChange = (prop: keyof Client) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setClient({ ...client, [prop]: event.target.value });
    };

	function handleSave() {
        apiConnect.setValues({
            company: client.company || '',
            vatnumber: client.vatnumber || '',
            address: client.address || '',
            zipcode: client.zipcode || '',
            city: client.city || '',
            phone: client.phone || '',
            email: client.email || '',
            www: client.www || '',
        });
        apiConnect.connect( 'Clients/add' ).then(data => {
            history.push({
                pathname: '/client',
                state: {client_id: data.id},
            });
        })
        .catch( error => {
            console.log(error);
        });
	}

    return (
        <DefaultTemplate
            title='Lisää uusi asiakas'
            content={
                <Paper square={true} style={{marginTop: "20px"}}>
                    <Grid container spacing={3} className={classes.paper}>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                id="company"
                                label="Yrityksen nimi"
                                onChange={handleChange('company')} 
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                id="vatnumber"
                                label="Y-tunnus"
                                onChange={handleChange('vatnumber')} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="address"
                                label="Osoite"
                                onChange={handleChange('address')} 
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                id="zipcode"
                                label="Postinumero"
                                onChange={handleChange('zipcode')} 
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                id="city"
                                label="Kaupunki"
                                onChange={handleChange('city')} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="phone"
                                label="Puhelinnumero"
                                onChange={handleChange('phone')} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="email"
                                label="Sähköpostiosoite"
                                onChange={handleChange('email')} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="www"
                                label="Kotisivujen osoite"
                                onChange={handleChange('www')} 
                            />
                        </Grid>                    
                        <Grid item xs={12} style={{textAlign: "right"}}>
                            <Button
                                variant="contained"
                                color="secondary"
                                //size="small"
                                disableElevation
                                onClick={ () => handleSave() }
                            >
                                Tallenna
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            }
        />
    );

};
