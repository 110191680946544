import { AppBar, Button, Drawer as DrawerMui, Hidden, IconButton, Toolbar, Typography, useMediaQuery } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";

import { MenuItems, MenuItem } from "../Route/MenuItems";
import { history } from "../../configureStore";

import MenuIcon from "@material-ui/icons/Menu";

import { useSelector } from "react-redux";
import { RootState } from "../../reducers/index";

import { AppConfig } from "../../AppConfig";

import HeaderMenuItem from "./HeaderMenuItem";

import { Service } from "../../model/service";

function Drawer() {
	const classes = useStyles();
	const serviceList: Service[] = useSelector((state: RootState) => state.serviceList);
	return (
		<div>
			<div className={classes.drawerHeader} />
				{
					MenuItems.map((item: MenuItem, key: number) => {
						if ( item.hide ) {
							return false;
						} else if ( item.id ) {
							const checker = serviceList.filter( ( service ) => service.id === item.id );
							if ( checker.length === 0 ) {
								return false;
							}
						}
						return(
							<HeaderMenuItem
								item={item}
								key={key}
							/>
						);
					})
				}
		</div>
	);
}



function AppHeader({logged}:IAppHeader) {
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(true);
	
	const name = useSelector((state: RootState) => state.user.name);
    
    const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down("sm")
	);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
    };
    
    const ToolbarNavButton = (): JSX.Element => {
        return ( 
            <IconButton
				color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                className={classes.mobileShow}
            >
                <MenuIcon />
            </IconButton>
        );
	};

	const DrawerMenu = (): JSX.Element => {
		return ( 
			<React.Fragment>
				<Hidden mdUp>
					<DrawerMui
						variant="temporary"
						anchor={"left"}
						open={mobileOpen}
						classes={{
							paper: classes.drawerPaper,
						}}
						onClose={handleDrawerToggle}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						<Drawer />
					</DrawerMui>
				</Hidden>
				<Hidden smDown>
					<DrawerMui
						variant="permanent"
						open
						classes={{
							paper: classes.drawerPaper,
						}}
					>
						<Drawer />
					</DrawerMui>
				</Hidden>
			</React.Fragment>
		);
    };
    
    return (
        <React.Fragment>
            <AppBar className={classes.appBar}>
                <Toolbar>
					{ logged === true
						? <ToolbarNavButton />
						: ''
					}
					<Typography
						variant="h6"
						color="inherit"
						noWrap={isMobile}
						className={classes.title}
					>
						{AppConfig.appTitle}
					</Typography>
					<Typography 
						style={{marginLeft: "1rem", marginRight: "1rem"}}
						className={classes.mobileHide}
					>
						{name}
					</Typography>
					{ logged === true
						?
							<Button 
								variant="outlined"
								size="small"
								color="inherit"
								onClick={() => history.push('/logout')}
								className={classes.mobileHide}
							>
								Kirjaudu ulos
							</Button>
						: ''
					}
                </Toolbar>
            </AppBar>
            { logged === true
                ? <DrawerMenu />
                : ''
            } 
        </React.Fragment>
    );
}

export interface IAppHeader {
    logged: Boolean;
};

export default AppHeader;

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: "100%",
		height: "100%",
		zIndex: 1,
		overflow: "hidden",
	},
	appFrame: {
		position: "relative",
		display: "flex",
		width: "100%",
		height: "100%",
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		position: "absolute",
	},
	mobileHide: {
		[theme.breakpoints.only("xs")]:{ //{"xs","sm",})]: {
			display: "none",
		},
	},
	mobileShow: {
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
	title: {
		flexGrow: 1,
	},
	drawerHeader: theme.mixins.toolbar,
	drawerPaper: {
		width: 250,
		backgroundColor: theme.palette.background.default,
		[theme.breakpoints.up("md")]: {
			width: drawerWidth,
			position: "relative",
			height: "100%",
		},
	},
	content: {
		backgroundColor: theme.palette.background.default,
		width: "100%",
		height: "calc(100% - 56px)",
		marginTop: 56,
		[theme.breakpoints.up("sm")]: {
			height: "calc(100% - 64px)",
			marginTop: 64,
		},
	},
}));