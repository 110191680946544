import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { AppBar, Button, FormControl, Input, InputLabel, OutlinedInput, Paper, Toolbar, Typography } from "@material-ui/core";

import { useActions } from "../actions";
import * as UserActions from "../actions/user";
import { Service } from "../model/service";
import * as ServiceActions from "../actions/service";

import { BasicAlert, IBasicAlert } from '../components/Alerts/BasicAlert';

import * as React from "react";

import ApiConnection from "../actions/api/connect";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: 20,
        },
    }),
);

const Login = () => {
    
    const classes = useStyles();

    const [alert, setAlert] = React.useState<IBasicAlert>({});

    const [values, setValues] = React.useState<LoginInfo>({
        username: '',
        password: '',
    });

    const userActions = useActions(UserActions);
    const serviceActions = useActions(ServiceActions);

    const handleChange = (prop: keyof LoginInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleLogin = () => () => {
        console.log( 'Login Handler' );
        
        const api = new ApiConnection();

        const d = new Date();
        const exp_time = d.getTime() + 30 * 600;

        api.setValues( values );

        api.connect(
            'users/login'
        ).then(response => {
            // console.log('response: '+JSON.stringify(response));
            
            setAlert({});

            if ( response.data && response.data.token ) {
                /*userActions.login({
                    username: values.username,
                    logged: true,
                    token: response.data.token,
                    token_expires: exp_time,
                });*/
                const token: string = response.data.token;
                api.setToken( token )
                //const api = new ApiConnection( response.data.token );
                
                api.connect(
                    'users/get'
                ).then(response => {

                    const userData = response.data.user;

                    userActions.login({
                        username: userData.username,
                        name: userData.name,
                        id: userData.id,
                        logged: true,
                        token: userData.token,
                        role: userData.role,
                        token_expires: exp_time,
                    });

                    api.setValues( {} );
                    api.setToken( token );

                    // console.log( 'COnnecting?' );

                    api.connect(
                        'users/getUserServices'
                    ).then(response => {
                        console.log( response.data.services );
                        response.data.services.map(( item: Service ) => { // :Service
                            const serv: Service = {
                                id: item.id,
                                name: item.name,
                                group_id: item.group_id,
                                customer_id: item.customer_id,
                                url: item.url,
                                order: item.order,
                                access: item.access,
                            };
                            serviceActions.addService( serv );
                        })
                    })
                    .catch( error => {
                        console.log('Error!!!');
                    });
                    
                    
                })
                .catch( error => {

                });
                
            }

        })
        .catch( error => {

            console.log('error!!!');

            setAlert({
                type: 'warning',
                message: 'Tarkista käyttäjätunnus ja salasana.',
            });

        }).finally(() => {
            //
        });

    };



    return (
        <div>
            <AppBar position="static" color="secondary">
                <Toolbar variant="dense">
                    <Typography variant="h6">
                    Kirjaudu
                    </Typography>
                </Toolbar>
            </AppBar>
            <Paper square  className={classes.paper}>
                {
                    alert
                        ? <BasicAlert 
                            type={alert.type}
                            message={alert.message}
                        />
                        : ''
                }
                <form noValidate autoComplete="off" >
                    <FormControl variant="filled" fullWidth>
                        <InputLabel htmlFor="username">Käyttäjätunnus</InputLabel>
                        <Input 
                            id="username" 
                            value={values.username}
                            onChange={handleChange('username')}
                        />
                    </FormControl>
                    <FormControl  variant="filled" fullWidth>
                        <InputLabel htmlFor="password">Salasana</InputLabel>
                        <Input
                            id="password" 
                            value={values.password} 
                            type="password"
                            onChange={handleChange('password')} 
                        />
                    </FormControl>
                    <div style={{textAlign: 'right', marginTop: '1rem'}}>
                        <Button variant="contained" color="secondary" onClick={handleLogin()}>
                            Kirjaudu
                        </Button>
                    </div>
                </form>
            </Paper>
        </div>
    );
};

interface LoginInfo {
    username: string;
    password: string;
};

export default Login;